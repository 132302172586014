import React from 'react';
import styles from './Steps.css.js';

const RenderPids = ({ pids }) => (
    <div style={styles.pidList}>
        {pids && pids.map((value, idx) => {
            if(idx === 0) {
                return <div key={`pid__${idx}`}>{value}</div>;
            };
            return <div key={`pid__${idx}`}>{`, ${value}`}</div>;
        })}
    </div>
);

export default RenderPids;