const styles = {
    rowStyle: {
        fontSize: '1rem',
    },
    offersTable: {
        borderRadius: '5px',
        width: '100%',
    },
    offersHeader: {
        backgroundColor: 'rgba(16,64,107,0.92)',
        color: 'rgba(255,255,255,1)',
        padding: '2px 10px',
        fontSize: '1rem',
        fontWeight: '300'
    },
    tablePaper: {
        overflowX: 'hidden',
        overflowY: 'hidden',
        width: '85%',
        margin: '20px 0',
        borderRadius: '5px',
    },
    loadingContainer: {
        minWidth: '100%',
        height: '100%',
        minHeight: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    linksMain: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgb(239,248,254)'
    },
    linkItem: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    linkButton: {
        width: '240px',
        height: '45px',
        backgroundColor: 'rgb(39,79,119)',
        borderRadius: '25px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.25rem',
        color: 'white',
        margin: '5px 0'
    },
    linkClicksText: {
        fontSize: '1.5rem',
        marginLeft: '10px'
    }
};

export default styles;