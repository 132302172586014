import React from 'react';
import { useQuery } from '@apollo/client';
import { ENDPOINT_GROUPS } from '../../graphql/queries';
import Paper from '@material-ui/core/Paper';
import EmptyList from '../EmptyList';
import Loading from '../Loading';
import styles from './EndpointGroupList.css.js';
import GroupDetails from '../Tables/GroupDetails';
import EndpointGroupColumns from '../Tables/TableColumns/EndpointGroupColumns';
import TableIcons from '../Tables/TableIcons';
import MaterialTable from 'material-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EndpointGroupList = ({ isSelection, handleEdit, activeGroup, setGroup, setName }) => {
    const { loading, error, data } = useQuery(ENDPOINT_GROUPS);

    if (loading) return <div style={styles.loadingTable}><Loading /></div>;

    if (error) return <div>{`Error occured fetching endpoint groups: ${error.message}`}</div>;

    const handleGroupSelection = (event, rowData) => {
        event.stopPropagation();
        if(rowData.id === activeGroup) {
            setGroup('N/A');
            setName(null);
        } else {
            setGroup(rowData.id);
            setName(rowData.name);
        }
    };

    const endpointGroupsArray = data.fetchAllEndpointGroups.body;

    return (
        <Paper style={styles.tablePaper} elevation={10}>
            {endpointGroupsArray.length > 0 ? 
                <MaterialTable
                    icons={TableIcons}
                    columns={EndpointGroupColumns()}
                    data={endpointGroupsArray.map(endpoint_group => {
                        return {
                            id: endpoint_group.id,
                            name: endpoint_group.name,
                            group_items: endpoint_group.group_items,
                            usage: endpoint_group.usage
                        };
                    })}
                    options={{
                        selection: isSelection,
                        showSelectAllCheckbox: false,
                        showTitle: false,
                        search: true,
                        paging: false,
                        headerStyle: styles.offersHeader,
                        detailPanelColumnAlignment: 'right',
                        rowStyle: styles.groupRow,
                        selectionProps: rowData => ({
                            checked: rowData.id === activeGroup,
                            onClick: (event) => handleGroupSelection(event, rowData)
                        })
                    }}
                    style={styles.groupTable}
                    detailPanel={GroupDetails('endpoint')}
                    onRowClick={(_, __, togglePanel) => togglePanel()}
                    actions={isSelection ?
                        [] :
                        [{
                            icon: () => (
                                <div style={styles.actionContainer}>
                                    <FontAwesomeIcon style={styles.editIcon} icon={['fal', 'pen']} />
                                </div>
                            ),
                            tooltip: 'Edit Endpoint Group',
                            onClick: handleEdit
                        }]}
                /> : <EmptyList />
            }
        </Paper>
    )
};

export default EndpointGroupList;