import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ControlButtons from './ControlButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Steps.css.js';

const RenderButtonForList = ({data, handleClick}) => {
    const [ isHovering, setHovering ] = useState(false);

    const buttonStyle = Object.assign({},
        styles.stepButton,
        isHovering && styles.hoverButton
    );

    return (
        <Button 
            key={`loan-type__${data.idx}`} 
            onClick={() => handleClick(data.value)}
            style={buttonStyle}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
        >
            <FontAwesomeIcon style={styles.stepButtonIcon} icon={data?.avatar} />
            {data.text}
        </Button>
    );
};

const SelectionList = ({ close, moveBack, list, onSelectClick }) => (
    <>		
    <ButtonGroup 
        style={styles.stepQuestionButtons} 
        orientation='vertical'
        fullWidth
    >
        {list.map((button, idx) => (
            <RenderButtonForList
                key={`${idx}-option`}
                data={{...button, idx}} 
                handleClick={onSelectClick}
            />
        ))}		
    </ButtonGroup>
    <ControlButtons close={close} handleBack={moveBack} />
    </>
);

export default SelectionList;