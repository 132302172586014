const styles = {
    pathStepperContainer: {
        maxWidth: '1000px',
    },
    visualStep: {
        margin: '20px 0 60px 0',
        minWidth: '1000px',
        // backgroundColor: 'aliceblue',
        padding: '0'
    }
};

export default styles;