import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../context';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import VerifyDomain from './Steps/VertifyDomain';
import SelectLoanType from './Steps/SelectLoanType';
import SelectDebtType from './Steps/SelectDebtType';
import SelectDebtAmount from './Steps/SelectDebtAmount';
import SelectOptins from './Steps/SelectOptins';
import SelectEndpointGroup from './Steps/SelectEndpointGroup';
import ConfirmSelection from './Steps/ConfirmSelection';
import { getStepTitles } from './stepper-utils';
import styles from './PathStepper.css.js';

const PathStepper = ({ close, toggleSubmit }) => {
    const { selectionState } = useContext(AppContext);
	const [ activeStep, setActiveStep ] = useState(() => {
		if(!!selectionState?.id) {
			return 1;
		};
		return 0;
	});

	const steps = getStepTitles(activeStep, selectionState);

	const handleNext = () => {
		setActiveStep((prevActiveStep) => {
            if(prevActiveStep + 1 === steps.length -1) {
                toggleSubmit(true);
            };
            return prevActiveStep + 1
        });
	};
	
	const handleBack = () => {
		setActiveStep((prevActiveStep) => {
            toggleSubmit(false);
			if(selectionState?.vars?.vertical === 'direct' && prevActiveStep === 5) return 1;
			return prevActiveStep - 1;
		});
	};

    const renderStepContent = () => {
        switch (activeStep) {
            case 0:
                return <VerifyDomain 
                            selection={selectionState} 
                            close={close} 
                            moveToNext={handleNext}
                        />;
            case 1:
                return <SelectLoanType 
                            close={close} 
                            moveBack={handleBack} 
                            moveToNext={handleNext} 
                        />;
            case 2:
                return <SelectDebtType 
                            close={close} 
                            moveBack={handleBack} 
                            moveToNext={handleNext}
                        />;
            case 3:
                return <SelectDebtAmount 
                            close={close} 
                            moveBack={handleBack} 
                            moveToNext={handleNext}
                        />;
            case 4:
                return <SelectOptins 
                            close={close} 
                            moveBack={handleBack} 
                            moveToNext={handleNext}
                        />;
            case 5:
                return <SelectEndpointGroup 
                            close={close} 
                            moveBack={handleBack} 
                            moveToNext={handleNext}
                        />;
            case 6:
                return <ConfirmSelection 
                            close={close} 
                            moveBack={handleBack}
                            setShowSubmit={toggleSubmit}
                        />;
            default:
                return 'Unknown step';
        }
    };

	return (		
		<div style={styles.pathStepperContainer}>
            <Stepper 
                activeStep={activeStep} 
                alternativeLabel={true} 
                style={styles.visualStep}
            >
				{steps.map((label, index) => (
					<Step key={`step_${label}-${index}`}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
            {renderStepContent()}
		</div>
		
	);
}

export default PathStepper;