import React, { useContext, useState } from 'react';
import { AppContext } from '../../../context';
import styles from './Programs.css.js';
import Box from '@material-ui/core/Box';
import ActionBar from '../../ActionBar';
import RangeSelector from '../../RangeSelector';
import ProgramRecordDialog from '../../Forms/ProgramRecordDialog';
import RecordsTable from './RecordsTable';

const ProgramItemContent = ({ programDomain }) => {
	const { dispatchSelection } = useContext(AppContext);
    const [ dateRange, setDateRange ] = useState('24hr');

	const [ showDialog, setShowDialog ] = useState(false);
    
    const handleClose = () => {
        dispatchSelection({ type: 'CLEAR_SELECTION' });
        setShowDialog(false);
    };

    const handleAddClick = () => {
		dispatchSelection({ type: 'ADD_PROGRAM_RECORD', payload: programDomain });
        setShowDialog(true);
    };

    const handleEditClick = (event, rowData) => {
        event.preventDefault();
        dispatchSelection({ type: 'EDIT_PROGRAM_RECORD', payload: { record: rowData, domain: programDomain } });
        setShowDialog(true);
    };

	return (
		<div style={styles.programContent}>
            <Box p={3} style={styles.verticalContent}>
				{showDialog ? 
					<ProgramRecordDialog open={showDialog} close={handleClose}/> 
					: 
					<>
					<ActionBar text='Active Offer' title='RECORDS' handleClick={handleAddClick} size='Md'/>
					<div style={styles.listContainer}>
						<div style={styles.tableTitle}>
							<RangeSelector range={dateRange} setRange={setDateRange} />
						</div>
						<RecordsTable domain={programDomain} handleEdit={handleEditClick}/>
					</div>
					</>
				}
			</Box>
		</div>
	);
}

export default ProgramItemContent;