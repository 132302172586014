import React, { forwardRef } from 'react';
import Paper from '@material-ui/core/Paper';
import EmptyList from '../../EmptyList';
import styles from './LinkListBuilder.css.js';
import SelectionLinkListColumns from '../../Tables/TableColumns/SelectionLinkListColumns';
import TableIcons from '../../Tables/TableIcons';
import MaterialTable, { MTableBodyRow } from 'material-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionButton from '../../ActionButton';

const CustomLinksList = forwardRef(({ links, handleAdd }, ref) => {

    const constPathColors = {
        1: 'rgba(16,64,107,0.25)',
        2: 'aliceblue',
    };

    return (
        <Paper style={styles.customLinksPaper} elevation={10}>
            {links.length > 0 ? 
                <MaterialTable
                    icons={TableIcons}
                    columns={SelectionLinkListColumns()}
                    data={links}
                    options={{
                        selection: false,
                        search: false,
                        showSelectAllCheckbox: false,
                        showTitle: false,
                        toolbar: false,
                        paging: false,
                        header: false,
                        rowStyle: rowData => {
                            if(rowData.tableData.isTreeExpanded === false && rowData.tableData.path.length === 1) {
                                return styles.linkRow;
                            }
                            const rowBackgroundColor = constPathColors[rowData.tableData.path.length];
                            return {...styles.linkRow, backgroundColor: rowBackgroundColor, width: '100%', padding: '0'};
                        }
                    }}
                    tableRef={ref}
                    onRowClick={(_, rowData) => ref.current.onTreeExpandChanged(rowData.tableData.path, rowData)}
                    parentChildData={(row, rows) => rows.find(a => a.name === row.cluster)}
                    style={styles.customlinksTable}
                    actions={[{ icon: () => <FontAwesomeIcon 
                        style={styles.addIcon} 
                        icon={['fal', 'plus']}
                    />, tooltip: 'Add QuickLink', onClick: handleAdd }]}
                    components={{
                        Action: props => {
                            if(!!props.data.cluster_name) return null;
                            
                            return (
                                <ActionButton 
                                    icon='plus' 
                                    bg='lightBlue'
                                    bgHover='ice' 
                                    handleClick={(e) => handleAdd(e, props.data)}
                                />
                            )
                        },
                        Row: (props) => ( <MTableBodyRow hover={true} {...props} /> )
                    }}
                /> : <EmptyList />
            }
        </Paper>
    );
})

export default CustomLinksList;