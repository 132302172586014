const styles = {
    textFields: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    offerList: {
        width: '85%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    itemInputContainer: {
        width: '90%',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textInput: {
        marginBottom: '20px'
    },
    tablePaper: {
        width: '100%',
        maxWidth: '1300px',
        margin: '20px 0 0 0',
        borderRadius: '5px',
    },
    endpointsTable: {
        borderRadius: '5px',
    },
    offerRow: {
        color: 'rgba(16,64,107,1)',
    },
    offersHeader: {
        backgroundColor: 'rgba(16,64,107,0.92)',
        color: 'rgba(255,255,255,1)',
        padding: '2px 10px',
        fontSize: '1rem',
        fontWeight: '300'
    },
};
export default styles;