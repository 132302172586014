import React from 'react';
import styles from './CustomCells.css.js';

const ButtonText = ({ text }) => (
	<div style={styles.buttonTextContainer}>
        <div style={styles.elText}>
			{text}
        </div>
    </div>
);

export default ButtonText;