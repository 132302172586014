import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import styles from '../Offerings/Offerings.css';
import CategoryTabPanel from '../Offerings/CategoryTabPanel';
import ArticleList from './ArticleList';
import RecommendedList from './RecommendedList';
import OfferBlockList from './OfferBlockList';
import PopupControl from './PopupControl';

const ArticleControl = () => {
	const { appState } = useContext(AppContext);
	const tabValue = appState.articlesTabValue;

	return(
        <div style={styles.pageContainer}>
			<CategoryTabPanel value={tabValue} idx={0}>
				<ArticleList />
			</CategoryTabPanel>
            <CategoryTabPanel value={tabValue} idx={1}>
				<RecommendedList />
			</CategoryTabPanel>
            <CategoryTabPanel value={tabValue} idx={2}>
				<OfferBlockList />
			</CategoryTabPanel>
            <CategoryTabPanel value={tabValue} idx={3}>
				<PopupControl />
			</CategoryTabPanel>
        </div>
	)
};

export default ArticleControl;