import React from 'react';
import styles from './CustomColumns.css.js';
import GroupName from '../../GroupName';
import GroupCount from '../../GroupCount';

const VerticalDirectColumns = (range) => ([
	{ 
		title: 'URL Identity', 
		field: 'vars.loan_type',
		align: 'left',
        cellStyle: {...styles.titleCell}, 
		render: rowData => rowData.vars.loan_type 
	},
	{ 
        title: 'Group Name', 
		field: 'endpoint_group',
		align: 'center',
        cellStyle: styles.tableCell,
        render: rowData => <GroupName type='endpoint' id={rowData.endpoint_group} />
	},
	{ 
        title: 'Visitors', 
		field: 'endpoint_group',
		align: 'center',
        cellStyle: styles.tableCell,
        render: rowData => <GroupCount id={rowData.id} endpoint_group={rowData.endpoint_group} range={range}/>
	},
]);

export default VerticalDirectColumns;