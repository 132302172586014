import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import { useQuery } from '@apollo/client';
import { DIRECT_DOMAINS } from '../../../graphql/queries';
import Loading from '../../Loading';
import ProgramItem from './ProgramItem';
import EmptyList from '../../EmptyList';
import List from '@material-ui/core/List';
import DirectDomainDialog from '../../Forms/DirectDomainDialog';
import styles from './Programs.css.js';
import ActionBar from '../../ActionBar';
import Paper from '@material-ui/core/Paper';

const Programs = () => {
    const { dispatchSelection, selectionState } = useContext(AppContext);
    const { show_domain_dialog } = selectionState;

    const { loading, error, data } = useQuery(DIRECT_DOMAINS);

    if (error) return <div>Error</div>;

    if (loading) return <div style={styles.fillPage}><Loading /></div>;

    const direct_domains = data.fetchDirectDomainList.body;
    
    const handleClose = () => {
        dispatchSelection({ type: 'CLOSE_DIRECT_DOMAIN_DIALOG' });
    };

    const handleAddClick = () => {
        dispatchSelection({ type: 'ADD_DIRECT_DOMAIN_DIALOG' });
    };

    return (
        <div style={styles.pageContainer}>
            <ActionBar text='Direct Domain' title='PROGRAMS' handleClick={handleAddClick} size='Lg'/>
            <div style={styles.content}>
                {!show_domain_dialog ? (
                    direct_domains?.length > 0 ? 
                        <List component='nav' aria-labelledby='direct-domain-list'>
                            {direct_domains.map((program, idx) => (
                                <ProgramItem key={`direct-domain__${idx}`} item={{idx, ...program}} />
                            ))}
                        </List>
                     : <Paper style={styles.tablePaper} elevation={10}><EmptyList /></Paper>
                ) : <DirectDomainDialog open={show_domain_dialog} close={handleClose} />}
            </div>
        </div> 
    );
}

export default Programs;