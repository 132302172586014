import React from 'react';
import Sidebars from '../components/Sidebars';
import styles from '../components/Global/GlobalStyle.css';

const CallbackPage = () => {
    return (
        <div style={styles.app}>
            <Sidebars>
                <div>...</div>
            </Sidebars> 
        </div>
    );
};
export default CallbackPage;