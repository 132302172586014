import React from 'react';
import MaterialTable from 'material-table';
import VerticalPathsColumns from '../../../Tables/TableColumns/VerticalPathsColumns';
import VerticalDirectColumns from '../../../Tables/TableColumns/VerticalDirectColumns';
import TableIcons from '../../../Tables/TableIcons';
import EmptyList from '../../../EmptyList';
import styles from './PathListStyle.css.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Paper from '@material-ui/core/Paper';
import ListDetailPanels from './ListDetailPanels';

const VerticalPathsTable = ({ tableData, range, handleDelete, handleEdit }) => (
    <Paper style={styles.tablePaper} elevation={10}>
        {tableData.length > 0 ?
            <MaterialTable
                icons={TableIcons}
                columns={tableData[0]?.program?.vars?.vertical === 'direct' ? VerticalDirectColumns(range) : VerticalPathsColumns(range)}
                data={tableData.map(program => ({
                    id: program?.id,
                    domain: program?.domain,
                    pid: program?.pid,
                    vars: {
                        vertical: program?.vars?.vertical,
                        loan_type: program?.vars?.loan_type,
                        debt_type: program?.vars?.debt_type,
                        debt_amount: program?.vars?.debt_amount,
                    },
                    debt_group: program?.debt_group,
                    checking_group: program?.checking_group,
                    endpoint_group: program?.endpoint_group,
                    range: range            
                }))}
                options={{
                    search: false,
                    showTitle: false,
                    toolbar: false,
                    paging: false,
                    headerStyle: styles.header,
                    sorting: false,
                    detailPanelColumnAlignment: 'right',
                }}
                style={styles.pathTable}
                detailPanel={ListDetailPanels(range)}
                onRowClick={(_, __, togglePanel) => {togglePanel()}}
                actions={[
                    {
                        icon: () => (
                            <div style={styles.actionContainer}>
                                <FontAwesomeIcon style={styles.toggleDeleteIcon} icon={['fal', 'trash-alt']} />
                            </div>
                        ),
                        tooltip: 'Delete Path',
                        onClick: handleDelete
                    },
                    {
                        icon: () => (
                            <div style={styles.actionContainer}>
                                <FontAwesomeIcon style={styles.toggleIcon} icon={['fal', 'pen']} />
                            </div>
                        ),
                        tooltip: 'Edit Path',
                        onClick: handleEdit
                    },
                ]}
            /> :
        <EmptyList />}
    </Paper>
);

export default VerticalPathsTable;