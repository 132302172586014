import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { useQuery } from '@apollo/client';
import { DOMAINS } from '../../../graphql/queries';
import Loading from '../../Loading';
import DomainItem from './DomainItem';
import EmptyList from '../../EmptyList';
import List from '@material-ui/core/List';
import DomainDialog from '../../Forms/DomainDialog';
import styles from './Domains.css.js';
import ActionBar from '../../ActionBar';
import Paper from '@material-ui/core/Paper';

const Domains = () => {
    const { dispatchSelection } = useContext(AppContext);
    const [ showDialog, setShowDialog ] = useState(false);

    const { loading, error, data } = useQuery(DOMAINS);

    if (error) return <div>Error</div>;

    if (loading) return <div style={styles.fillPage}><Loading /></div>;

    const domains = data.fetchDomainList.body;
    
    const handleClose = () => {
        dispatchSelection({ type: 'CLEAR_SELECTION' });
        setShowDialog(false);
    };

    const handleAddClick = () => {
        dispatchSelection({ type: 'CLEAR_SELECTION' });
        setShowDialog(true);
    };

    return (
        <div style={styles.pageContainer}>
            <ActionBar text='Domain' title='PROGRAMS' handleClick={handleAddClick} size='Lg'/>
            {!showDialog ? 
                <div style={styles.content}>
                    {domains?.length > 0 ? (
                        <List component='nav' aria-labelledby='nested-list-programs'>
                            {domains.map((domain, idx) => (
                                <DomainItem
                                    key={`domain-item__${idx}`}
                                    item={{idx, ...domain}}
                                />
                            ))}
                        </List>
                    ) : (<Paper style={styles.tablePaper} elevation={10}><EmptyList /></Paper>)}
                </div> : 
                <DomainDialog open={showDialog} close={handleClose} />
            }
        </div> 
    );
}

export default Domains;