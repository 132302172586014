import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Tables.css.js';

const TableIcon = forwardRef((props, ref) => (
	<div {...props} ref={ref}>
		<FontAwesomeIcon
			icon={['fal', props.icon]}
			style={styles.iconStyle}
		/>
	</div>
));

const TableIcons = {
    Add: forwardRef((props, ref) =>
		<TableIcon icon='plus' ref={ref} {...props}/>
	),
    Check: forwardRef((props, ref) =>
		<TableIcon icon='check' ref={ref} {...props}/>
	),
    Clear: forwardRef((props, ref) =>
		<TableIcon icon='times' ref={ref} {...props}/>
	),
    Delete: forwardRef((props, ref) =>
		<TableIcon icon='trash-alt' ref={ref} {...props}/>
	),
    DetailPanel: forwardRef((props, ref) =>
		<TableIcon icon='angle-right' ref={ref} {...props}/>
	),
    Edit: forwardRef((props, ref) =>
		<TableIcon icon='pen' ref={ref} {...props}/>
	),
    Export: forwardRef((props, ref) =>
		<TableIcon icon='file-export' ref={ref} {...props}/>
	),
    Filter: forwardRef((props, ref) =>
		<TableIcon icon='filter' ref={ref} {...props}/>
	),
    FirstPage: forwardRef((props, ref) =>
		<TableIcon icon='fast-backward' ref={ref} {...props}/>
	),
    LastPage: forwardRef((props, ref) =>
		<TableIcon icon='fast-forward' ref={ref} {...props}/>
	),
    NextPage: forwardRef((props, ref) =>
		<TableIcon icon='angle-right' ref={ref} {...props}/>
	),
    PreviousPage: forwardRef((props, ref) =>
		<TableIcon icon='angle-left' ref={ref} {...props}/>
	),
    ResetSearch: forwardRef((props, ref) =>
		<TableIcon icon='times' ref={ref} {...props}/>
	),
    Search: forwardRef((props, ref) =>
		<TableIcon icon='search' ref={ref} {...props}/>
	),
    SortArrow: forwardRef((props, ref) =>
		<TableIcon icon='long-arrow-down' ref={ref} {...props}/>
	),
    ThirdStateCheck: forwardRef((props, ref) =>
		<TableIcon icon='check' ref={ref} {...props}/>
	),
    ViewColumn: forwardRef((props, ref) =>
		<TableIcon icon='line-columns' ref={ref} {...props}/>
	)
};

export default TableIcons;