const styles = {
    pageContainer: {
        minWidth: '90vw',
        maxWidth: '1500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    articleListContainer: {
        padding: '20px 0 0 0',
        width: '100%',
        maxWidth: '1500px',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    dialogContainer: {
        width: '100%',
        marginTop: '20px',
        display: 'inline-flex',
        justifyContent: 'flex-start'
    },
    fullListItem: {
        display: 'inline-flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '3px',
        width: '80vw',
        maxWidth: '1290px'
    },
    summaryItem: {
        width: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 5px 0 15px',
    },
    summaryItemTitle: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        fontWeight: 'bold',
	    color: '#10406B',
	    fontSize: '1.25rem',
    },
    editIcon: {
        fontSize: '1.4rem',
        color: 'rgba(16,64,107,0.7)',
        padding: '0',
        margin: '0 10px',
    },
    detailContainer: {
        width: '100%',
        diaply: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '20px'
    },
    articleTitle: {
        fontWeight: 'bold',
        fontSize: '2rem',
        marginBottom: '20px',
    },
    articleText: {
        marginBottom: '20px',
        padding: '0 15%',
        fontSize: '1.25rem',
        textAlign: 'left'
    },
    buttonHeader: {
        width: '100%',
        fontWeight: 'bold',
        fontSize: '1.4rem',
        textAlign: 'center',
        marginTop: '50px'
    },
    buttonGroupContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    activeContainer: {
        backgroundColor: 'rgb(228,233,237)'
    },
    buttonGroupTitleRow: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 30px 0 30px'
    },
    buttonGroup: {
        width: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '20px',
    },
    divider: {
        borderBottom: 'solid 1px #10406B',
        width: '100%',
        paddingBottom: '20px'
    },
    buttonGroupButton: {
        width: '100%',
        backgroundColor: 'rgb(9,44,126)',
        fontWeight: '700',
        borderRadius: '5px',
        fontSize: '20px',
        lineHeight: '48px',
        margin: '5px',
        padding: '5px',
        color: 'white',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    buttonChevron: {
        width: '24px'
    },
    buttonText: {
        width: '100%',
        marginLeft: '10px',
        marginRight: '10px'
    },
    addButtonGroupRow: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        margin: '20px 0 10px 0',
        paddingRight: '20px'
    },
    addGroupText: {
        paddingRight: '10px',
        opacity: '0.5'
    },
    buttonsListContainer: {
        marginTop: '20px',
        width: '100%',
        border: '1px solid rgba(0,0,0,0.25)',
        borderRadius: '25px',
        padding: '20px'
    },
    articleKey: {
        display: 'inline-flex',
        whiteSpace: 'nowrap',
        marginRight: '25px',
        color: 'rgb(189,189,189)',
        fontSize: '1.25rem'
    },
    progress: {
        backgroundColor: 'aliceblue'
    },
    formButtonRow: {
        width: '100%',
        padding: '100px 100px 20px 100px',
        display: 'inline-flex',
        justifyContent: 'space-between',
    },
    submitButtonContinaer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    submitButton: {
        width: '100px',
        height: '40px',
        marginTop: '20px'
    },
    submitColor: {
        backgroundColor: '#10406B',
        color: 'white',
    },
    disabledColor: {
        backgroundColor: 'rgb(224,224,224)',
        color: 'rgb(166,166,166)',
    },
    hover: {
        cursor: 'pointer',
        backgroundColor: 'rgb(66,150,180)',
        opacity: '0.75',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
    },
    linkDiv: {
        marginRight: '25px',
        display: 'inline-flex',
        minWidth: '200px',
        justifyContent: 'space-around'
    },
    linkContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: '20px'
    },
    recOfferContainer: {
        color: '#777',
        textAlign: 'justify',
        fontWeight: '300',
        lineHeight: '1.3',
        fontSize: '1.5rem',
        width: '90%',
        maxWidth: '1200px',
        padding: '15px 30px',
        margin: '20px',
        backgroundColor: 'aliceblue'
    },
    offerButtonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px'
    },
    offerButton: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        borderRadius: '75px',
        WebkitTransition: '.5s all',
        transition: '.5s all',
        whiteSpace: 'nowrap',
        border: 'none',
        fontSize: '1.5rem',
        width: '200px',
        height: '50px',
        backgroundColor: '#2670C5',
        color: '#fff',
    },
    offerHover: {
        backgroundColor: '#f5f5f5',
        color: 'black',
        cursor: 'pointer',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
        fontSize: '1.3rem',
    },
    recTitleContainer: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'flex-start',
        textAlign: 'left',
        alignItems: 'baseline',
    },
    recIcon: {
        fontSize: '1.5rem',
        marginRight: '10px'
    },
    title: {
        fontWeight: '700',
        fontSize: '1.8rem',
        paddingBottom: '15px'
    },
    contentText: {
        fontSize: '1.3rem',
        padding: '15px 0'
    },
    boldText: {
        fontWeight: '700'
    },
    offerExample: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px 20px'
    },
    loading: {
        minWidth: '100%',
        height: '100%',
        minHeight: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    recContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        border: '1px solid rgba(0,0,0,0.25)',
        borderRadius: '25px',
        padding: '20px',
        marginBottom: '20px'
    },
    recTitle: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: '40px',
        fontSize: '1.1rem',
        fontWeight: '700',
    },
    blockContainer: {
        width: '100%',
        maxWidth: '600px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '30px',
        border: '1px solid rgba(15, 64, 107, 0.5)',
        borderRadius: '10px',
        backgroundColor: 'rgb(239,248,255)'
    },
    blockTitle: {
        width: '100%',
        textAlign: 'center',
        fontSize: '1.4rem',
        fontWeight: 'bold',
        marginBottom: '20px'
    },
    blockOffersListContainer: {
        width: '100%',
        maxWidth: '800px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    blockOfferContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    blockOfferButton: {
        width: '350px',
        height: '40px',
        backgroundColor: 'rgb(9,44,126)',
        color: 'rgb(255,255,255)',
        fontSize: '1.1rem',
        marginBottom: '10px',
        borderRadius: '10px'
    },
    chipButton: {
        marginRight: '100px'
    },
    selectorContainer: {
        width: '100%',
        maxWidth: '350px',
        margin: '30px 0 0 30px'
    },
    tabContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '0',
        width: '100%',
        maxWidth: '1550px',
        minWidth: '90vw'
    },
};

export default styles;