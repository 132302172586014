import React, { useState } from 'react';
import styles from './DomainItem.css.js';
import AcceptableMedia from '../AcceptableMedia';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DomainSummary = props => {
	const { programDomain, isExpanded, onEdit } = props;
	const [ isHovering, setHovering ] = useState(false);
	
	const editButtonStyle = Object.assign({},
        styles.editButton,
        isHovering && styles.hoverRow
	);
	
	return (
		<ListItem 
			dense 
			style={styles.summaryItem}
		>
			<ListItemAvatar>
			{isExpanded ? 
				<div
					onMouseEnter={() => setHovering(true)}
					onMouseLeave={() => setHovering(false)}
					style={editButtonStyle}
					onClick={onEdit}
				>
					<FontAwesomeIcon style={styles.editIcon} icon={['fal', 'pen']} />
				</div> :
				<Avatar
					alt={`Avatar n°${programDomain.title}`}
					src={`${programDomain.avatar === 'N/A' ? 'https://bluekeel-hosted-assets.s3.amazonaws.com/bk_logo.png' : programDomain.avatar}`}
					variant='rounded'
				/>
				}
			</ListItemAvatar>
			<Tooltip 
				title={
				programDomain?.pid?.length > 1 ? 
					programDomain.pid.map((pid, idx) => (
						<span key={`pid-tag-${idx}`} style={styles.tooltip}>{pid}</span>
					)) :
					<span style={styles.tooltip}>{programDomain.pid}</span>
				}
				arrow={true}
				placement='top-start'
			>
				<div style={styles.tooltipText}>PIDs</div>
			</Tooltip>
			<div style={styles.titleContainer}>
				<div style={styles.titlePrime}>
					{programDomain.title}
				</div>
				<div style={styles.titleSecond}>
					{programDomain.description}
				</div>
			</div>
			<AcceptableMedia accepts={programDomain.accepts} />
		</ListItem>
	);
}

export default DomainSummary;