import React, { memo } from 'react';
import styles from './OfferBlockDialog.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const BlockOffer = ({ offerItem, index }) => {
    return (
        <Draggable draggableId={offerItem.id} index={index}>
            {provided => (
                <div 
                    style={{...styles.blockOfferContainer}}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div style={styles.blockOfferButton} >
                        {offerItem.display}
                    </div>
                </div>
            )}
        </Draggable>
    );
};

const OfferList = memo(({ offers }) => {
    return offers.map((offer, index) => (
        <BlockOffer key={offer.id} index={index} offerItem={offer}/>
    ));
});

const OfferDnd = ({ state, setState }) => {

    function onDragEnd(result) {
        if (!result.destination) return;

        if (result.destination.index === result.source.index) return;

        const offers = reorder(
            state.offers,
            result.source.index,
            result.destination.index
        );
        setState({ offers });
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div style={styles.blockContainer}>
                <div style={styles.blockTitle}>Exclusive Offers!</div>
                <Droppable droppableId='list'>
                    {provided => (                        
                        <div 
                            style={styles.blockOffersListContainer} 
                            ref={provided.innerRef} {...provided.droppableProps}
                        >
                            <OfferList offers={state.offers} />
                            {provided.placeholder}
                        </div>                        
                    )}
                </Droppable>
            </div>
        </DragDropContext>
    );
};

export default OfferDnd;