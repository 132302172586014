const styles = {
    textFields: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    textInput: {
        marginBottom: '20px'
    },
    selectFields: {
        width: '50%',
        margin: '20px 0',
        display: 'inline-flex',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    checkInput: {
        width: '100%',
        textAlign: 'right',
        paddingLeft: '30px',
        display: 'inline-flex',
    },
    groupListContainer: {
        width: '100%'
    }
};
export default styles;