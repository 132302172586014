import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { useQuery } from '@apollo/client';
import { PROGRAM_TABLE } from '../../../graphql/queries';
import Loading from '../../Loading';
import RangeSelector from '../../RangeSelector';
import ActionButton from '../../ActionButton';
import ProgramDialog from '../../Forms/ProgramDialog';
import VerticalPathsTable from './VerticalPathsTable';
import styles from './Domains.css.js';

const DomainVertical = ({ domain, vertical }) => {
    const { dispatchSelection } = useContext(AppContext);
    const [ openDialog, toggleDialog ] = useState(false);
    const [ dateRange, setDateRange ] = useState('24hr');

    const { loading, error, data } = useQuery(
        PROGRAM_TABLE, { variables: { pid: domain.pid, vertical } }
    );

    if (loading) return <div style={styles.loadingItemContent}><Loading /></div>;

    if (error) return <div>{`Error occured: ${error.message}`}</div>;

    const tablePrograms = data?.getProgramsByVertical?.body ?? [];

    const handleClose = () => {
        dispatchSelection({ type: 'CLEAR_SELECTION' });
        toggleDialog(false);
    };

    const handleAddClick = () => {
        dispatchSelection({ type: 'ADD_PROGRAM', payload: { domain, vertical } });
        toggleDialog(true);
    };

    const handleEditClick = (e, rowData) => {
        e.stopPropagation();
        dispatchSelection({ type: 'EDIT_PROGRAM', payload: { domain, program: rowData } });
        toggleDialog(true);
    };

    const handleDeleteClick = (e, rowData) => {
        e.stopPropagation();
        dispatchSelection({ 
            type: 'DELETE_SELECTION', 
            payload: { 
                type: 'program_path', 
                item_id: rowData.id,
                domain,
                vertical
            } 
        });
    };

    return (
    openDialog ? 
        <ProgramDialog open={openDialog} close={handleClose}/> :
        <div style={styles.listContainer}>
            <div style={styles.tableTitle}>
                <ActionButton 
                    icon='plus'
                    bg='lightBlue'
                    bgHover='green'
                    handleClick={handleAddClick} 
                />
                <RangeSelector range={dateRange} setRange={setDateRange} />
            </div>
            <VerticalPathsTable 
                tableData={tablePrograms} 
                range={dateRange}
                handleDelete={handleDeleteClick}
                handleEdit={handleEditClick}
            />
        </div>
    );
};

export default DomainVertical;