import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../components/Loading';
import styles from '../components/Global/GlobalStyle.css';

const ProtectedRoute = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div style={styles.app}>
            <Loading />
        </div>
    ),
  });

  return <Component />;
};

export default ProtectedRoute;