const styles = {
    pageContainer: {
        minWidth: '90vw',
        maxWidth: '1500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    content: {
        padding: '20px 0 0 0',
        width: '100%',
        maxWidth: '1500px',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    tablePaper: {
        width: '100%',
        maxWidth: '1300px',
        display: 'flex',
        margin: '0',
        backgroundColor: 'rgba(16,64,107,0.45)',
        borderRadius: '5px',
        padding: '0'
    },
    fullListItem: {
        display: 'inline-flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '3px',
        width: '80vw',
        maxWidth: '1290px'
    },
    summaryItem: {
        width: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 5px 0 15px',
    },
    editButton: {
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    hoverRow: {
        cursor: 'pointer',
        backgroundColor: '#FFF',
        borderRadius: '50%',
        color: 'rgb(66,150,180)',
        opacity: '0.75',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
    },
    editIcon: {
        fontSize: '1.4rem',
        color: 'rgba(16,64,107,0.7)',
    },
    tooltip: {
        fontSize: '1.4rem',
        padding: '5px',
        lineHeight: '1.1',
    },
    tooltipText: {
        borderRight: 'solid 1px #10406B',
        padding: '0 10px',
        marginRight: '10px',
        color: 'rgba(1, 24, 66, 0.4)',
        fontSize: '1.2rem',
        display: 'flex',
        alignItems: 'center',
        minWidth: '65px',
    },
    titleContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    titlePrime: {
        fontWeight: 'bold',
        color: '#10406B',
        fontSize: '1.4rem',
    },
    titleSecond: {
        fontSize: '1rem',
        color: 'rgba(0,0,0,0.5)',
    },
    acceptableMedia: {
        borderLeft: 'solid 1px #10406B',
        fontSize: '0.9rem',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        color: 'rgba(0,0,0,0.4)',
        padding: '0 10px',
    },
    mediaType: { paddingLeft: '5px' },
    programContent: {
        width: '100%',
        overflowX: 'hidden',
        backgroundColor: 'aliceblue'
    },
    verticalContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '10px'
    },
    listContainer: {
        maxWidth: '1300px',
        minWidth: 'calc(100% - 60px)',
        padding: '10px 30px 20px 30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'flex-start',
        minHeight: 'fit-content',
    },
    tableTitle: {
        fontSize: '2rem',
        fontWeight: '200',
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        color: 'rgba(15, 64, 107, 0.5)',
        marginBottom: '10px'
    },
    // Records Table
    loadingTable: {
        minWidth: '100%',
        height: '100%',
        minHeight: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    offersHeader: {
        backgroundColor: 'rgba(16,64,107,0.92)',
        color: 'rgba(255,255,255,1)',
        padding: '2px 10px',
        fontSize: '1rem',
        fontWeight: '300'
    },
    recordTablePaper: {
        width: '100%',
        maxWidth: '1300px',
        margin: '20px 0 0 0',
        borderRadius: '5px',
    },
    recordsTable: {
        borderRadius: '5px',
        backgroundColor: 'aliceblue'
    },
    offerRow: {
        color: 'rgba(16,64,107,1)',
        backgroundColor: '#FFF',
    }
};
export default styles;