const styles = {
    loadingTable: {
        minWidth: '100%',
        height: '100%',
        minHeight: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    header: {
        backgroundColor: 'rgba(16,64,107,0.92)',
        color: 'rgba(255,255,255,1)',
        padding: '2px 10px',
    },
    rowStyle: {},
    table: { borderRadius: '5px' },
    itemLabel: {
        paddingLeft: '10px',
        fontSize: '1rem',
    },
    expandCell: {
        width: '100%',
        padding: '0'
    },
    toggleIcon: {
        color: 'rgba(16,64,107,0.7)',
        width: '15px',
        height: '15px',
    },
    iconStyle: {
        width: '20px',
        height: '20px',
        color: 'rgba(15,64,107,1)',
    },
    iconStyleWhite: {
        width: '20px',
        height: '20px',
        color: '#f5f5f5',
    },
    detailPaper: {
        margin: '10px 10%',
        // backgroundColor: 'rgba(16,64,107,0.25)',
        borderRadius: '5px',
    }
};

export default styles;