const styles = {
    stepContentContainer: {
    },
    stepContentQuestion: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        position: 'relative',
    },
    stepContentTitle: {
        borderBottom: '1px solid rgba(15,64,107,0.5)',
        color: 'rgba(15,64,107,0.5)',
        fontWeight: '500',
        fontSize: '1.6rem',
        width: '100%',
        textAlign: 'left'
    },
    optinTypeTitle: {
        color: 'rgba(15,64,107,0.5)',
        fontWeight: '300',
        fontSize: '1.1rem',
        textAlign: 'left'
    },
    stepContentTable: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 0 0 45px',
    },
    confirmTable: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 0 0 45px',
    },
    confirmTableRow: {
        display: 'flex',
        alignItems: 'baseline',
        marginBottom: '5px'
    },
    confirmTableKey: {
        fontSize: '1.2rem',
        marginRight: '30px',
    },
    confirmTableValue: {
        color: '#10406B',
        fontSize: '1.4rem',
    },
    stepContentTableRow: {
        display: 'flex',
        alignItems: 'baseline',
    },
    stepContentTableKey: {
        fontSize: '1rem',
        marginRight: '30px',
    },
    stepContentTableValue: {
        color: '#10406B',
        fontSize: '1.4rem',
        fontWeight: 'bold'
    },
    optinTypeValue: {
        color: '#10406B',
        fontWeight: 'bold'
    },
    typeInputContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '1000px',
        margin: '50px 0'
    },
    typeInput: {
        minWidth: '500px'
    },
    pidList: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    buttonGroup: {
        margin: '20px',
        padding: '20px 100px',
        display: 'inline-flex',
        justifyContent: 'flex-end',
    },
    stepButton: {
        display: 'inline-flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderRadius: '75px',
        minHeight: '50px',
        WebkitTransition: '.5s all',
        transition: '.5s all',
        whiteSpace: 'nowrap',
        border: 'none',
        fontSize: '1.1rem',
        outline: 'none',
        margin: '2% 25% 0 25%',
        color: 'white', 
        backgroundColor: 'rgb(15,64,107)',
        paddingLeft: '6%'
    },
    nextButton: {
        display: 'inline-flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    stepButtonIcon: {
        fontSize: '1.5rem',
        marginRight: '20px',
    },
    hoverButton: {
        cursor: 'pointer',
        backgroundColor: 'rgb(66,150,180)',
        opacity: '0.75',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
    },
    progressButton: {
        color: 'rgb(162, 201, 252) !important',
        margin: '0 10px',
    },
    selections: {
        width: '100%',
        margin: '20px 0 0 0',
    }
};

export default styles;