const styles = {
    loadingTable: {
        minWidth: '100%',
        height: '100%',
        minHeight: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    offersHeader: {
        backgroundColor: 'rgba(16,64,107,0.92)',
        color: 'rgba(255,255,255,1)',
        padding: '2px 10px',
        fontSize: '1rem',
        fontWeight: '300'
    },
    actionContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 'unset',
    },
    itemLabel: {
        padding: '10px',
        fontSize: '1rem',
    },
    tablePaper: {
        width: '100%',
        maxWidth: '1300px',
        margin: '20px 0 0 0',
        borderRadius: '5px',
    },
    endpointsTable: {
        borderRadius: '5px',
        backgroundColor: 'aliceblue'
    },
    offerRow: {
        color: 'rgba(16,64,107,1)',
        backgroundColor: '#FFF',
    },
    toggleIcon: {
        fontSize: '1.4rem',
        color: 'rgba(16,64,107,0.7)',
        margin: '5px'
    }
};

export default styles;