import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../context';
import styles from './Endpoints.css.js';
import ActionBar from '../../../ActionBar';
import EndpointsList from '../../../EndpointsList';
import EndpointDialog from '../../../Forms/EndpointDialog';

const Offers = () => {
    const { dispatchSelection } = useContext(AppContext);
    const [ showDialog, setShowDialog ] = useState(false);
    
    const handleClose = () => {
        dispatchSelection({ type: 'CLEAR_SELECTION' });
        setShowDialog(false);
    };

    const handleAddClick = () => {
        dispatchSelection({ type: 'CLEAR_SELECTION' });
        setShowDialog(true);
    };

    const handleEditClick = (event, rowData) => {
        event.preventDefault();
        dispatchSelection({ type: 'EDIT_ENDPOINT', payload: rowData });
        setShowDialog(true);
    };

    return (
        <>
            <ActionBar text='Offer' title='ENDPOINTS' handleClick={handleAddClick} size='Lg'/>
            <div style={styles.content}>
                {!showDialog ? 
                    <EndpointsList handleEdit={handleEditClick}/> : 
                    <EndpointDialog open={showDialog} close={handleClose} />
                }
            </div>
        </>
    )
};

export default Offers;