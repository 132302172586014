import React, { useRef, useEffect, useContext } from 'react';
import { AppContext } from '../../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth0 } from "@auth0/auth0-react";
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useNavigate } from 'react-router-dom';
import styles from './Sidebars.css.js';

const DrawerItems = () => {
    let navigate = useNavigate();
    const { appState, dispatchApp } = useContext(AppContext);
    const { logout } = useAuth0();
    const componentIsMounted = useRef(true);

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    };
    
    useEffect(() => {
        if (componentIsMounted.current) {
            let path = window.location.pathname;
            switch (path) {
                case '/':
                    dispatchApp({ type: 'HOME_TAB_ACTIVE' });
                    break;
                case '/domains':
                    dispatchApp({ type: 'DOMAINS_TAB_ACTIVE' });
                    break;
                case '/programs':
                    dispatchApp({ type: 'PROGRAMS_TAB_ACTIVE' });
                    break;
                case '/offerings':
                    dispatchApp({ type: 'OFFERINGS_TAB_ACTIVE' });
                    break;
                case '/push':
                    dispatchApp({ type: 'PUSH_TAB_ACTIVE' });
                    break;
                case '/reports':
                    dispatchApp({ type: 'REPORTS_TAB_ACTIVE' });
                    break;
                case '/articles':
                    dispatchApp({ type: 'ARTICLES_TAB_ACTIVE' });
                    break;
                default:
                    return;
            };
        }

        return () => componentIsMounted.current = false;
        // eslint-disable-next-line
    }, []);

    const handleTabClick = (e, tab) => {
        e.preventDefault();
        switch (tab) {
            case 'home':
                dispatchApp({ type: 'HOME_TAB_ACTIVE' });
                navigate('/');
                break;
            case 'domains':
                dispatchApp({ type: 'DOMAINS_TAB_ACTIVE' });
                navigate('/domains');
                break;
            case 'programs':
                dispatchApp({ type: 'PROGRAMS_TAB_ACTIVE' });
                navigate('/programs');
                break;
            case 'offerings':
                dispatchApp({ type: 'OFFERINGS_TAB_ACTIVE' });
                navigate('/offerings');
                break;
            case 'push':
                dispatchApp({ type: 'PUSH_TAB_ACTIVE' });
                navigate('/push');
                break;
            case 'reports':
                dispatchApp({ type: 'REPORTS_TAB_ACTIVE' });
                navigate('/reports');
                break;
            case 'articles':
                dispatchApp({ type: 'ARTICLES_TAB_ACTIVE' });
                navigate('/articles');
                break;
            default:
                return;
        };
    };

    const SidebarItem = ({ handleClick, icon, text, isActive }) => {
        const SidebarStyle = Object.assign({}, isActive && styles.activeTab);
        return (
            <ListItem 
                disableGutters 
                button 
                onClick={handleClick}
                style={SidebarStyle}
            >
                <ListItemIcon style={styles.iconContainer}>
                    <FontAwesomeIcon style={styles.sidebarIcon} icon={['fal', icon]} size='lg' />
                </ListItemIcon>
                <span style={styles.sidebarTabTitle}>{text}</span>
            </ListItem>
        );
    }

    return (
        <div style={styles.drawerContainer}>
            <List>
                <SidebarItem 
                    handleClick={(e) => handleTabClick(e, 'home')}
                    isActive={appState.homeTabActive}
                    icon='home'
                    text='HOME'
                />
                <SidebarItem 
                    handleClick={(e) => handleTabClick(e, 'domains')}
                    isActive={appState.domainsTabActive}
                    icon='route'
                    text='DOMAINS'
                />
                <SidebarItem 
                    handleClick={(e) => handleTabClick(e, 'programs')}
                    isActive={appState.programsTabActive}
                    icon='sitemap'
                    text='PROGRAMS'
                />
                <SidebarItem 
                    handleClick={(e) => handleTabClick(e, 'offerings')}
                    isActive={appState.offeringsTabActive}
                    icon='bullseye-arrow'
                    text='TEMPLATES'
                />             
                <SidebarItem 
                    handleClick={(e) => handleTabClick(e, 'reports')}
                    isActive={appState.reportsTabActive}
                    icon='file-chart-column'
                    text='TRAFFIC REPORTS'
                />             
                <SidebarItem 
                    handleClick={(e) => handleTabClick(e, 'articles')}
                    isActive={appState.articlesTabActive}
                    icon='book'
                    text='ARTICLE CONTROL'
                />             
                <SidebarItem 
                    handleClick={(e) => handleTabClick(e, 'push')}
                    isActive={appState.pushTabActive}
                    icon='balance-scale-right'
                    text='PUSH DISTRIBUTION'
                />             
            </List>
            <Divider />
            <List>
                <SidebarItem 
                    handleClick={handleLogout}
                    isActive={false}
                    icon='sign-out'
                    text='LOGOUT'
                />
            </List>
        </div>
    );
};

export default DrawerItems;