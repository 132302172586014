import React from 'react';
import { useQuery } from '@apollo/client';
import { ALL_OPTINS } from '../../graphql/queries';
import Paper from '@material-ui/core/Paper';
import EmptyList from '../EmptyList';
import Loading from '../Loading';
import styles from './OptInsList.css.js';
import EndpointOffersColumns from '../Tables/TableColumns/EndpointOffersColumns';
import TableIcons from '../Tables/TableIcons';
import MaterialTable from 'material-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OptInsList = ({ handleEdit }) => {
    const { loading, error, data } = useQuery(ALL_OPTINS);

    if (loading) return <div style={styles.loadingTable}><Loading /></div>;

    if (error) return <div>{`Error occured fetching optin offers list: ${error.message}`}</div>;

    const optinsArray = data.fetchAllOptins.body;
    
    return (
        <Paper style={styles.tablePaper} elevation={10}>
            {optinsArray?.length > 0 ? 
                <MaterialTable
                    icons={TableIcons}
                    columns={EndpointOffersColumns()}
                    data={optinsArray.map(optin => {
                        return {
                            id: optin.id,
                            name: optin.title,
                            type: optin.type,
                            offer_page: optin.offer_page,
                            description: optin.description,
                            link: optin.link,
                            jump: optin.jump,
                            four_button: optin.four_button,
                            offers: optin.offers,
                            restricted: optin.restricted,
                            states: optin.states
                        };
                    })}
                    options={{
                        search: true,
                        sorting: true,
                        showTitle: false,
                        paging: false,
                        showFirstLastPageButtons: true,
                        headerStyle: styles.offersHeader,
                        rowStyle: styles.offerRow,
                    }}
                    style={styles.optinTable}
                    actions={[
                        {
                            icon: () => (
                                <div style={styles.actionContainer}>
                                    <FontAwesomeIcon style={styles.toggleIcon} icon={['fal', 'pen']} />
                                </div>
                            ),
                            tooltip: 'Edit Opt-In',
                            onClick: handleEdit
                        },
                    ]}
                /> : <EmptyList />
            }
        </Paper>
    )
};

export default OptInsList;