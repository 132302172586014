const styles = {
    error: {
        color: 'red',
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'center'
    },
    count: {
        display: 'inline-flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    countText: {
        fontSize: '1.3rem',
        lineHeight: '1',
        padding: '5px 20px',
        // marginLeft: '60px',
        display: 'inline-flex',
    }
};

export default styles;