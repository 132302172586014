import React from 'react';
import styles from './CustomColumns.css.js';
import { makePagePretty, capitalizeValue } from '../../../utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipCell from '../CustomCells/TooltipCell';
import GroupItemCount from '../../GroupItemCount';

const ActiveOffersColumns = (program, range) => ([
	{ 
		title: 'Name', 
        field: 'name',
        cellStyle: {...styles.titleCell}, 
		render: rowData => capitalizeValue(rowData.name) 
	},
	{ 
		title: 'Page Type', 
        field: 'offer_page',
        align: 'center',
        cellStyle: {...styles.tableCell}, 
		render: rowData => {
            const linkButtons = rowData?.selection_links?.map((link) => (capitalizeValue(link.text))) ?? [];
            return (
                rowData.offer_page === 'selection' ? 
                <TooltipCell linkArray={linkButtons} selector='text'/> :
                makePagePretty(rowData.offer_page) 
            )
        }
	},
	{ 
		title: 'Link', 
        field: 'url',
        align: 'center',
        cellStyle: styles.tableCell,
		render: rowData => (
            rowData.offer_page === 'mNet' ?
            capitalizeValue(rowData.url) :
            <TooltipCell linkArray={[rowData.url]} selector='url'/>
        )
	},
	{ 
		title: 'Jump', 
        field: 'jump',
        align: 'center',
        cellStyle: styles.tableCell, 
		render: rowData => <TooltipCell linkArray={[rowData.jump]} selector='jump'/>
	},
	{ 
		title: 'Fenced', 
        field: 'jump',
        align: 'center',
        cellStyle: styles.tableCell, 
		render: rowData => (
            rowData.restricted ?
            <TooltipCell linkArray={rowData.states} selector='states'/> :
            <FontAwesomeIcon style={styles.emptyColumn} icon={['fal', 'empty-set']} />
        )
	},
	{ 
		title: 'Usage %', 
        field: 'usage',
        align: 'center',
        cellStyle: styles.tableCell, 
		render: rowData => (
            (rowData.usage && rowData.usage !== 0) ? 
			rowData.usage : 
			<FontAwesomeIcon style={styles.emptyColumn} icon={['fal', 'empty-set']} />
        )
	},
	{ 
		title: '# Views', 
        field: 'id',
        align: 'center',
        cellStyle: styles.tableCell, 
		render: rowData => (
            <GroupItemCount 
                program_id={program.id}
                group_id={program.endpoint_group}
                offer_id={rowData.id}
                range={range || program.range}
            />
        )
	}
]);

export default ActiveOffersColumns;