import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { OPTIN_GROUPS_BY_TYPE } from '../../graphql/queries';
import EmptyList from '../EmptyList';
import Loading from '../Loading';
import styles from './OptinGroupList.css';
import GroupDetails from '../Tables/GroupDetails';
import OptInColumns from '../Tables/TableColumns/OptinsColumns';
import TableIcons from '../Tables/TableIcons';
import MaterialTable, { MTableGroupRow } from 'material-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Paper from '@material-ui/core/Paper';

const RenderClusterRow = (props) => {
    useEffect(() => {
        // props.groupData.isExpanded = true;
        // eslint-disable-next-line
    },[]);

    return (
        <MTableGroupRow {...props} />
    )
};

const OptInGroupList = ({ isSelection, handleEdit, type, activeOptin, setOptinGroup }) => {
    const { loading, error, data } = useQuery(OPTIN_GROUPS_BY_TYPE, { variables: { type: type } });

    if (loading) return <div style={styles.loadingTable}><Loading /></div>;

    if (error) return <div>{`Error occured fetching ${type} opt-in list: ${error.message}`}</div>;

    const handleGroupSelection = (event, rowData) => {
        event.stopPropagation();
        if(rowData.id === activeOptin) {
            setOptinGroup('N/A');
        } else {
            setOptinGroup(rowData.id);
        }
    };

    const actionArray = () => (
        isSelection ?
            [{ disabled: true, hidden: true }] :
            [{
                icon: () => {
                    return (
                        <div style={styles.actionContainer}>
                            <FontAwesomeIcon style={styles.toggleIcon} icon={['fal', 'pen']} />
                        </div>
                    )
                },
                tooltip: 'Edit Opt-In Group',
                onClick: handleEdit
            }]
    );

    const optinGroupsArray = data.fetchOptinGroupsByType.body;

    return (
        <Paper style={styles.tablePaper} elevation={10}>
            {optinGroupsArray.length > 0 ? 
                <MaterialTable
                    icons={TableIcons}
                    columns={OptInColumns()}
                    data={optinGroupsArray.map((optin_group, idx) => {
                        return {
                            id: optin_group.id,
                            name: optin_group.name,
                            type: optin_group.type,
                            group_items: optin_group.group_items,
                            usage: optin_group.usage,
                        };
                    })}
                    options={{
                        selection: isSelection,
                        search: false,
                        showSelectAllCheckbox: false,
                        showTitle: false,
                        toolbar: false,
                        paging: false,
                        header: false,
                        detailPanelColumnAlignment: 'right',
                        rowStyle: styles.groupRow,
                        selectionProps: rowData => ({
                            checked: rowData.id === activeOptin,
                            onClick: (event) => handleGroupSelection(event, rowData)
                        })
                    }}
                    style={styles.groupTable}
                    detailPanel={GroupDetails('optin')}
                    onRowClick={(_, __, togglePanel) => togglePanel()}
                    actions={actionArray()}
                    components={{ GroupRow: props => <RenderClusterRow {...props}/> }}
                /> : <EmptyList />
            }
        </Paper>
    )
};

export default OptInGroupList;