import React, { useContext, useRef, useEffect } from 'react';
import { AppContext } from '../../../context';
import Tabs from '@material-ui/core/Tabs';
import Grow from '@material-ui/core/Grow';
import Tab from '@material-ui/core/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './TabGroups.css.js';

const MarketPlaceTabs = () => {
    const { dispatchApp, appState } = useContext(AppContext);
    const { subcategoryTabValue } = appState;
    const tabsActions = useRef();

    useEffect(() => {
        tabsActions.current.updateIndicator()
        // setTimeout(() => tabsActions.current.updateIndicator(), 200);
    }, []);
    
    const handleSubCatChange = (e, newValue) => {
        e.preventDefault();
        dispatchApp({ type: 'SUBCATEGORY_TAB_SELECT', payload: newValue});
    };

    const a11yProps = (index) => {
        return {
            id: `market-tab-${index}`,
            className: 'market-tab',
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const MarketTab = (props) => (
        <Tab 
            textColor='inherit'
            label={<div style={styles.subTabTitle}>{props.labeltext}</div>}
            icon={<FontAwesomeIcon icon={['fal', props.iconname]} style={styles.subTabIcon} />}
            {...props} 
        />
    );

    return (
        <Grow in={true}>
            <Tabs 
                value={subcategoryTabValue} 
                onChange={handleSubCatChange} 
                aria-label='subtabpanel' 
                centered={true}
                style={styles.subTabsEl}
                action={tabsActions}
            >
                <MarketTab 
                    labeltext='Categories'
                    iconname='object-group'
                    style={styles.tabIcon}
                    {...a11yProps(0)}
                />
                <MarketTab 
                    labeltext='Verticals'
                    iconname='list'
                    style={styles.tabIcon}
                    {...a11yProps(1)}
                />
            </Tabs>
        </Grow>
    )
};

export default MarketPlaceTabs;