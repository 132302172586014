import React, { useState } from 'react';
import clsx from 'clsx';
import styles from './Sidebars.css.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import TopAppBar from '../TopAppBar';
import DrawerItems from './DrawerItems';
import Fab from '@material-ui/core/Fab';

const Sidebars = ({children}) => {
    const classes = styles.materialStyle();
    const [ open, setOpen ] = useState(false);

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <TopAppBar open={open} setOpen={setOpen} />
            <Drawer
                variant='permanent'
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar} style={styles.backContainer}>                   
                    <Fab 
                        style={styles.backButton} 
                        aria-label='back' 
                        onClick={handleDrawerClose}
                        variant='extended'
                    >
                        <FontAwesomeIcon style={styles.backIcon} icon={['fal', 'chevron-left']} />
                    </Fab>
                </div>
                <Divider />
                <DrawerItems />
            </Drawer>
            <main className={classes.content}>
            <div className={classes.toolbar} />
                {children}
            </main>
        </div>
    );
};

export default Sidebars;