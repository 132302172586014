const styles = {
    formContainer: {
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: 'rgba(15, 64, 107, 0.75)'
    },
    paperContainer: {
        padding: '20px 100px',
        maxWidth: '1000px',
        margin: '20px 0',
        backgroundColor: 'aliceblue'
    },
    headerContainer: {
        fontSize: '2rem',
        fontWeight: '100',
        marginTop: '10px',
        borderBottom: '1px solid rgba(15, 64, 107, 0.5)',
        color: 'rgba(16, 64, 107, 0.5)',
        textAlign: 'right'
    },
    deleteDialogContent: {
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    textContainer: {
        margin: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dialogText: {
        marginTop: '5px',
        fontSize: '1.75rem',
        fontWeight: '300',
        lineHeight: '1.25'
    },
    boldText: {
        fontWeight: 'bold'
    },
    importantText: {
        margin: '0 0 10px 0',
        fontSize: '1.25rem',
        fontWeight: '300',
        textTransform: 'uppercase',
        color: 'red'
    },
    submitButton: {
        width: '100px',
        height: '40px',
        backgroundColor: 'red',
        color: 'white',
    },
    deleteHover: {
        backgroundColor: 'red',
        cursor: 'pointer',
        opacity: '0.75',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
    },
    formButtons: {
        width: '100%',
        padding: '20px 100px',
        display: 'inline-flex',
        justifyContent: 'flex-end',
    },
    buttonProgress: {
        color: 'rgb(162, 201, 252) !important',
        margin: '0 10px',
    },
};

export default styles;