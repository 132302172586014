const styles = {
    tooltipContainer: {
        width: 'fit-content',
        maxWidth: '600px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonText: {
        // margin: '10px',
        lineHeight: '1.1',
        textAlign: 'center',
        maxWidth: '600px'
    },
    indexText: {
        fontSize: '1.1rem',
        fontWeight: '300',
        color: 'rgba(16,64,107,1)'
    },
    valueText: {
        fontSize: '1.1rem',
        textDecoration: 'underline',
        color: 'rgba(16,64,107,1)'
    },
    eyeContainer: {},
    cellIcon: {
        fontSize: '1.3rem',
        color: 'rgb(16,64,107)'
    },
    emptyColumn: {
        color: 'rgba(0,0,0,0.15)',
        fontSize: '1.5rem',
    },
    toolTip: {},
    buttonTextContainer: {
        border: '1px solid #10406B',
        backgroundColor: 'rgba(16,64,107,0.7)',
        color: '#FFF',
        textAlign: 'center',
        borderRadius: '25px',
        width: '200px',
        margin: '5px 0'
    },
    elText: {
        margin: '5px 10px',
        lineHeight: '1',
        whiteSpace: 'nowrap'
    },
};

export default styles;