import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../../../context';
import ControlButtons from './ControlButtons';
import styles from './Steps.css.js';
import OptInGroupList from '../../../../OptinGroupList';

const SelectOptins = ({ close, moveBack, moveToNext }) => {
	const { dispatchSelection, selectionState, appState } = useContext(AppContext);
    const [ activeDebtOptinGroup, setActiveDebtOptinGroup ] = useState(selectionState.debt_group);
    const [ activeCheckingOptinGroup, setActiveCheckingOptinGroup ] = useState(selectionState.checking_group);
    
	useEffect(() => {
		if(appState.authGroup !== 'bk') {
			dispatchSelection({ 
				type: 'SELECT_OPTINS', 
				payload: { 
					debt: 'N/A', 
					checking: 'N/A'
				} 
			});
			moveToNext();
		};
// eslint-disable-next-line
	},[appState.authGroup]);

	const handleSelection = () => {
        dispatchSelection({ 
            type: 'SELECT_OPTINS', 
            payload: { 
                debt: activeDebtOptinGroup, 
                checking: activeCheckingOptinGroup 
            } 
        });
		moveToNext();
    };

	return (
		<div style={styles.stepContentContainer}>
			<div style={styles.stepContentQuestion}>
				<div style={styles.stepContentTitle}>
					Select Opt-In <span style={styles.stepContentTableValue}>Type</span>:
				</div>
                <div style={styles.selections}>
					<OptInGroupList
                        type='debt'
						activeOptin={activeDebtOptinGroup} 
						setOptinGroup={setActiveDebtOptinGroup}
					/>
					<OptInGroupList
                        type='checking'
						activeOptin={activeCheckingOptinGroup}
						setOptinGroup={setActiveCheckingOptinGroup}
					/>
				</div>
                <ControlButtons 
                    close={close}
                    handleNextClick={handleSelection}
                    handleBack={moveBack}
                />
			</div>
		</div>
	);
}

export default SelectOptins;