const styles = {
    tabIcon: {
        fontSize: '1.25rem',
        marginRight: '10px',
        color: 'rgba(16,64,107,1)',
    },
    tabTitle: {
        fontSize: '1.1rem',
        whiteSpace: 'nowrap',
        textAlign: 'left',
        color: 'rgba(16,64,107,1)',
        textTransform: 'none',
        fontWeight: '400'
    },
    subTabIcon: {
        fontSize: '1.25rem',
        margin: '0 15px 0 0',
        color: 'rgba(16,64,107,1)',
    },
    subTabTitle: {
        fontSize: '1.1rem',
        whiteSpace: 'nowrap',
        textAlign: 'left',
        color: 'rgba(16,64,107,1)',
        textTransform: 'none',
        fontWeight: '500'
    },
    subTabsEl: {
        maxHeight: '70px'
    },
    bestLogo: {
        width: '30px',
        maxHeight: '50px',
        margin: '0 5px 0 0',
    },
};

export default styles;