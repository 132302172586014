const styles = {
    dialogParent: {
        margin: '20px'
    },
    textInput: {
        margin: '0 200px 20px 20px'
    },
    mainRow: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'start'
    },
    textFields: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    imageInputContainer: {
        display: 'inline-flex',
        width: '100%'
    },
    imagePaper: {
        width: '200px',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageBgPushPros: {
        backgroundColor: 'rgb(0,130,206)'
    },
    imageBgPushnami: {
        backgroundColor: 'rgb(47,37,116)'
    },
    providerImage: {
        width: '100%',
        height: 'auto',
        padding: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
};

export default styles;