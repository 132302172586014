import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import styles from './EndpointDialog.css.js';
import { stateList } from './usStates';

const GeoRestrictions = ({ states, setStates }) => {
	const [showGeoInputs, toggleGeoInputs] = useState(states?.length > 0);

	const handleStateChange = (event) => {
        setStates(event.target.value)
	};
	
	const toggleGeoInput = () => {
		if(showGeoInputs) {
			setStates([]);
		};
        toggleGeoInputs(!showGeoInputs);
    };

    const handleChipClick = (e, state) => {
        e.preventDefault();
        console.log('chip click:', states.indexOf(state.value))
    }

	return (
		<div style={styles.geoContainer}>
			<FormControlLabel
				style={styles.checkInput}
				control={
					<Checkbox
						checked={showGeoInputs}
						onChange={toggleGeoInput}
						name='show-geo-inputs'
						color='primary'
					/>
				}
				label='Geographic Restrictions?'
			/>
			{showGeoInputs && (
				<FormControl style={styles.statesContainer}>
					<InputLabel id='state-selector-label'>Select States</InputLabel>
					<Select
						multiple
						labelId='state-selector-label'
						id='state-selector-input'
						value={states}
						onChange={handleStateChange}
						input={<Input/>}
						autoWidth={true}
						renderValue={(selectedStates) => (
							selectedStates ? (selectedStates.map((state) => (
								<Chip 
									key={`geo-selection__${state}`} 
									label={state} 
									style={styles.geoChip} 
									onClick={(e) => handleChipClick(e, state)} 
								/>
							))): null
						)}
						inputProps={{ 'aria-label': 'state restriction stelector' }}
					>
						{stateList.map((state) => (
							<MenuItem key={`state-menu-item__${state.key}`} value={state.value}>
								<Checkbox checked={states.indexOf(state.value) > -1} />
								<ListItemText primary={<span>{state.value}</span>} />
							</MenuItem>
						))}
					</Select>
				</FormControl>                 
			)}         
		</div>
	);
};

export default GeoRestrictions;