import React from 'react';
import styles from './Offerings.css.js';
import Box from '@material-ui/core/Box';

const CategoryTabPanel = ({ children, value, idx,...other }) => (
	<div
		role='tabpanel'
		hidden={value !== idx}
		id={`category-tabpanel-${idx}`}
		aria-labelledby={`simple-tab-${idx}`}
		style={{width: '100%'}}
		{...other}
	>
		{value === idx && (
			<Box p={3} style={styles.tabContent}>
				{children}
			</Box>
		)}
	</div>
);

export default CategoryTabPanel;