import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import styles from './Reports.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getReportUrl } from './actions';

const dayArray = [1,2,3,4,5,6,7,8,9,10,11,12,13,14];
const reportTypes = [
    {
        value: 1,
        type: 'Campaign Daily'
    },
    {
        value: 2,
        type: 'Partner Daily'
    },
    {
        value: 3,
        type: 'Campaign Hourly'
    },
];

const lengthOfNumber = (num) => {
    return num.toString().length;
};

const formatDateString = (dateObj) => {
    const year = dateObj.year();
    let month = dateObj.month() + 1;
    if(lengthOfNumber(month) === 1) { month = `0${month}`};
    let day = dateObj.date();
    if(lengthOfNumber(day) === 1) { day = `0${day}`};
    return `${year}-${month}-${day}`;
};

const DownloadReport = () => {
    const { appState } = useContext(AppContext);
    const [ date, setDate ] = useState(dayjs());
    const [ formattedDate, setFormattedDate ] = useState(formatDateString(date));
    const [ days, setDays ] = useState(1);
    const [ reportType, setReportType ] = useState(1);
    const [ isLoading, setLoading ] = useState(false);
    const [ isHovering, setHovering ] = useState(false);
    const [ unexpected, setUnexpected ] = useState(null);

    const handleDateChange = (newValue) => {
        setDate(newValue);
        const formattedDate = formatDateString(newValue);
        setFormattedDate(formattedDate);
    };
    const handleDayChange = (event) => {
        setDays(event.target.value);
    };
    const handleTypeChange = (event) => {
        setReportType(event.target.value);
    };
    const handleDownloadClick = async() => {
        setLoading(true);
        setUnexpected(null);
        const res = await getReportUrl(formattedDate, days, reportType, appState.uri);
        console.log('final res:', res);
        if(res.status === 'SUCCESS') {
            const reportUrl = `https://reports.system1.com${res.message}`
            window.open(reportUrl, '_blank', 'noreferrer');
            setLoading(false);
            return;
        };
        setUnexpected(res.message);
        setLoading(false);
    };

    const themedStyle = Object.assign({},
        styles.submit,
        isHovering && styles.hover,
        isLoading && styles.progress
    );

    return(
        <div style={styles.pageContainer}>
            <div style={styles.datePickerContainer}>
                <div style={styles.inputGroup}>
                    <div style={styles.datePicker}>
                        <DatePicker
                            label="Report Date"
                            value={date}
                            onChange={handleDateChange}
                        />  
                    </div>
                    <div style={styles.dayPicker}>
                        <FormControl fullWidth>
                            <InputLabel id='days-select-label'># of Days</InputLabel>
                            <Select
                                labelId='days-select-label'
                                id='days-select'
                                value={days}
                                label='# of Days'
                                onChange={handleDayChange}
                                fullWidth
                            >
                                {dayArray.map((val) => (
                                    <MenuItem key={`days-${val}`} value={val}>{val}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div style={styles.typePicker}>
                        <FormControl fullWidth>
                            <InputLabel id='type-select-label'>Report Type</InputLabel>
                            <Select
                                labelId='type-select-label'
                                id='type-select'
                                value={reportType}
                                label='Report Type'
                                onChange={handleTypeChange}
                                fullWidth
                            >
                                {reportTypes.map((valObj) => (
                                    <MenuItem key={`type-${valObj.value}`} value={valObj.value}>{valObj.type}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <Button 
                    variant='contained'
                    onClick={handleDownloadClick}
                    style={themedStyle}
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                >
                    {isLoading ? <CircularProgress size={24} style={styles.buttonProgress} /> : 'Download'}
                </Button>
            </div>
            {isLoading && <h3>This may take some time. Please be patient...</h3>}
            {!!unexpected && <span style={styles.unexpected}>{unexpected}</span>}
        </div>
    )
};

export default DownloadReport;