import React from 'react';
import styles from './CustomColumns.css.js';
import ButtonText from '../CustomCells/ButtonText';
import LinkCell from '../CustomCells/LinkCell';

const TreeTableList = () => ([
	{ 
		title: 'Link Name', 
		field: 'name',
		grouping: false,
        cellStyle: {...styles.titleCell, width: '25%'},
	},
	{ 
		title: 'Button Text', 
		field: 'text',
        cellStyle: styles.tableCell,
        render: rowData => !!rowData.text && <ButtonText text={rowData.text}/>
	},
	{ 
		title: 'Offer URL', 
		field: 'url',
		align: 'center',
		cellStyle: styles.tableCell,
		render: rowData => ( !!rowData.url &&
			<div style={styles.inline}>
				<span style={styles.prefix}>Offer Link: </span>
				<LinkCell link={rowData.url}/>
			</div>
		) 
	},
	{ 
		title: 'Jump Beind', 
		field: 'jump',
		align: 'center',
		cellStyle: styles.tableCell,
		render: rowData => ( !!rowData.jump &&
			<div style={styles.inline}>
				<span style={styles.prefix}>Jump Behind: </span>
				<LinkCell link={rowData.jump}/>
			</div>
		)
	},
]);

export default TreeTableList;