const styles = {
    formContainer: {
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    paperContainer: {
        padding: '20px 100px',
        width: '100%',
        maxWidth: '1500px',
        margin: '0',
        borderRadius: '5px'
    },
    white: { backgroundColor: '#FFF' },
    ice: { backgroundColor: 'aliceblue' },
    headerContainer: {
        fontSize: '2rem',
        fontWeight: 'bold',
        marginTop: '20px',
        borderBottom: '1px solid rgba(15,64,107,0.5)',
        color: 'rgba(15,64,107,0.6)',
        textAlign: 'left'
    },
    modalForm: {
        maxWidth: '1400px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '20px 50px'
    },
    formButtonRow: {
        width: '100%',
        padding: '100px 100px 20px 100px',
        display: 'inline-flex',
        justifyContent: 'space-between',
    },
    submitButton: {
        width: '100px',
        height: '40px',
    },
    submitColor: {
        backgroundColor: '#10406B',
        color: 'white',
    },
    hover: {
        cursor: 'pointer',
        backgroundColor: 'rgb(66,150,180)',
        opacity: '0.75',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
    },
    formButtons: {
        width: '100%',
        padding: '20px 100px',
        display: 'inline-flex',
        justifyContent: 'flex-end',
    },
    buttonProgress: {
        color: 'rgb(162, 201, 252) !important',
        margin: '0 10px',
    },
    deleteButton: {
        color: 'red',
        opacity: '0.6',
        position: 'relative',
        left: '8%',
        bottom: '8%',
        height: '48px',
        borderRadius: '50%',
    },
    deleteIcon: {
        fontSize: '1.5rem'
    },
    deleteHover: {
        backgroundColor: 'red',
        cursor: 'pointer',
        color: '#FFF',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
    },
    progress: {
        backgroundColor: 'aliceblue'
    }
};

export default styles;