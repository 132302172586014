import React from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './RangeSelector.css.js';

const RangeSelector = ({ range, setRange }) => {

    const setNewRange = (e, newValue) => {
        e.preventDefault();
        setRange(newValue);
    };

    return (
        <div style={styles.main}>
            <BottomNavigation
                value={range}
                onChange={setNewRange}
                aria-label='time frame'
                showLabels
            >
                <BottomNavigationAction label='1 hr' value='1hr' icon={<FontAwesomeIcon icon={['fal', 'hourglass']} style={styles.icon}/>} />
                <BottomNavigationAction label='24 hr' value='24hr' icon={<FontAwesomeIcon icon={['fal', 'calendar-day']} style={styles.icon}/>} />
                <BottomNavigationAction label='48 hr' value='48hr' icon={<FontAwesomeIcon icon={['fal', 'calendar-day']} style={styles.icon}/>} />
                <BottomNavigationAction label='72 hr' value='72hr' icon={<FontAwesomeIcon icon={['fal', 'calendar-day']} style={styles.icon}/>} />
                <BottomNavigationAction label='1 wk' value='1wk' icon={<FontAwesomeIcon icon={['fal', 'calendar-week']} style={styles.icon}/>} />
                <BottomNavigationAction label='1 mo' value='1mo' icon={<FontAwesomeIcon icon={['fal', 'calendar-alt']} style={styles.icon}/>} />
                <BottomNavigationAction label='All' value='all' icon={<FontAwesomeIcon icon={['fal', 'infinity']} style={styles.icon}/>} />
            </BottomNavigation>
        </div>
    );
}


export default RangeSelector;