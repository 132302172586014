import React, { useEffect, useState, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { ALL_QUICK_LINKS } from '../../../../graphql/queries';
import Paper from '@material-ui/core/Paper';
import EmptyList from '../../../EmptyList';
import Loading from '../../../Loading';
import styles from './QuickLinks.css.js';
import TreeTableList from '../../../Tables/TableColumns/TreeTableList';
import TableIcons from '../../../Tables/TableIcons';
import MaterialTable from 'material-table';
import { groupBy, titleCase } from '../../../../utils/helpers';
import ActionButton from '../../../ActionButton';

const TreeTable = ({ handleEdit }) => {
    const myTableRef = useRef(null);
    const [ activeTrees, setActive ] = useState([]);
    const [ listLinks, setListLinks ] = useState([]);
    const [ linkData, setData ] = useState([]);
    const { loading, error, data } = useQuery(ALL_QUICK_LINKS);

    useEffect(() => {
        if (data) {
            const quick_links = data.fetchAllQuickLinks.body;
            setListLinks(quick_links);
        };
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (listLinks.length > 0) {
            // Save cluster keys...
            let newClusterKeys = [];
            let clusters = groupBy(listLinks, link => link.cluster);
            for (let key of clusters.keys()) { newClusterKeys.push(titleCase(key)); };
            const clusterArray = newClusterKeys.map(cluster => ({ name: cluster, cluster_name: cluster }));
            const linkArray = listLinks.map(offer_link => ({
                id: offer_link.id,
                cluster: titleCase(offer_link.cluster),
                group: titleCase(offer_link.group),
                name: offer_link.name,
                text: offer_link.text,
                offer_group: offer_link.offer_group,
                url: offer_link.url,
                jump: offer_link.jump
            }));
            const fullData = [...clusterArray, ...linkArray];
            setData(fullData);
        };
        // eslint-disable-next-line
    }, [listLinks]);

    if (loading) return <div style={styles.loadingTable}><Loading /></div>;

    if (error) return <div>{`Error occured fetching quick link offers: ${error.message}`}</div>;

    const constPathColors = {
        1: 'rgba(16,64,107,0.25)',
        2: '#FFF',
    };

    const tableStyle = Object.assign({},
        styles.quicklinkTable,
        activeTrees.length > 0 && styles.quicklinkTableActive
    );
    
    const handleRowClick = (_, rowData) => {
        if(!rowData.tableData.isTreeExpanded) {
            setActive([...activeTrees, rowData.tableData.path[0]]);
        } else {
            const newList = activeTrees.filter(path => path !== rowData.tableData.path[0]);
            setActive(newList);
        }
        myTableRef.current.onTreeExpandChanged(rowData.tableData.path, rowData)
    };

    return ( 
        <Paper style={styles.tablePaper} elevation={10}>
            {listLinks?.length > 0 ? (
                <MaterialTable
                    icons={TableIcons}
                    columns={TreeTableList()}
                    data={linkData}
                    options={{
                        selection: false,
                        search: true,
                        showSelectAllCheckbox: false,
                        showTitle: false,
                        paging: false,
                        header: false,
                        rowStyle: rowData => {
                            if(rowData.tableData.isTreeExpanded === false && rowData.tableData.path.length === 1) {
                                return {};
                            }
                            const rowBackgroundColor = constPathColors[rowData.tableData.path.length];
                            return {backgroundColor: rowBackgroundColor, width: '100%', padding: '0'};
                        }
                    }}
                    tableRef={myTableRef}
                    onRowClick={handleRowClick}
                    parentChildData={(row, rows) => rows.find(a => a.name === row.cluster)}
                    style={tableStyle}
                    actions={[{
                        icon: 'edit',
                        tooltip: 'Edit QuickLink',
                        onClick: handleEdit
                    }]}
                    components={{
                        Action: props => {
                            if(!!props.data.cluster_name) return null;

                            if(props.action.icon === 'edit'){
                                return (
                                    <ActionButton 
                                        icon='pen'
                                        bg='white'
                                        bgHover='ice'
                                        handleClick={(e) => props.action.onClick(e, props.data)}
                                    />
                                )
                            }
                        }
                
                    }}
                /> 
            ) : (<EmptyList />)}
        </Paper>
    )
};

export default TreeTable;