import React, { useContext, useRef, useEffect } from 'react';
import { AppContext } from '../../../context';
import Tabs from '@material-ui/core/Tabs';
import Grow from '@material-ui/core/Grow';
import Tab from '@material-ui/core/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './TabGroups.css.js';
// import { ReactComponent as BestLogo } from '../../../assets/best.svg';

const OfferingsTabs = () => {
    const { dispatchApp, appState } = useContext(AppContext);

    const tabsActions = useRef();

    useEffect(() => {
        tabsActions.current.updateIndicator()
        // setTimeout(() => tabsActions.current.updateIndicator(), 200);
    }, []);

    const handleChange = (e, newValue) => {
        e.preventDefault();
        dispatchApp({ type: 'OFFERINGS_TAB_SELECT', payload: newValue});
    };

    const a11yProps = (idx) => {
        return {
            id: `endpoint-tab-${idx}`,
            'aria-controls': `simple-tabpanel-${idx}`,
        };
    };

    const OfferingsTab = (props) => (
        <Tab 
            icon={<FontAwesomeIcon icon={['fal', props.iconname]} style={styles.tabIcon} /> } 
            label={<div style={styles.tabTitle}>{props.labeltext}</div>}
            {...props} 
        />
    );
    // const OfferingsTab = (props) => (
    //     <Tab 
    //         icon={props.iconname === '10Best' ? 
    //             <BestLogo style={styles.bestLogo}/> :
    //             <FontAwesomeIcon icon={['fal', props.iconname]} style={styles.tabIcon} /> 
    //         } 
    //         label={<div style={styles.tabTitle}>{props.labeltext}</div>}
    //         {...props} 
    //     />
    // );

    return (
        <Grow in={true}>
            <Tabs 
                value={appState.offeringsTabValue} 
                onChange={handleChange} 
                aria-label='endpoint tabs' 
                centered={true}
                action={tabsActions}
            >
                <OfferingsTab labeltext='Offers' iconname='bullseye-pointer' {...a11yProps(0)}/>
                <OfferingsTab labeltext='Link Buttons' iconname='list' {...a11yProps(1)}/>
                {appState.authGroup === 'bk' && <OfferingsTab labeltext='Opt-Ins' iconname='check-square' {...a11yProps(2)}/>}
                {/* {appState.authGroup === 'bk' && <OfferingsTab labeltext='Best' iconname='10Best' {...a11yProps(3)}/>} */}
            </Tabs>
        </Grow>
    )
};

export default OfferingsTabs;