const styles = {
    accordionItem: {
        width: '100%',
        maxWidth: '1500px',
        overflowY: 'hidden'
    },
    accordion: {
        maxHeight: '50px'
    },
    expanded: {
        maxHeight: '48px',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    expandedVertical: {
        maxHeight: '48px',
        backgroundColor: 'rgba(16,64,107,0.2)',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
    panelContent: {
        padding: '0',
        minWidth: '75%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflowX: 'hidden',
        overflowY: 'hidden',
    },
    hover: {
        backgroundColor: 'rgba(66,150,180,0.5)',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
    },
    iconHover: {
        cursor: 'pointer',
    },
    toggleIcon: {
        fontSize: '1.4rem',
        color: 'rgba(16,64,107,0.7)',
    },
};

export default styles;