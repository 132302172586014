import './fonts.css';
import './overrides.css';

const styles = {
    app: {
        fontFamily: 'Verlag A, Verlag B, aktiv-grotesk, sans-serif',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100vw',
        minHeight: '100vh',
        backgroundColor: 'rgba(240, 240, 240, 1)',
    },
    initLoading: {
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
};

export default styles;