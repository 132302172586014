const styles = {
    fillPage: {
        minHeight: '55vh',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    pageContainer: {
        minWidth: '90vw',
        maxWidth: '1500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    content: {
        padding: '20px 0 0 10vw ',
        width: '100%',
        maxWidth: '1500px',
        display:'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    pushItem: {
        display: 'inline-flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '20px'
    },
    imagePaper: {
        width: '200px',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageBgPushPros: {
        backgroundColor: 'rgb(0,130,206)'
    },
    imageBgPushnami: {
        backgroundColor: 'rgb(47,37,116)'
    },
    providerImage: {
        width: '100%',
        height: 'auto',
        padding: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    pushItemValue: {
        fontSize: '2rem',
        // fontWeight: 'bold',
        marginLeft: '20px'
    }
};

export default styles;