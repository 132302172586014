import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loading from '../../Loading';
import { useQuery } from '@apollo/client';
import { OFFER_BLOCKS } from '../../../graphql/queries';
import styles from './ArticleDialog.css';

const BlockSelector = ({ value, setValue }) => {
    const { loading, error, data } = useQuery(OFFER_BLOCKS);

    if (error) {
        return <div>{`Error fetching Offer Block List: ${error}`}</div>;
    };

    if (loading) {
        return <div style={styles.loading}><Loading /></div>
    };

    const blocks = data.fetchOfferBlocks.body;

    return(
        <div style={styles.selectorRow}>
            <div style={styles.selectorContainer}>
                <FormControl fullWidth>
                    <InputLabel id='select-label'>Offer Block</InputLabel>
                    <Select
                        labelId='select-label'
                        id='offer-block-selector'
                        value={value}
                        label='Offer Block'
                        onChange={setValue}
                    >
                        {blocks.map((block, idx) => (
                            <MenuItem key={`block-option-${idx}`} value={block.id}>{block.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};

export default BlockSelector;