import React from 'react';
import styles from './QuickLinkDialog.css.js';
import EndpointGroupList from '../../EndpointGroupList';
import Paper from '@material-ui/core/Paper';

const OfferGroupSelect = ({ activeEndpointGroup, setActiveGroup, setGroupName }) => (
    <div style={styles.groupListContainer}>        
        <Paper style={styles.selections} elevation={10}>
            <EndpointGroupList
                isSelection={true}
                activeGroup={activeEndpointGroup} 
                setGroup={setActiveGroup}
                setName={setGroupName}
            />
        </Paper>
    </div>
);

export default OfferGroupSelect;