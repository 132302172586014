const styles = {
    actionBarLg: {
        width: '100%',
        maxWidth: '1500px',
        display: 'inline-flex',
        justifyContent: 'space-between',
    },
    actionBarMd: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        padding: '30px 50px 20px 50px'
    },
    actionBarContentLg: {
        borderBottom: 'solid 1px #10406B',
        width: '100%',
        display: 'inline-flex',
        alignItems: 'flex-end',
        fontSize: '2.3rem',
        padding: '10px 20px',
        color: '#10406B',
        fontWeight: '200'
    },
    actionBarContentMd: {
        borderBottom: 'solid 1px #10406B',
        width: '100%',
        display: 'inline-flex',
        alignItems: 'flex-end',
        fontSize: '1.4rem',
        padding: '0 10px',
        color: '#10406B',
        fontWeight: '300',
    },
    withButton: {
        marginRight: '20px'
    },
    addButton: {
        backgroundColor: 'rgb(225,233,237)',
        borderRadius: '50%',
        color: 'white',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '40px',
        maxHeight: '40px',
        width: '40px',
        minWidth: 'unset',
        margin: '0 0 0 10px',
        padding: '0',
    },
    addIcon: {
        fontSize: '1.6rem',
        color: 'rgba(16,64,107,1)',
    },
    leftTitle: {
        justifyContent: 'flex-start',
    },
    rightTitle: {
        justifyContent: 'flex-end',
    },
    titleText: {
        display: 'inline-flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    boldText: {
        fontWeight: 'bold',
        marginLeft: '10px',
        color: 'rgba(16,64,107,0.92)'
    }
};

export default styles;