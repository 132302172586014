import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../../../../context';
import { debtTypeOptions } from './options';
import SelectionList from './SelectionList';
import styles from './Steps.css.js';

const SelectDebtType = ({ close, moveBack, moveToNext }) => {
	const { dispatchSelection, selectionState } = useContext(AppContext);

	useEffect(() => {
		if(selectionState.vars.vertical !== 'personal_loans') {
			dispatchSelection({ type: 'SELECT_DEBT_TYPE', payload: 'N/A' });
			moveToNext();
		};
// eslint-disable-next-line
	},[selectionState.vars.vertical]);

	const handleSelection = (value) => {
		dispatchSelection({ type: 'SELECT_DEBT_TYPE', payload: value });
		moveToNext();
	};

	return (
		<div style={styles.stepContentContainer}>
			<div style={styles.stepContentQuestion}>
				<div style={styles.stepContentTitle}>
					Select a <span style={styles.stepContentTableValue}>Debt Type:</span>:
				</div>
                <SelectionList
                    close={close} 
                    moveBack={moveBack}
                    list={debtTypeOptions} 
                    onSelectClick={handleSelection}
                />
			</div>
		</div>
	);
}

export default SelectDebtType;