const styles = {
    offeringsContainer: {},
    pageContainer: {},
    content: {
        padding: '20px 50px 0 50px',
        width: '100%',
        maxWidth: '1500px',
    },
    mainPaper: {
        width: '100%',
        margin: '25px 0 0 0',
        backgroundColor: 'rgba(189,189,189,0.8)',
        borderRadius: '10px',
        maxWidth: '1500px',
    }
};

export default styles;