const styles = {
    welcomePageStyle: {
        fontSize: '2.5rem', 
        fontWeight: 200,
        minHeight: 'calc(100vh - 200px)',
    },
    bkLetters: {
        width: '350px',
        margin: '20px 0'
    },
    text: {
        fontWeight: '300',
        fontSize: '2.75rem',
        opacity: '0.5',
    }
};

export default styles;