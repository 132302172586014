import React from 'react';
import styles from './Home.css.js';
import BK_logo from '../../../assets/bk_letters.png';

const Home = () => (
    <div style={styles.welcomePageStyle}>
        <div style={styles.text}>Welcome to the</div>
        <img alt='bk-letters' src={BK_logo} style={styles.bkLetters} />
        <div style={styles.text}>Services Portal</div>
    </div>
);

export default Home;