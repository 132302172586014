const styles = {
    fullListItem: {
        display: 'inline-flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '3px',
        width: '80vw',
        maxWidth: '1290px'
    },
    summaryItem: {
        width: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 5px 0 15px',
    },
    tooltip: {
        fontSize: '1.4rem',
        padding: '5px',
        lineHeight: '1.1',
    },
    tooltipText: {
        borderRight: 'solid 1px #10406B',
        padding: '0 10px',
        marginRight: '10px',
        color: 'rgba(1, 24, 66, 0.4)',
        fontSize: '1.2rem',
        display: 'flex',
        alignItems: 'center',
        minWidth: '65px',
    },
    titleContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    titlePrime: {
        fontWeight: 'bold',
        color: '#10406B',
        fontSize: '1.4rem',
    },
    titleSecond: {
        fontSize: '1rem',
        color: 'rgba(0,0,0,0.5)',
    },
    programContent: {
        width: '100%',
        overflowX: 'hidden',
        backgroundColor: 'aliceblue'
    },
    verticalContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0'
    },
    editButton: {
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    hoverRow: {
        cursor: 'pointer',
        backgroundColor: '#FFF',
        borderRadius: '50%',
        color: 'rgb(66,150,180)',
        opacity: '0.75',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
    },
    editIcon: {
        fontSize: '1.4rem',
        color: 'rgba(16,64,107,0.7)',
    }
};

export default styles;