import React from 'react';
import styles from './CustomColumns.css.js';
import ButtonText from '../CustomCells/ButtonText';

const SelectionLinkListColumns = () => ([
	{ 
		title: 'Link Name', 
		field: 'name',
		grouping: false,
        cellStyle: {...styles.titleCell, width: '50%'},
	},
	{ 
		title: 'Button Text', 
		field: 'text',
        cellStyle: styles.tableCell,
        render: rowData => !!rowData.text && <ButtonText text={rowData.text}/>
	},
]);

export default SelectionLinkListColumns;