const styles = {
    main: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: '10px',
        backgroundColor: 'aliceblue',
        width: '100%',
        minHeight: '30vh',
        borderRadius: '5px'
    },
    contentText: {
        padding: '5px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: 'rgba(16,64,107,0.5)',
        fontSize: '1.75rem',
    },
    nullIcon: {
        color: 'rgba(16,64,107,0.5)',
        fontSize: '2rem',
    }
};

export default styles;