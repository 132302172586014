import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../context';
import styles from './QuickLinks.css.js';
import ActionBar from '../../../ActionBar';
import CategoryTabPanel from '../CategoryTabPanel';
import TreeTable from './TreeTable';
import QuickLinkDialog from '../../../Forms/QuickLinkDialog';

const QuickLinks = () => {
    const { dispatchSelection } = useContext(AppContext);
    const [ showDialog, setShowDialog ] = useState(false);
    
    const handleClose = () => {
        setShowDialog(false);
    };

    const handleAddClick = () => {
        dispatchSelection({ type: 'CLEAR_SELECTION' });
        setShowDialog(true);
    };

    const handleEditClick = (event, rowData) => {
        event.preventDefault();
        console.log('rowData:', rowData);
        dispatchSelection({ type: 'EDIT_QUICK_LINK', payload: rowData });
        setShowDialog(true);
    };

    return (
        <div style={styles.pageContainer}>
			<CategoryTabPanel value={0} idx={0}>
                <ActionBar text='Quick' title='LINKS' handleClick={handleAddClick} size='Lg'/>
                <div style={styles.content}>
                    {!showDialog ? 
                        <TreeTable handleEdit={handleEditClick}/> : 
                        <QuickLinkDialog open={showDialog} close={handleClose} />
                    }
                </div>
			</CategoryTabPanel>
		</div>
    )
};

export default QuickLinks;