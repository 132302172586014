import React from 'react';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import styles from './DirectDomainDialog.css.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MediaSwitches = ({ mediaState, setMediaState }) => {

    const handleChange = (event) => {
        setMediaState({ ...mediaState, [event.target.name]: event.target.checked });
    };

    return (
        <div style={styles.acceptedContainer}>
            <FormControl component='fieldset'>
                <FormGroup row style={styles.acceptedRow}>
                    {MediaSwitchNames.map((name, idx) => (
						<FormGroup key={`media-switch-${idx}`} style={styles.switchContainer}>
							<FormControlLabel
								key={`media-switch__${idx}`}
								control={
									<Switch
										size='small' 
										checked={!!mediaState[name.value]} 
										onChange={handleChange} 
										name={name.value} 
									/>
								}
								label={<FontAwesomeIcon style={styles.switchIcon} icon={name.icon} size='1x' />}
								labelPlacement='top'
							/>
							<FormHelperText style={styles.switchHelper}>{name.label}</FormHelperText>
						</FormGroup>
                    ))}
                    
                </FormGroup>
            </FormControl>
        </div>
    );
};

const MediaSwitchNames = [
	{
		value: 'bing',
		label: 'Bing',
		icon: ['fal', 'search-dollar']
	},
	{
		value: 'google',
		label: 'Google',
		icon: ['fab', 'google']
	},
	{
		value: 'social',
		label: 'Social',
		icon: ['fal', 'users']
	},
	{
		value: 'native',
		label: 'Native',
		icon: ['fal', 'mobile']
	},
	{
		value: 'email',
		label: 'Email',
		icon: ['fal', 'envelope']
	},
	{
		value: 'sms',
		label: 'SMS',
		icon: ['fal', 'sms']
	},
	{
		value: 'push',
		label: 'Push',
		icon: ['fal', 'bells']
	},
	{
		value: 'known',
		label: 'Known',
		icon: ['fal', 'ad']
	},
	{
		value: 'unknown',
		label: 'Unknown',
		icon: ['fal', 'question']
	}
];

export default MediaSwitches;