import React, { useRef, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Grow from '@material-ui/core/Grow';
import Tab from '@material-ui/core/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ArticleDialog.css.js';

const PageSizeTabs = ({ pageSizeTabValue, setPageSizeTabValue }) => {
    // const { appState, dispatchApp } = useContext(AppContext);
    // const { pageSizeTabValue } = appState;
    const tabsActions = useRef();

    useEffect(() => {
        tabsActions.current.updateIndicator()
        // setTimeout(() => tabsActions.current.updateIndicator(), 200);
    }, []);
    
    const handleTabChange = (e, newValue) => {
        e.preventDefault();
        setPageSizeTabValue(newValue);
        // dispatchApp({ type: 'ARTICLE_SIZE_TAB_SELECT', payload: newValue});
    };

    const a11yProps = (idx) => {
		return {
			id: `article-size-${idx}`,
			'aria-controls': `tabpanel-${idx}`,
		};
	};
    const PageTab = (props) => (
        <Tab 
            textColor='inherit'
            label={<div style={styles.subTabTitle}>{props.labeltext}</div>}
            icon={<FontAwesomeIcon icon={['fal', props.iconname]} style={styles.subTabIcon} />}
            {...props}
        />
    );

    return (
        // className important for app bar style override
        <Grow in={true}>
            <Tabs 
                value={pageSizeTabValue} 
                onChange={handleTabChange} 
                aria-label='subtabpanel' 
                centered={true}
                style={styles.subTabsEl}
                action={tabsActions}
            >
                <PageTab 
                    labeltext='Mobile'
                    iconname='newspaper'
                    style={styles.tabIcon}
                    {...a11yProps(0)}
                />
                <PageTab 
                    labeltext='Desktop'
                    iconname='inbox-out'
                    style={styles.tabIcon}
                    {...a11yProps(1)}
                /> 
            </Tabs>
        </Grow>
    )
};

export default PageSizeTabs;