import React from 'react';
import styles from './CustomColumns.css.js';
// import { makePagePretty, titleCase } from '../../../utils/helpers';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import TooltipCell from '../CustomCells/TooltipCell';
import EndpointName from '../../EndpointName';
// import LinkCell from '../CustomCells/LinkCell';

const ProgramRecordColumns = () => ([
	{ 
		title: 'Name', 
        field: 'record_name',
        cellStyle: {...styles.titleCell},
        align: 'left',
        defaultSort: 'asc',
		render: rowData => (rowData.record_name) 
	},
	{ 
		title: 'Endpoint Name', 
        field: 'endpoint_id',
        align: 'right',
        cellStyle: styles.tableCell,
		render: rowData => (<EndpointName id={rowData.endpoint_id}/>)
	}
]);

export default ProgramRecordColumns;