import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../context';
import { useToasts } from 'react-toast-notifications';
import styles from './ArticleControl.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import ActionButton from '../../ActionButton';
import Button from '@material-ui/core/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation, useQuery } from '@apollo/client';
import { SAVE_ARTICLE } from '../../../graphql/mutations';
import { ARTICLE_OFFER_BY_ID, ARTICLES, OFFER_BLOCK_BY_ID } from '../../../graphql/queries';
import Loading from '../../Loading';
import RecBox from './RecBox';
import BlockExample from './BlockExample';

const ArticleOffer = ({id, title}) => {
    const { loading, error, data } = useQuery(ARTICLE_OFFER_BY_ID, { variables: { id } });

    if (error) {
        return <div>{`Error fetching Article Offer by ID: ${error}`}</div>;
    };

    if (loading) {
        return <div style={styles.loading}><Loading /></div>
    };

    const offer = data.fetchArticleOfferById.body;
    return (
        <div style={styles.recContainer}>
            <div style={styles.recTitle}>{title}</div>
            <RecBox item={offer} />
        </div>
    );
};

const OfferBlockDisplay = ({ block_id }) => {
    const { loading, error, data } = useQuery(OFFER_BLOCK_BY_ID, { variables: { id: block_id } });

    if (error) {
        return <div>{`Error fetching Offer Block by ID: ${error}`}</div>;
    };

    if (loading) {
        return <div style={styles.loading}><Loading /></div>
    };

    const offerBlock = data.fetchOfferBlockById.body;
    return (
        <div style={styles.recContainer}>
            <div style={styles.recTitle}>{offerBlock.name}</div>
            <BlockExample item={offerBlock}/>
        </div>
    );
};

const ButtonGroup = ({ groupObj, activeGroupId, article }) => {
    const { addToast } = useToasts();
    const { dispatchSelection } = useContext(AppContext);
    const [ saveNewActive, setSaveNewActive ] = useState(false);
    const [ groupActive, setGroupActive ] = useState(activeGroupId === groupObj.group_key);
    const [ isHovering, setHovering ] = useState(false);
    const [ isSaving, setSaving ] = useState(false);

    const handleRedirect = (data) => {
        if (data.saveArticle.success) {
            addToast('Successfully SAVED Article!', { appearance: 'success', autoDismiss: true });
            setSaving(false);
        } else {
            console.error(data.message);
            addToast('Failed!', { appearance: 'error', autoDismiss: true });
            setSaving(false);
        };
    };

    const [saveArticle] = useMutation(
        SAVE_ARTICLE, {
        refetchQueries: [{ query: ARTICLES }],
        onCompleted: handleRedirect
    });

    const editButtonGroup = (e) => {
        e.stopPropagation();
        dispatchSelection({ 
            type: 'SET_ACTIVE_DATA', 
            payload: {
                group: groupObj,
                article: article
            } 
        });
        dispatchSelection({ type: 'OPEN_BUTTON_GROUP_DIALOG' });
    };

    useEffect(() => {
        if(activeGroupId === groupObj.group_key) {
            setGroupActive(true);
            return;
        };
        setGroupActive(false);

    },[groupObj, activeGroupId]);

    useEffect(() => {
        if(activeGroupId !== article.active_button_group_key && groupActive) {
            setSaveNewActive(true);
        } else {
            setSaveNewActive(false);
        };
// eslint-disable-next-line 
    },[activeGroupId, groupActive]);

    const ButtonEl = ({ text }) => (
        <div style={styles.buttonGroupButton}>
            <FontAwesomeIcon icon={['fal', 'chevron-right']} style={styles.buttonChevron} />
            <div style={styles.buttonText}>{text}</div>
            <FontAwesomeIcon icon={['fal', 'chevron-right']} style={styles.buttonChevron} />
        </div>
    );

    const handleUpdate = () => {
        setSaving(true);
        const { key, title, header, sub_text, button_title } = article;
        const sendData = {
            key,
            title,
            header,
            sub_text,
            button_title,
            active_button_group_key: activeGroupId,
	        button_groups: article.button_groups.map((groupObj) => ({
                group_key: groupObj.group_key,
                buttons: groupObj.buttons
            }))
        };
        saveArticle({ variables: { article: sendData } });
    };

    const activeStyle = Object.assign({},
        styles['buttonGroupContainer'],
        groupActive && styles['activeContainer']
    );

    const themedStyle = Object.assign({},
        styles.submitButton,
        styles.submitColor,
        isHovering && styles.hover,
        isSaving && styles.progress
    );

    return (
        <div style={activeStyle}>
            <div style={styles.buttonGroupTitleRow}>
                <FormControlLabel 
                    value={groupObj.group_key} 
                    control={<Radio/>} 
                    label={activeGroupId === groupObj.group_key ? 'Active Group' : 'Set Active'} 
                />
                {saveNewActive ? (
                    <Button 
                        type='submit'
                        variant="outlined"
                        onClick={handleUpdate} 
                        onFocus={(event) => event.stopPropagation()}
                        onMouseEnter={() => setHovering(true)}
                        onMouseLeave={() => setHovering(false)}
                        style={themedStyle}
                    >
                        {isSaving ? <CircularProgress size={24} style={styles.buttonProgress} /> : 'Save'}
                    </Button>
                ) : (
                    <ActionButton 
                        icon='pen'
                        bg={activeGroupId === groupObj.group_key ? 'rgb(228,233,237)' : 'white'}
                        bgHover='green'
                        handleClick={editButtonGroup}
                    />
                )}
            </div>
            
                <div style={styles.buttonGroup}>
                    {groupObj.buttons.map((button, idx) => (
                        <ButtonEl key={`group_${groupObj.group_key}_button_${idx}`} text={button} />
                    ))}
                </div>
            <div style={styles.divider}></div>
        </div>
    )
};

const ArticleDetails = ({ item }) => {
    const { dispatchSelection } = useContext(AppContext);
    const [ activeButtonGroup, setActiveButtonGroup ] = useState(item.active_button_group_key);

    const handleButtonGroupChange = (e) => {
        setActiveButtonGroup(e.target.value);
    };

    const createButtonGroup = () => {
        dispatchSelection({ 
            type: 'SET_ACTIVE_DATA', 
            payload: {
                group: null,
                article: item
            } 
        });
        dispatchSelection({ type: 'OPEN_BUTTON_GROUP_DIALOG' });
    };

    return (  
        <div style={styles.detailContainer}>
            {!!item.cci_link && (
                <div style={styles.linkContainer}>
                    {/* <span style={styles.linkText}>{item.cci_link}</span> */}
                    <TextField
                        disabled
                        id="standard-disabled"
                        label="CCI Link"
                        defaultValue={item.cci_link}
                        variant="standard"
                    />
                    <Divider style={{marginBottom: '20px'}}/>
                </div>
            )}
            <div style={styles.detailContainer}>
                <div style={styles.articleTitle}>{item.header}</div>
                <div style={styles.articleText}>{item.sub_text}</div>
            </div>
            {!!item?.offer_block && (
                <OfferBlockDisplay block_id={item.offer_block} />
            )}
            {!!item?.offer1 && (
                <ArticleOffer id={item.offer1} title='Top Offer'/>
            )}
            {!!item?.offer2 && (
                <ArticleOffer id={item.offer2} title='Bottom Offer'/>
            )}
            <div style={styles.buttonHeader}>{item.button_title}</div>
            <div style={styles.addButtonGroupRow}>
                <div style={styles.addGroupText}>Create Group</div>
                <ActionButton 
                    icon='plus'
                    bg='lightGrey'
                    bgHover='green'
                    handleClick={createButtonGroup}
                />
            </div>
            <div style={styles.buttonsListContainer}>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="controlled-radio-buttons-group"
                        name="controlled-buttons-group"
                        defaultValue={item.active_button_group_key}
                        onChange={handleButtonGroupChange}
                    >
                        {/* Show all the button groups for the article */}
                        {item.button_groups.map((button_group, idx) => (
                            <ButtonGroup 
                                groupObj={button_group} 
                                key={`button_group-${item.key}-${idx}`}
                                activeGroupId={activeButtonGroup}
                                article={item}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </div>
        </div>
    )
};

export default ArticleDetails;