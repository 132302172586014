const initialSelectionState = {
    clusters: null,
    groups: null,
    delete_doc: null,
    show_delete: false,
    id: null,
    show_domain_dialog: false,
    program_domain: {
        id: null,
        title: '',
        pid: [],
        domain: '',
        domain_key: '',
        description: '',
        avatar: 'N/A',
        accepts: []
    },
    vars: {
        vertical: null,
        loan_type: null,
        debt_type: null,
        debt_amount: null
    },
    debt_group: 'N/A',
    debtOptinGroup: 'N/A',
    checking_group: 'N/A',
    checkingOptinGroup: 'N/A',
    endpoint_group: null,
    endpointGroupName: 'N/A',
    endpoint: {
        id: null,
        name: '',
        offer_page: 'direct_link',
        link: '',
        jump: '',
        four_button: [],
        offers: [],
        restricted: false,
        states: [],
        question_text: 'What are you interested in?',
        show_search: false,
        selection_links: [],
        quick_link_group: 'N/A',
        google: false
    },
    offer_group: {
        id: null,
        name: '',
        group_items: [],
        usage: []
    },
    quick_link: {
        id: null,
        qid: null,
        cluster: 'N/A',
        group: 'N/A',
        name: '',
        text: '',
        url: '',
        jump: '',
        google: false
    },
    push_providers: {
        push_pros: null,
        pushnami: null
    },
    program_record: {
        record_name: '',
        endpoint_id: ''
    },
    article: null,
    article_offer: null,
    showArticleOfferDialog: false,
    button_group: null,
    showButtonGroupDialog: false,
    showArticleDialog: false,
    offer_block: null,
    showOfferBlockDialog: false
};

const selectionStateReducer = (state, action) => {
    switch (action.type) {
        case 'CLEAR_SELECTION':
            return initialSelectionState;

        case 'SET_QUICK_LINK_OPTIONS':
            return {
                ...state,
                clusters: action.payload.clusters,
                groups: action.payload.groups
            };

        case 'CLOSE_DIRECT_DOMAIN_DIALOG':
            return {
                ...state,
                program_domain: initialSelectionState.program_domain,
                show_domain_dialog: false
            };

        case 'ADD_DIRECT_DOMAIN_DIALOG':
            return {
                ...state,
                program_domain: initialSelectionState.program_domain,
                show_domain_dialog: true
            };

        case 'EDIT_DIRECT_DOMAIN_DIALOG':
            return {
                ...state,
                program_domain: action.payload,
                show_domain_dialog: true
            };

        case 'ADD_PROGRAM_RECORD':
            return {
                ...state,
                program_domain: action.payload
            };

        case 'EDIT_PROGRAM_RECORD':
            return {
                ...state,
                program_record: action.payload.record,
                program_domain: action.payload.domain
            };

        case 'EDIT_DOMAIN':
            return {
                ...state,
                program_domain: action.payload
            };

        case 'ADD_PROGRAM':
            return {
                ...state,
                program_domain: action.payload.domain,
                vars: {
                    ...initialSelectionState.vars,
                    vertical: action.payload.vertical
                }
            };

        case 'EDIT_PROGRAM':
            return {
                ...state,
                program_domain: action.payload.domain,
                ...action.payload.program,
            };
        
        case 'SELECT_LOAN_TYPE':
            return {
                ...state,
                vars: {
                    ...state.vars,
                    loan_type: action.payload
                }
            };

        case 'SELECT_DEBT_TYPE':
            return {
                ...state,
                vars: {
                    ...state.vars,
                    debt_type: action.payload
                }
            };
            
        case 'SELECT_DEBT_AMOUNT':
            return {
                ...state,
                vars: {
                    ...state.vars,
                    debt_amount: action.payload
                }
            };

        case 'SELECT_OPTINS':
            return {
                ...state,
                debt_group: action.payload.debt,
                checking_group: action.payload.checking
            };

        case 'SELECT_ENDPOINT_GROUP':
            return {
                ...state,
                endpoint_group: action.payload.id,
                endpointGroupName: action.payload.name
            };

        case 'DELETE_SELECTION':
            return {
                ...state,
                delete_doc: action.payload,
                show_delete: true
            };

        case 'CLOSE_DELETE':
            return {
                ...state,
                delete_doc: null,
                show_delete: false
            };

        case 'EDIT_ENDPOINT':
            return {
                ...state,
                endpoint: action.payload
            };

        case 'EDIT_OFFER_GROUP':
            return {
                ...state,
                offer_group: action.payload
            };

        case 'EDIT_QUICK_LINK':
            return {
                ...state,
                quick_link: action.payload
            };

        case 'EDIT_PUSH_PROVIDERS':
            return {
                ...state,
                push_providers: action.payload
            };

        case 'CLEAR_DATA':
            return {
                ...state,
                button_group: null,
                article: null,
            };
        case 'SET_ACTIVE_DATA':
            return {
                ...state,
                button_group: action.payload.group,
                article: action.payload.article,
            };

        case 'TOGGLE_ARTICLE_DIALOG':
            return {
                ...state,
                showArticleDialog: !state.showArticleDialog
            };
        case 'TOGGLE_ARTICLE_OFFER_DIALOG':
            return {
                ...state,
                showArticleOfferDialog: !state.showArticleOfferDialog
            };
        case 'TOGGLE_OFFER_BLOCK_DIALOG':
            return {
                ...state,
                showOfferBlockDialog: !state.showOfferBlockDialog
            };
        case 'SET_ARTICLE_OFFER':
            return {
                ...state,
                article_offer: action.payload
            };
        case 'OPEN_BUTTON_GROUP_DIALOG':
            return {
                ...state,
                showButtonGroupDialog: true
            };
        case 'CLOSE_BUTTON_GROUP_DIALOG':
            return {
                ...state,
                showButtonGroupDialog: false
            };
        case 'SET_OFFER_BLOCK':
            return {
                ...state,
                offer_block: action.payload
            };

        default:
            throw new Error(`Not supported action ${action.type}`);
    }
};

export {
    initialSelectionState,
    selectionStateReducer
};