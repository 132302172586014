import React, { useContext, useRef, useEffect } from 'react';
import { AppContext } from '../../../context';
import Tabs from '@material-ui/core/Tabs';
import Grow from '@material-ui/core/Grow';
import Tab from '@material-ui/core/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './TabGroups.css.js';

const ArticlesTabs = () => {
    const { appState, dispatchApp } = useContext(AppContext);
    const { articlesTabValue } = appState;
    const tabsActions = useRef();

    useEffect(() => {
        tabsActions.current.updateIndicator()
        // setTimeout(() => tabsActions.current.updateIndicator(), 200);
    }, []);
    
    const handleTabChange = (e, newValue) => {
        e.preventDefault();
        dispatchApp({ type: 'ARTICLES_TAB_SELECT', payload: newValue});
    };

    const a11yProps = (idx) => {
		return {
			id: `report-${idx}`,
			'aria-controls': `tabpanel-${idx}`,
		};
	};
    const PageTab = (props) => (
        <Tab 
            textColor='inherit'
            label={<div style={styles.subTabTitle}>{props.labeltext}</div>}
            icon={<FontAwesomeIcon icon={['fal', props.iconname]} style={styles.subTabIcon} />}
            {...props}
        />
    );

    return (
        // className important for app bar style override
        <Grow in={true}>
            <Tabs 
                value={articlesTabValue} 
                onChange={handleTabChange} 
                aria-label='subtabpanel' 
                centered={true}
                style={styles.subTabsEl}
                action={tabsActions}
            >
                <PageTab 
                    labeltext='Articles'
                    iconname='newspaper'
                    style={styles.tabIcon}
                    {...a11yProps(0)}
                />
                <PageTab 
                    labeltext='Offers'
                    iconname='inbox-out'
                    style={styles.tabIcon}
                    {...a11yProps(1)}
                />
                <PageTab 
                    labeltext='Offer Blocks'
                    iconname='chart-network'
                    style={styles.tabIcon}
                    {...a11yProps(2)}
                />
                <PageTab 
                    labeltext='Pop-Up'
                    iconname='lightbulb'
                    style={styles.tabIcon}
                    {...a11yProps(3)}
                />
            </Tabs>
        </Grow>
    )
};

export default ArticlesTabs;