import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../../context';
import TextField from '@material-ui/core/TextField';
import * as buttonList from './options';
import { flattenString } from '../../../../../utils/helpers';
import ControlButtons from './ControlButtons';
import SelectionList from './SelectionList';
import styles from './Steps.css.js';

const SelectLoanType = ({ close, moveBack, moveToNext }) => {
	const { dispatchSelection, selectionState } = useContext(AppContext);
	const [ keyType, setKeyType ] = useState(selectionState?.vars?.loanType ?? '');
	const vertical = selectionState?.vars?.vertical ?? '';
	
	const handleSelection = (value) => {
		dispatchSelection({ type: 'SELECT_LOAN_TYPE', payload: value });
		moveToNext();
	};

	const handleInputChange = (e) => {
        e.preventDefault();
        setKeyType(e.target.value);
    };

	const pickButtonList = () => {
		switch (vertical) {
			case 'auto_loans':
				return buttonList.autoVariableList;
			case 'home_loans':
				return buttonList.homeVariableList;
			case 'credit_cards':
				return buttonList.ccVariableList;
			case 'personal_loans':
				return buttonList.personalVariableList;
			default:
				return 'Unknown step';
		}
	}

	const handleSubmitInput = () => {
		dispatchSelection({ type: 'SELECT_LOAN_TYPE', payload: flattenString(keyType) });
		moveToNext();
    };
	
	return (
		<div style={styles.stepContentContainer}>
			<div style={styles.stepContentQuestion}>
				<div style={styles.stepContentTitle}>
					Choose a 
                    <span style={styles.stepContentTableValue}>
                        {vertical === 'direct' ? ' URL Parameter Value' : ' Loan Type'}
                    </span>:
				</div>
				{vertical === 'direct' ?
					<div style={styles.typeInputContainer}>
						<TextField
							name='typeKey'
							style={styles.typeInput}
							label='Any Unique Identifier will suffice...'
							margin='normal'
							value={keyType}
							onChange={handleInputChange}
						/>
                        <ControlButtons 
                            close={close} 
                            handleNextClick={handleSubmitInput} 
                            handleBack={moveBack}
                        />
					</div> :
                    <SelectionList 
                        close={close} 
                        moveBack={moveBack} 
                        list={pickButtonList()} 
                        onSelectClick={handleSelection}
                    />
				}
			</div>
		</div>
	);
}

export default SelectLoanType;