import React, { useContext } from 'react';
import { AppContext } from '../../../../context';
import styles from '../Offerings.css.js';
import ActionBar from '../../../ActionBar';
import OptInsList from '../../../OptInsList';
import OptInGroups from './OptInGroups';
import CategoryTabPanel from '../CategoryTabPanel';

const OptIns = () => {
    const { appState } = useContext(AppContext);
	const tabValue = appState.subcategoryTabValue;
    
    const handleAddOptin = () => {
        console.log('clicked ADD!');
    };

    const handleEditOptin = () => {
        console.log('clicked Edit!');
    };

    return (
        <div style={styles.pageContainer}>
            <CategoryTabPanel value={tabValue} idx={0}>
                <ActionBar text='Opt-In' title='ENDPOINTS' handleClick={handleAddOptin} size='Lg'/>
                <div style={styles.content}>
                    <OptInsList handleEdit={handleEditOptin}/>
                </div>
            </CategoryTabPanel>
            <CategoryTabPanel value={tabValue} idx={1}>
                <OptInGroups />
            </CategoryTabPanel>
		</div>
    )
};

export default OptIns;