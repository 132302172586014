import React from 'react';
import styles from './CustomColumns.css.js';

const EndpointGroupColumns = () => ([
	{ 
		title: 'Group Name', 
		field: 'name',
		defaultSort: 'asc',
        cellStyle: {...styles.titleCell, width: '40%'}, 
	},
	{ 
		title: 'Group Offer Count', 
		field: 'group_items',
		align: 'center',
        cellStyle: {...styles.tableCell, width: '40%'}, 
		render: rowData => <span>{`Offer Count: (${rowData.group_items.length})`}</span>
	},
]);

export default EndpointGroupColumns;