import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import Endpoints from './Endpoints';
import OptIns from './OptIns';
import QuickLinks from './QuickLinks';
// import TenBest from './TenBest';
import styles from './Offerings.css.js';
import CategoryTabPanel from './CategoryTabPanel';

const Offerings = () => {
	const { appState } = useContext(AppContext);
	const value = appState.offeringsTabValue;

	return(
		<div style={styles.offeringsContainer}>
			<CategoryTabPanel value={value} idx={0}>
				<Endpoints />
			</CategoryTabPanel>
			<CategoryTabPanel value={value} idx={1}>
				<QuickLinks />
			</CategoryTabPanel>
			<CategoryTabPanel value={value} idx={2}>
				<OptIns />
			</CategoryTabPanel>
			{/* <CategoryTabPanel value={value} idx={3}>
				<TenBest />
			</CategoryTabPanel> */}
		</div>
	)
};

export default Offerings;