const styles = {
    fourButtonFields: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexFlow: 'row wrap',
        width: '100%'
    },
    buttonInputsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '20px'
    },
    textInput: {
        marginBottom: '20px'
    },
    buttonInput: {
        marginBottom: '20px',
        width: '90%'
    },
    mainForm: {
        width: '100%',
    },
    selectionRow: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    selectionPageRow: {
        width: '100%',
        display:'inline-flex',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    selection: {
        width: '25%',
        marginTop: '16px',
        marginBottom: '8px',
        textAlign: 'right',
    },
    inputLabel: {
        color: 'rgba(15,64,107,0.6)',
        fontSize: '1.2rem',
        fontWeight: 'bold'
    },
    geoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '0',
        textAlign: 'right',
        marginTop: '40px'
    },
    checkInput: {
        width: '30%',
        textAlign: 'right',
        display: 'inline-flex',
    },
    googleInput: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'flex-start',
        marginBottom: '20px'
    },
    statesContainer: {
        width: '70%',
    },
    geoChip: {
        margin: '0 5px',
    }
};

export default styles;