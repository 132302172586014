import React, { forwardRef } from 'react';
import styles from './OfferGroupDialog.css.js';
import TextField from '@material-ui/core/TextField';
import EndpointName from '../../EndpointName';

const GroupItemInput = forwardRef(({ item, ...props }, ref) => (
    <div style={styles.itemInputContainer}>
        <TextField
            label={<>% <EndpointName id={item.id}/></>}
            fullWidth
            defaultValue={item?.usage ?? 0}
            style={styles.textInput}
            inputRef={ref}
            {...props}
        />
    </div>
));

export default GroupItemInput;