import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import styles from './ArticleDialog.css';
import TextField from '@material-ui/core/TextField';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogBase from '../DialogBase';
import { useMutation } from '@apollo/client';
import { SAVE_ARTICLE } from '../../../graphql/mutations';
import { ARTICLES } from '../../../graphql/queries';
import InputLabel from '@material-ui/core/InputLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OfferSelector from './OfferSelector';
import BlockSelector from './BlockSelector';
import PageSizeTabs from './PageSizeTabs';
import Box from '@material-ui/core/Box';

const LittleIcon = ({icon, color, handleClick}) => {
    const [ isHovering, setHovering ] = useState(false);
    
    const iconStyle = Object.assign({},
        styles.litteIcon,
        {color: color},
        isHovering && styles.iconHover
    );

    return(
        <FontAwesomeIcon
            icon={['fal', icon]}
            size='1x'
            style={iconStyle}
            onClick={handleClick}
            onMouseEnter={() => setHovering(true)}
			onMouseLeave={() => setHovering(false)}
        />
    );
};

const TabContent = ({ children, value, idx,...other }) => (
	<div
		role='tabpanel'
		hidden={value !== idx}
		id={`category-tabpanel-${idx}`}
		aria-labelledby={`simple-tab-${idx}`}
		style={{width: '100%'}}
		{...other}
	>
		{value === idx && (
			<Box p={3} style={styles.tabContent}>
				{children}
			</Box>
		)}
	</div>
);

const ArticleDialog = () => {
    const { selectionState, dispatchSelection } = useContext(AppContext);
    const { article } = selectionState;

    const { addToast } = useToasts();
    const blankButton = { button: '' };
    const [ buttonState, setButtonState ] = useState([{button: ''}, {button: ''}, {button: ''}]);
    const [ mobileButtonState, setMobileButtonState ] = useState([{button: ''}, {button: ''}, {button: ''}]);

    const [ desktopSegment, setDesktopSegment ] = useState(article?.segment ?? '');
    const [ mobileSegment, setMobileSegment ] = useState(article?.mobile?.segment ?? '');

    const [ cciActive, setCciActive ] = useState(!!article?.cci_link);
    const [ mobileCciActive, setMobileCciActive ] = useState(!!article?.mobile?.cci_link);

    const [ rsocActive, setRsocActive ] = useState(!!article?.rsoc_active);
    const [ mobileRsocActive, setMobileRsocActive ] = useState(!!article?.mobile?.rsoc_active);

    const [ blockActive, setBlockActive ] = useState(!!article?.offer_block);
    const [ mobileBlockActive, setMobileBlockActive ] = useState(!!article?.mobile?.offer_block);

    const [ offerBlock, setOfferBlock ] = useState(article?.offer_block ?? '');
    const [ mobileOfferBlock, setMobileOfferBlock ] = useState(article?.mobile?.offer_block ?? '');

    const [ header, setHeader ] = useState(article?.header ?? '');
    const [ mobileHeader, setMobileHeader ] = useState(article?.mobile?.header ?? '');
    const [ subText, setSubText ] = useState(article?.sub_text ?? '');
    const [ mobileSubText, setMobileSubText ] = useState(article?.mobile?.sub_text ?? '');
    const [ buttonTitle, setButtonTitle ] = useState(article?.button_title ?? '');
    const [ mobileButtonTitle, setMobileButtonTitle ] = useState(article?.mobile?.button_title ?? '');

    const [ topOfferActive, setTopOfferActive ] = useState(!!article?.offer1);
    const [ topOffer, setTopOffer ] = useState(article?.offer1 ?? '');
    const [ mobileTopOfferActive, setMobileTopOfferActive ] = useState(!!article?.mobile?.offer1);
    const [ mobileTopOffer, setMobileTopOffer ] = useState(article?.mobile?.offer1 ?? '');

    const [ bottomOfferActive, setBottomOfferActive ] = useState(!!article?.offer2);
    const [ bottomOffer, setBottomOffer ] = useState(article?.offer2 ?? '');
    const [ mobileBottomOfferActive, setMobileBottomOfferActive ] = useState(!!article?.mobile?.offer2);
    const [ mobileBottomOffer, setMobileBottomOffer ] = useState(article?.mobile?.offer2 ?? '');

    const [ pageSizeTabValue, setPageSizeTabValue ] = useState(0);

    const [ isSaving, setSaving ] = useState(false);
    const { register, errors, handleSubmit } = useForm({ mode: 'onChange', reValidateMode: 'onChange', });

    const close = () => {
        dispatchSelection({ type: 'CLEAR_DATA'  });
        dispatchSelection({ type: 'TOGGLE_ARTICLE_DIALOG' });
    };

    const handleRedirect = (data) => {
        if (data.saveArticle.success) {
            addToast('Successfully SAVED Article!', { appearance: 'success', autoDismiss: true });
            setSaving(false);
        } else {
            console.error(data.message);
            addToast('Failed!', { appearance: 'error', autoDismiss: true });
            setSaving(false);
        };
        close();
    };

    const [saveArticle] = useMutation(
        SAVE_ARTICLE, {
        refetchQueries: [{ query: ARTICLES }],
        onCompleted: handleRedirect
    });

    const handleAddInputClick = () => {
        if(buttonState.length > 6) return;
        setButtonState([...buttonState, { ...blankButton }]);
    };
    const handleAddMobileInputClick = () => {
        if(buttonState.length > 6) return;
        setMobileButtonState([...buttonState, { ...blankButton }]);
    };

    const handleRemoveInputClick = (idx) => {
        if(buttonState.length < 4) return;
        let newState = buttonState.filter((el, i) => i !== idx);
        setButtonState(newState);
    };
    const handleRemoveMobileInputClick = (idx) => {
        if(mobileButtonState.length < 4) return;
        let newState = mobileButtonState.filter((el, i) => i !== idx);
        setMobileButtonState(newState);
    };

    const handleButtonChange = (e) => {
        const updatedButtons = [...buttonState];
        updatedButtons[Number(e.target.id)]['button'] = e.target.value;
        setButtonState(updatedButtons);
    };
    const handleMobileButtonChange = (e) => {
        const updatedButtons = [...mobileButtonState];
        updatedButtons[Number(e.target.id)]['button'] = e.target.value;
        setMobileButtonState(updatedButtons);
    };

    const handleSubmission = (formData) => {
        setSaving(true);
        const { key, title, cci_link, mobile_cci_link } = formData;
        const buttonValues = buttonState.map((input) => (
            input.button
        ));
        const mobileButtonValues = mobileButtonState.map((input) => (
            input.button
        ));
        const newButtonGroupKey = `${key.toLowerCase()}-button_group-${new Date().getTime()}`;
        const cleanButtons = article?.button_groups?.map((obj) => ({ group_key: obj.group_key, buttons: obj.buttons })) || null;
        const newMobileButtonGroupKey = `${key.toLowerCase()}-mobile-button_group-${new Date().getTime()}`;
        const cleanMobileButtons = article?.mobile?.button_groups?.map((obj) => ({ group_key: obj.group_key, buttons: obj.buttons })) || null;

        const sendData = {
            key: key.toLowerCase(),
            title,
            header: header || '',
            sub_text: subText || '',
            button_title: buttonTitle || '',
            offer1: topOfferActive ? topOffer : null,
            offer2: bottomOfferActive ? bottomOffer : null,
            offer_block: blockActive ? offerBlock : null,
            cci_link: cciActive ? cci_link : '',
            rsoc_active: rsocActive,
            segment: desktopSegment,
            active_button_group_key: !!article ? article.active_button_group_key : newButtonGroupKey,
	        button_groups: !!article ? cleanButtons : [
                {
                    group_key: newButtonGroupKey ,
                    buttons: buttonValues
                }
            ],
            mobile: {
                header: mobileHeader || '',
                sub_text: mobileSubText || '',
                button_title: mobileButtonTitle || '',
                offer1: mobileTopOfferActive ? mobileTopOffer : null,
                offer2: mobileBottomOfferActive ? mobileBottomOffer : null,
                offer_block: mobileBlockActive ? mobileOfferBlock : null,
                cci_link: mobileCciActive ? mobile_cci_link : '',
                rsoc_active: mobileRsocActive,
                segment: mobileSegment,
                active_button_group_key: !!article?.mobile ? article.mobile.active_button_group_key : newMobileButtonGroupKey,
                button_groups: !!article?.mobile ? cleanMobileButtons : [
                    {
                        group_key: newMobileButtonGroupKey ,
                        buttons: mobileButtonValues
                    }
                ],
            }
        };
        console.log('Article:', sendData);
        saveArticle({ variables: { article: sendData } });
    };

    const OfferBlockSelection = ({ size }) => (
       <><div style={styles.toggle}>
            <FormControlLabel 
                control={<Switch 
                    checked={size === 'mobile' ? mobileBlockActive : blockActive}
                    onChange={(e) => {
                        if(size === 'mobile') {
                            setMobileBlockActive(e.target.checked)
                        } else {
                            setBlockActive(e.target.checked)
                        }
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                />} 
                label={size === 'mobile' ? "Mobile Offer Block?" : "Offer Block?" }
            />
        </div>
        {blockActive && size !== 'mobile' && (
            <BlockSelector
                value={offerBlock}
                setValue={(e) => setOfferBlock(e.target.value)}
            />
        )}
        {mobileBlockActive && size === 'mobile' && (
            <BlockSelector
                value={mobileOfferBlock}
                setValue={(e) => setMobileOfferBlock(e.target.value)}
            />
        )}
       </> 
    );

    const OffersTopBottomSelection = ({ size }) => (
        <div style={styles.offerSelectionRow}>
            <div style={styles.toggleGroup}>
                <div style={styles.toggle}>
                    <FormControlLabel 
                        control={<Switch 
                            checked={size === 'mobile' ? mobileTopOfferActive : topOfferActive}
                            onChange={(e) => {
                                if(size === 'mobile') {
                                    setMobileTopOfferActive(e.target.checked)
                                } else {
                                    setTopOfferActive(e.target.checked)
                                }
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />} 
                        label={size === 'mobile' ? "Mobile Top Offer?" : "Top Offer?"} 
                    />
                </div>
                {topOfferActive && size !== 'mobile' && (
                    <OfferSelector 
                        id='top-offer-selector'
                        label='Top Offer'
                        value={topOffer}
                        setValue={(e) => setTopOffer(e.target.value)}
                    />
                )}
                {mobileTopOfferActive && size === 'mobile' && (
                    <OfferSelector 
                        id='mobile-top-offer-selector'
                        label='Mobile Top Offer'
                        value={mobileTopOffer}
                        setValue={(e) => setMobileTopOffer(e.target.value)}
                    />
                )}
            </div>
            <div style={styles.toggleGroup}>
                <div style={styles.toggle}>
                    <FormControlLabel 
                        control={<Switch 
                            checked={size === 'mobile' ? mobileBottomOfferActive : bottomOfferActive}
                            onChange={(e) => {
                                if(size === 'mobile') {
                                    setMobileBottomOfferActive(e.target.checked)
                                } else {
                                    setBottomOfferActive(e.target.checked)
                                }
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />} 
                        label={size === 'mobile' ? "Mobile Bottom Offer?" : "Bottom Offer?"} 
                    />
                </div>
                {bottomOfferActive && size !== 'mobile' && (
                    <OfferSelector 
                        id='bottom-offer-selector'
                        label='Bottom Offer'
                        value={bottomOffer}
                        setValue={(e) => setBottomOffer(e.target.value)}
                    />
                )}
                {mobileBottomOfferActive && size === 'mobile' && (
                    <OfferSelector 
                        id='mobile-bottom-offer-selector'
                        label='Mobile Bottom Offer'
                        value={mobileBottomOffer}
                        setValue={(e) => setMobileBottomOffer(e.target.value)}
                    />
                )}
            </div>
        </div>
    );

    const FormElements = ({size}) => (
        <>

            <OfferBlockSelection size={size} />

            <OffersTopBottomSelection size={size} />

            <div style={styles.toggleRow}>
                <FormControlLabel 
                    control={<Switch 
                        checked={size === 'mobile' ? mobileRsocActive : rsocActive}
                        onChange={(e) => {
                            if(size === 'mobile') {
                                setMobileRsocActive(e.target.checked)
                            } else {
                                setRsocActive(e.target.checked)
                            }
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />} 
                    label="RSOC Active?" 
                />
            </div>
            {rsocActive && size !== 'mobile' && (
            <>
                <TextField
                    name='header'
                    className='form__text-input'
                    label='Header - (Main title for article)'
                    fullWidth
                    // error={!!errors.header}
                    margin='normal'
                    value={header}
                    onChange={(e) => setHeader(e.target.value)}
                    // inputRef={ register({ required: 'an Article Header is required!' }) }
                />
                <TextField
                    name='sub_text'
                    className='form__text-input'
                    label='Sub-Text - (Descriptive paragraph)'
                    fullWidth
                    // error={!!errors.sub_text}
                    margin='normal'
                    multiline
                    value={subText}
                    onChange={(e) => setSubText(e.target.value)}
                    // inputRef={ register({ required: 'Article SubText is required!' }) }
                />
                <TextField
                    name='button_title'
                    className='form__text-input'
                    label='Button Header - (Search and Compare ...)'
                    fullWidth
                    // error={!!errors.button_title}
                    margin='normal'
                    value={buttonTitle}
                    onChange={(e) => setButtonTitle(e.target.value)}
                    // inputRef={ register({ required: 'a Button Group Title is required!' }) }
                />
            </>
            )}
            {mobileRsocActive && size === 'mobile' && (
            <>
                <TextField
                    name='mobile_header'
                    className='form__text-input'
                    label='Mobile Header - (Main title for article)'
                    fullWidth
                    // error={!!errors.header}
                    margin='normal'
                    value={mobileHeader}
                    onChange={(e) => setMobileHeader(e.target.value)}
                    // inputRef={ register({ required: 'an Article Header is required!' }) }
                />
                <TextField
                    name='mobile_sub_text'
                    className='form__text-input'
                    label='Mobile Sub-Text - (Descriptive paragraph)'
                    fullWidth
                    // error={!!errors.sub_text}
                    margin='normal'
                    multiline
                    value={mobileSubText}
                    onChange={(e) => setMobileSubText(e.target.value)}
                    // inputRef={ register({ required: 'Article SubText is required!' }) }
                />
                <TextField
                    name='mobile_button_title'
                    className='form__text-input'
                    label='Mobile Button Header - (Search and Compare ...)'
                    fullWidth
                    // error={!!errors.mobile_button_title}
                    margin='normal'
                    value={mobileButtonTitle}
                    onChange={(e) => setMobileButtonTitle(e.target.value)}
                    // inputRef={ register({ required: 'a Button Group Title is required!' }) }
                />
            </>
            )}
            <div style={styles.toggle}>
                <FormControlLabel 
                    control={<Switch 
                        checked={size === 'mobile' ? mobileCciActive : cciActive}
                        onChange={(e) => {
                            if(size === 'mobile') {
                                setMobileCciActive(e.target.checked)
                            } else {
                                setCciActive(e.target.checked)
                            }
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />} 
                    label="Click Crafted Insight?" 
                />
            </div>
            {cciActive && size !== 'mobile' && 
                <TextField
                    name='cci_link'
                    className='form__text-input'
                    label='CCI Article Link URL'
                    fullWidth
                    margin='normal'
                    defaultValue={!!article ? article.cci_link : ''}
                    inputRef={ register() }
                />
            }
            {mobileCciActive && size === 'mobile' && 
                <TextField
                    name='mobile_cci_link'
                    className='form__text-input'
                    label='CCI Article Link URL'
                    fullWidth
                    margin='normal'
                    defaultValue={!!article ? article?.mobile?.cci_link : ''}
                    inputRef={ register() }
                />
            }
            {!article && size !== 'mobile' && (
                <div style={styles.buttonInputContainer}>
                    <div style={styles.buttonInputAddRow}>
                        <InputLabel id='pid-input-label' style={styles.buttonInputLabel}>Buttons</InputLabel>
                        <LittleIcon 
                            icon='plus-circle' 
                            color='rgba(67, 150, 180, 0.75)' 
                            handleClick={handleAddInputClick}
                        />
                    </div>
                    { buttonState.map((val, idx) => {
                        const inputId = `button-${idx}`;
                        return (
                            <div key={`button-input__${idx}`} style={styles.buttonInputRow}>
                                <div style={styles.buttonInputRowNumber}>{`${idx + 1}.)`}</div>
                                <TextField
                                    id={`${idx}`}
                                    name={inputId}
                                    className='button'
                                    fullWidth
                                    margin='normal'
                                    value={buttonState[idx].button}
                                    onChange={handleButtonChange}
                                />
                                {idx !== 0 && (
                                    <LittleIcon 
                                        icon='minus-circle' 
                                        color='red' 
                                        handleClick={() => handleRemoveInputClick(idx)}
                                    />
                                )}
                            </div>
                        );
                    })
                    }
                </div>
            )}
            {!article?.mobile && size === 'mobile' && (
                <div style={styles.buttonInputContainer}>
                    <div style={styles.buttonInputAddRow}>
                        <InputLabel id='pid-input-label' style={styles.buttonInputLabel}>Buttons</InputLabel>
                        <LittleIcon 
                            icon='plus-circle' 
                            color='rgba(67, 150, 180, 0.75)' 
                            handleClick={handleAddMobileInputClick}
                        />
                    </div>
                    { mobileButtonState.map((val, idx) => {
                        const mobileInputId = `mobile-button-${idx}`;
                        return (
                            <div key={`mobile-button-input__${idx}`} style={styles.buttonInputRow}>
                                <div style={styles.buttonInputRowNumber}>{`${idx + 1}.)`}</div>
                                <TextField
                                    id={`${idx}`}
                                    name={mobileInputId}
                                    className='button'
                                    fullWidth
                                    margin='normal'
                                    value={mobileButtonState[idx].button}
                                    onChange={handleMobileButtonChange}
                                />
                                {idx !== 0 && (
                                    <LittleIcon 
                                        icon='minus-circle' 
                                        color='red' 
                                        handleClick={() => handleRemoveMobileInputClick(idx)}
                                    />
                                )}
                            </div>
                        );
                    })
                    }
                </div>
            )}
        </>
    );

    return (
        <DialogBase
            open={selectionState.showArticleDialog}
            close={close}
            title={!!article ? 'Edit Article:' : 'Add New Article:'}
            isSaving={isSaving}
            handleSubmission={handleSubmit(handleSubmission)}
            handleDelete={null}
        >
            <TextField
                name='key'
                className='form__text-input'
                label='Article Key - (Must be unique)'
                fullWidth
                autoFocus={!article}
                error={!!errors.key}
                margin='normal'
                defaultValue={article ? article.key : ''}
                inputRef={ register({ required: 'an Article Key is required!' }) }
            />
            <TextField
                name='title'
                autoFocus={!!article}
                className='form__text-input'
                label='Display Title - (For easy reference)'
                fullWidth
                error={!!errors.title}
                margin='normal'
                defaultValue={article ? article.title : ''}
                inputRef={ register({ required: 'an Article Title is required!' }) }
            />
            {/* This is where the display size content is shown */}
            <PageSizeTabs pageSizeTabValue={pageSizeTabValue} setPageSizeTabValue={setPageSizeTabValue}/>
            <TabContent value={pageSizeTabValue} idx={0}>
                {/* Mobile */}
                <div style={styles.toggleRow}>
                    <TextField
                        name='mobile-segment'
                        className='form__text-input'
                        label='Mobile Segment'
                        fullWidth
                        // error={!!errors.header}
                        margin='normal'
                        value={mobileSegment}
                        onChange={(e) => setMobileSegment(e.target.value)}
                        // inputRef={ register({ required: 'an Article Header is required!' }) }
                    />
                </div>
				<FormElements size='mobile'/>
			</TabContent>
            <TabContent value={pageSizeTabValue} idx={1}>
                {/* Desktop */}
                <div style={styles.toggleRow}>
                    <TextField
                        name='desktop-segment'
                        className='form__text-input'
                        label='Desktop Segment'
                        fullWidth
                        // error={!!errors.header}
                        margin='normal'
                        value={desktopSegment}
                        onChange={(e) => setDesktopSegment(e.target.value)}
                        // inputRef={ register({ required: 'an Article Header is required!' }) }
                    />
                </div>
				<FormElements size='desktop'/>
			</TabContent>
        </DialogBase>
    );
};

export default ArticleDialog;