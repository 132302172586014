const styles = {
    offeringsContainer: {
        width: '100%',
        minWidth: '90vw',
        maxWidth: '1500px'
    },
    tabContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: '0',
        width: '100%',
        maxWidth: '1550px',
        minWidth: '90vw'
    },
    pageContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    content: {
        padding: '20px 0 0 0',
        width: '100%',
        maxWidth: '1500px',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
};

export default styles;