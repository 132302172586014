import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../context';
import styles from './Endpoints.css.js';
import ActionBar from '../../../ActionBar';
import EndpointGroupList from '../../../EndpointGroupList';
import OfferGroupDialog from '../../../Forms/OfferGroupDialog'

const OfferGroups = () => {
    const { dispatchSelection } = useContext(AppContext);
    const [ showDialog, setShowDialog ] = useState(false);

    const handleClose = () => {
        dispatchSelection({ type: 'CLEAR_SELECTION' });
        setShowDialog(false);
    };

    const handleAddClick = () => {
        dispatchSelection({ type: 'CLEAR_SELECTION' });
        setShowDialog(true);
    };

    const handleEditClick = (event, rowData) => {
        event.preventDefault();
        dispatchSelection({ type: 'EDIT_OFFER_GROUP', payload: rowData });
        setShowDialog(true);
    };

    return (
        <>
            <ActionBar text='Offer' title='GROUPS' handleClick={handleAddClick} size='Lg'/>
            <div style={styles.content}>
                {!showDialog ? 
                    <EndpointGroupList
                        isSelection={false}
                        handleEdit={handleEditClick}
                        activeGroup='N/A' 
                        setGroup={()=>(null)}
                        setName={()=>(null)}
                    /> : 
                    <OfferGroupDialog open={showDialog} close={handleClose} />
                }
            </div>
        </>
    )
};

export default OfferGroups;