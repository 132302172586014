import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './ArticleControl.css';
import { useQuery, useMutation } from '@apollo/client';
import { OFFER_BLOCKS, DIRECT_OFFER_BLOCK } from '../../../graphql/queries';
import { SAVE_DIRECT_OFFER_BLOCK } from '../../../graphql/mutations';
import ActionBar from '../../ActionBar';
import Loading from '../../Loading';

const SubmitButton = ({ isDisabled, setDisabled, offerBlockId, setSaved}) => {
    const { addToast } = useToasts();
    const [ isHovering, setHovering ] = useState(false);
    const [ isSaving, setSaving ] = useState(false);

    const handleRedirect = (data) => {
        if (data.saveDirectOfferBlock.success) {
            addToast('Successfully SAVED Direct Offer Block!', { appearance: 'success', autoDismiss: true });
            setSaving(false);
            setSaved(offerBlockId);
            setDisabled(true);
        } else {
            console.error(data.message);
            addToast('Failed!', { appearance: 'error', autoDismiss: true });
            setSaving(false);
        };
    };

    const [saveDirectOfferBlock] = useMutation(
        SAVE_DIRECT_OFFER_BLOCK, {
        refetchQueries: [{ query: DIRECT_OFFER_BLOCK }],
        onCompleted: handleRedirect
    });

    const handleSubmission = () => {
        setSaving(true);
        saveDirectOfferBlock({ variables: { offer_block_id: offerBlockId } });
    };

    const themedStyle = Object.assign({},
        styles.submitButton,
        styles.submitColor,
        isHovering && styles.hover,
        isSaving && styles.progress,
        isDisabled && styles.disabledColor
    );

    return(
        <Button
            id='submit_button'
            type='submit'
            onClick={handleSubmission} 
            onFocus={(event) => event.stopPropagation()}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            style={themedStyle}
            disabled={isDisabled}
        >
            {isSaving ? <CircularProgress size={24} style={styles.buttonProgress} /> : 'Save'}
        </Button>
    )
};

const PopupControl = () => {
    const [ saveDisabled, setSaveDisabled ] = useState(true);
    const [ savedSelection, setSavedOfferBlock ] = useState('');
    const [ offerBlockSelection, setOfferBlock ] = useState(null);

    const handleSelectionFetch = (d) => {
        const current = d.fetchDirectOfferBlock?.body?.id ?? '';
        setOfferBlock(current);
        setSavedOfferBlock(current);
    };

    const { loading, error, data } = useQuery(OFFER_BLOCKS);
    const { loadingSelection, errorSelection } = useQuery(DIRECT_OFFER_BLOCK, {
        onCompleted: handleSelectionFetch
    });

    if (error) {
        return <div>{`Error fetching Offer Block List: ${error}`}</div>;
    };

    if (errorSelection) {
        return <div>{`Error fetching Direct Offer Selection: ${errorSelection}`}</div>;
    };

    if (loading || loadingSelection || offerBlockSelection === null) {
        return <div style={styles.loading}><Loading /></div>
    };

    const blocks = data.fetchOfferBlocks.body;

    const setOfferBlockSelection = (e) => {
        setOfferBlock(e.target.value);
        if(e.target.value !== savedSelection) {
            setSaveDisabled(false);
        } else {
            setSaveDisabled(true);
        };
    };

    return (
        <div style={styles.pageContainer}>
            <ActionBar text='Selection' title='POPUP' size='Lg'/>
                <div style={styles.selectorContainer}>
                    <FormControl fullWidth>
                        <InputLabel id='select-label'>Block Name</InputLabel>
                        <Select
                            labelId='select-label'
                            id='offer-block-selector'
                            value={offerBlockSelection}
                            label='Offer Block'
                            onChange={setOfferBlockSelection}
                        >
                            {blocks.map((block, idx) => (
                                <MenuItem key={`block-option-${idx}`} value={block.id}>{block.name}</MenuItem>
                            ))}
                        </Select>
                        <div style={styles.submitButtonContinaer}>
                            <SubmitButton 
                                isDisabled={saveDisabled} 
                                setDisabled={setSaveDisabled} 
                                offerBlockId={offerBlockSelection}
                                setSaved={setSavedOfferBlock}
                            />
                        </div>
                    </FormControl>
                </div>
        </div>
    )
};

export default PopupControl;