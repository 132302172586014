import React from 'react';
import { useQuery } from '@apollo/client';
import { RECORDS_BY_KEY } from '../../../graphql/queries';
import Paper from '@mui/material/Paper';
import EmptyList from '../../EmptyList';
import Loading from '../../Loading';
import styles from './Programs.css.js';
import ProgramRecordColumns from '../../Tables/TableColumns/ProgramRecordColumns';
import TableIcons from '../../Tables/TableIcons';
import MaterialTable from 'material-table';
import ActionButton from '../../ActionButton';

const RecordsTable = ({ domain, handleEdit }) => {
    const { loading, error, data } = useQuery(RECORDS_BY_KEY, { variables: { domain_key: domain.domain_key }});

    if (loading) return <div style={styles.loadingTable}><Loading /></div>;

    if (error) return <div>{`Error occured fetching endpoint groups: ${error.message}`}</div>;

    const recordsArray = data.fetchProgramRecordsByKey.body;

    const dataArray = recordsArray.map(record => ({
        id: record.id,
        record_name: record.record_name,
        domain_key: record.domain_key,
        pid: record.pid,
        endpoint_id: record.endpoint_id,
    }));

    return (
        <Paper style={styles.recordTablePaper} elevation={10}>
            {recordsArray?.length > 0 ? 
                <MaterialTable
                    icons={TableIcons}
                    columns={ProgramRecordColumns()}
                    data={dataArray}
                    options={{
                        search: false,
                        toolbar: false,
                        sorting: true,
                        showTitle: false,
                        paging: false,
                        headerStyle: styles.offersHeader,
                        rowStyle: styles.offerRow,
                    }}
                    style={styles.recordsTable}
                    actions={[
                        rowData => ({
                            icon: 'Edit', 
                            tooltip: 'Edit Record', 
                            onClick: handleEdit,
                            disabled: !rowData.id
                        })
                    ]}
                    components={{
                        Action: props => (
                            <div style={{marginLeft: '20px'}}>
                                <ActionButton 
                                    icon='pen' 
                                    bg='clear'
                                    bgHover='ice' 
                                    handleClick={(e) => handleEdit(e, props.data)}
                                />
                            </div>
                        ),
                    }}
                /> : <EmptyList />
            }
        </Paper>
    )
};

export default RecordsTable;