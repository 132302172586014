import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import SearchPerformanceReport from './SearchPerformanceReport';
import SubIdReport from './SubIdReport';
import SubIdHourlyReport from './SubIdHourlyReport';
import DYCReport from './DYCReport';
import DownloadReport from './DownloadReport';
import styles from '../Offerings/Offerings.css';
import ActionBar from '../../ActionBar';
import CategoryTabPanel from '../Offerings/CategoryTabPanel';

const Offerings = () => {
	const { appState } = useContext(AppContext);
	const tabValue = appState.reportsTabValue;

	return(
        <div style={styles.pageContainer}>
            <ActionBar text='' title='REPORTS' handleClick={null} size='Lg'/>
			<CategoryTabPanel value={tabValue} idx={0}>
				<DownloadReport />
			</CategoryTabPanel>
            <CategoryTabPanel value={tabValue} idx={1}>
				<SearchPerformanceReport />
			</CategoryTabPanel>
            <CategoryTabPanel value={tabValue} idx={2}>
				<SubIdReport />
			</CategoryTabPanel>
            <CategoryTabPanel value={tabValue} idx={3}>
				<SubIdHourlyReport />
			</CategoryTabPanel>
            <CategoryTabPanel value={tabValue} idx={4}>
				<DYCReport />
			</CategoryTabPanel>
        </div>
	)
};

export default Offerings;