import React from 'react';
import { useQuery } from '@apollo/client';
import { ALL_ENDPOINTS } from '../../../graphql/queries';
import EmptyList from '../../EmptyList';
import Loading from '../../Loading';
import styles from './OfferGroupDialog.css.js';
import EndpointOffersColumns from '../../Tables/TableColumns/EndpointOffersColumns';
import TableIcons from '../../Tables/TableIcons';
import MaterialTable from 'material-table';

const OfferSelectionList = ({ handleSelection, activeIds }) => {
    const { loading, error, data } = useQuery(ALL_ENDPOINTS);

    if (loading) return <div style={styles.loadingTable}><Loading /></div>;

    if (error) return <div>{`Error occured fetching endpoint offers: ${error.message}`}</div>;

    const endpointsArray = data.fetchAllEndpoints.body;

    return (
        <div style={styles.tablePaper}>
            {endpointsArray?.length > 0 ? 
                <MaterialTable
                    icons={TableIcons}
                    columns={EndpointOffersColumns()}
                    data={endpointsArray.map(endpoint => {
                        return {
                            id: endpoint.id,
                            name: endpoint.name,
                            offer_page: endpoint.offer_page,
                            url: endpoint.url,
                            jump: endpoint.jump,
                            four_button: endpoint.four_button,
                            offers: endpoint.offers,
                            restricted: endpoint.restricted,
                            states: endpoint.states,
                            question_text: endpoint.question_text,
                            selection_links: endpoint.selection_links && endpoint.selection_links.map((link) => ({
                                id: link.id,
                                cluster: link.cluster,
                                group: link.group,
                                name: link.name,
                                text: link.text,
                                url: link.url,
                                jump: link.jump
                            })),
                            quick_link_group: endpoint.quick_link_group
                        };
                    })}
                    options={{
                        selection: true,
                        showSelectAllCheckbox: false,
                        search: false,
                        sorting: true,
                        showTitle: false,
                        toolbar: false,
                        paging: false,
                        headerStyle: styles.offersHeader,
                        rowStyle: styles.offerRow,
                        selectionProps: rowData => ({
                            checked: activeIds.includes(rowData.id),
                            onClick: (event) => handleSelection(event, rowData)
                        })
                    }}
                    style={styles.endpointsTable}
                /> : <EmptyList />
            }
        </div>
    )
};

export default OfferSelectionList;