const styles = {
    tableCell: {
        fontSize: '1.1rem',
        fontWeight: '400',
        padding: '2px 4px 2px 10px',
        whiteSpace: 'nowrap'
        // textAlign: 'left'
    },
    centerCell: {
        fontSize: '1.1rem',
        fontWeight: '400',
        padding: '2px 4px 2px 10px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    collectionCell: {
        fontSize: '1rem',
        fontWeight: '200',
        borderBottom: '1px solid rgba(16,64,107,0.45)',
        color: 'rgba(16,64,107,1)',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: '3px 0',
        whiteSpace: 'nowrap'
    },
    imageCell: {
        border: '1px solid red',
        padding: '2px 4px 2px 10px',
    },
    clusterText: {
        fontWeight: 'bold', 
        fontSize: '1.2rem',
        color: 'black',
        paddingLeft: '15px',
        whiteSpace: 'nowrap'
    },
    tableCellSm: {
        fontSize: '1rem',
        fontWeight: '400',
        whiteSpace: 'nowrap'
    },
    collectionHeadSm: {},
    collectionCellSm: {
        fontSize: '0.8rem',
        fontWeight: '200',
        color: 'rgba(16,64,107,1)',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        whiteSpace: 'nowrap',
        padding: '0'
    },
    clusterTextSm: {
        fontWeight: '500',
        fontSize: '1rem',
        paddingLeft: '5px',
        color: 'black',
    },
    emptyCellIcon: {
        color: 'rgba(0, 0, 0, 0.15)',
        fontSize: '1.5rem'
    },
    emptyColumn: {
        color: 'rgba(0,0,0,0.15)',
        fontSize: '1.5rem',
    },
    inline: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    prefix: {
        marginRight: '10px'
    },
    titleCell: {
        padding: '2px 4px 2px 10px',
        fontWeight: '400',
        // color: 'rgb(16,64,107)',
        color: 'black',
        width: '20%',
        fontSize: '1.2rem',
        whiteSpace: 'nowrap'
    },
    titleCellSm: {
        fontWeight: 'bold',
        color: 'rgb(16,64,107)',
        fontSize: '1.2rem',
        whiteSpace: 'nowrap',
        padding: '0'
    },
    titleContainer: {
        position: 'relative',
        left: '-170px'
    },
    buttonCell: {
        padding: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonContainer: {
        position: 'relative',
        left: '-100px'
    },
    inlineSpan: {
        display: 'inline-flex',
        alignItems: 'center'
    }
};

export default styles;