import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { useToasts } from 'react-toast-notifications';
import { useMutation } from '@apollo/client';
import { DELETE_QUICK_LINK } from '../../../graphql/mutations';
import { ALL_QUICK_LINKS } from '../../../graphql/queries';
import styles from './DeleteDialogs.css.js';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const DeleteQuickLink = () => {
    const { addToast } = useToasts();
    const { selectionState, dispatchSelection } = useContext(AppContext);
    const { delete_doc } = selectionState;
    const [ isSaving, setIsSaving ] = useState(false);
    const [ isHovering, setHovering ] = useState(false);

    const closeDialog = () => {
        dispatchSelection({ type: 'CLOSE_DELETE' });
    };

    const handleRedirect = (data) => {
        addToast(data.deleteQuickLink.message, { appearance: 'success', autoDismiss: true });
        setIsSaving(false);
        closeDialog();
    };
    const handleError = (error) => {
        console.error(error);
        addToast(error?.message ?? 'Error!', { appearance: 'error', autoDismiss: true });
        setIsSaving(false);
        closeDialog();
    };

    const [deleteQuickLink] = useMutation(
        DELETE_QUICK_LINK, {
        refetchQueries: [{ query: ALL_QUICK_LINKS }],
        onCompleted: handleRedirect,
        onError: handleError
    });

    const handleConfirmClick = () => {
        setIsSaving(true);
        deleteQuickLink({ variables: { id: delete_doc.item_id } });
    };

    const buttonStyle = Object.assign({},
        styles.submitButton,
        isHovering && styles.deleteHover
    );

    return (
        <div style={styles.deleteDialogContent}>
            <div style={styles.textContainer}>
                <span style={styles.importantText}>This action is permanant!</span>
                <span style={styles.dialogText}>Are you sure you want to delete the</span>
                <span style={styles.dialogText}>
                    Quick Link: <span style={styles.boldText}>{`${delete_doc?.name}`}</span>?<br/>
                    All associated offers will be effected!
                </span>
            </div>
            <ButtonGroup style={styles.formButtons}>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button 
                    onClick={handleConfirmClick} 
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                    style={buttonStyle}
                >
                    {isSaving ? <CircularProgress size={24} style={styles.buttonProgress} /> : 'BOOM!'}
                </Button>
            </ButtonGroup>
        </div>
    );
}

export default DeleteQuickLink;