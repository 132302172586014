const styles = {
    loadingTable: {
        minWidth: '100%',
        height: '100%',
        minHeight: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    groupTable: {
        borderRadius: '5px',
        height: '100%',
        maxHeight: '950px',
        margin: '3px'
    },
    groupRow: {
        backgroundColor: 'aliceblue',
        color: 'rgba(16,64,107,1)',
        borderRadius: '5px',
    },
    actionContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0'
    },
    actionButton: {
        backgroundColor: 'rgba(189,189,189,0.1)',
        color: '#10406B',
    },
    actionIcon: {
        fontSize: '1.4rem',
        color: 'rgba(16,64,107,1)',
    },
    tablePaper: {
        width: '100%',
        height: '100%',
        maxWidth: '1300px',
        margin: '0 0 40px 0',
        backgroundColor: 'rgba(16,64,107,0.75)',
        overflow: 'hidden',
        borderRadius: '5px',
    },
    toggleIcon: {
        fontSize: '1.4rem',
        color: 'rgba(16,64,107,0.7)',
    }
};

export default styles;