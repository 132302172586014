const styles = {
    pageContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    content: {
        padding: '20px 50px 0 50px',
        width: '100%',
        maxWidth: '1500px',
    },
    loadingTable: {
        minWidth: '100%',
        height: '100%',
        minHeight: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    quicklinkTable: {
        height: '100%',
        overflow: 'hidden',
        borderRadius: '5px',
        margin: '3px',
        backgroundColor: '#FFF'
    },
    quicklinkTableActive: {
        backgroundColor: 'aliceblue'
    },
    offerRow: {
        color: 'rgba(16,64,107,1)',
        backgroundColor: 'aliceblue'
    },
    tablePaper: {
        width: '100%',
        maxWidth: '1300px',
        height: '100%',
        margin: '0',
        backgroundColor: 'rgba(16,64,107,0.8)',
        borderRadius: '5px',
        overflow: 'hidden',
    },
};

export default styles;