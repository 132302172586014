import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../context';
import { useToasts } from 'react-toast-notifications';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation } from '@apollo/client';
import { SAVE_ARTICLE } from '../../../graphql/mutations';
import { ARTICLES } from '../../../graphql/queries';
import styles from './DeleteDialogs.css';

const DeleteArticleButtonGroup = () => {
    const { selectionState, dispatchSelection } = useContext(AppContext);
    const { article, group } = selectionState.delete_doc;
    const { addToast } = useToasts();    
    const [ isSaving, setIsSaving ] = useState(false);
    const [ isHovering, setHovering ] = useState(false);
    const [ showErrorPage, setShowErrorPage ] = useState(false);

    const closeDialog = () => {
        dispatchSelection({ type: 'CLEAR_DATA'  });
        dispatchSelection({ type: 'CLOSE_DELETE' });
        dispatchSelection({ type: 'CLOSE_BUTTON_GROUP_DIALOG' });
    };

    const handleRedirect = (data) => {
        addToast('Successfully DELETED button group!', { appearance: 'success', autoDismiss: true });
        setIsSaving(false);
        closeDialog();
    };

    const handleError = (error) => {
        console.error(error);
        addToast(error?.message ?? 'Error!', { appearance: 'error', autoDismiss: true });
        setIsSaving(false);
        closeDialog();
    };

    const [saveArticle] = useMutation(
        SAVE_ARTICLE, {
        refetchQueries: [{ query: ARTICLES }],
        onCompleted: handleRedirect,
        onError: handleError,
    });

    useEffect(() => {
        // Fist make sure this is not the only group available
        if(article.button_groups.length === 1) {
            setShowErrorPage(true);
            return;
        };

    },[article]);

    const handleDeleteButtonGroup = () => {
        setIsSaving(true);            
        let buttonArr = article.button_groups.map((obj) => ({
            group_key: obj.group_key,
            buttons: obj.buttons
        }));
        // Find index of this group
        // find and replace group with new one
        const index = buttonArr.findIndex((obj) => {
            return obj.group_key === group.group_key
        });
        //If index is found, remove element
        let activeKey = article.active_button_group_key;
        if(!!index) {
            let resetKey = false;
            // Check that active is reset if this group is active
            if(group.group_key === activeKey){
                resetKey = true;
            };
            buttonArr.splice(index, 1);
            if(resetKey) {
                // set the next group as active or set to null
                activeKey = buttonArr.length > 0 ? buttonArr[0].group_key : null
            };
        };
        const updatedArticle = {
            key: article.key,
            title: article.title,
            header: article.header,
            sub_text: article.sub_text,
            button_title: article.button_title,
            active_button_group_key: activeKey,
	        button_groups: buttonArr
        };
        saveArticle({ variables: { article: updatedArticle } });
    };

    const buttonStyle = Object.assign({},
        styles.submitButton,
        isHovering && styles.deleteHover
    );

    const OnlyOnGroupError = () => (
        <div style={styles.deleteDialogContent}>
            <div style={styles.textContainer}>
                <span style={styles.importantText}>Uh Oh!</span>
                <span style={styles.dialogText}>You are trying to delete the only button group for</span>
                <span style={styles.dialogText}>
                    Article: <span style={styles.boldText}>{`${article?.title}`}</span><br/>
                    You must create an additional button group before deleting this one!
                </span>
            </div>
            <ButtonGroup style={styles.formButtons}>
                <Button onClick={closeDialog}>Cancel</Button>
            </ButtonGroup>
        </div>
    );

    const DeleteGroupForm = () => (
        <div style={styles.deleteDialogContent}>
            <div style={styles.textContainer}>
                <span style={styles.importantText}>This action is permanant!</span>
                <span style={styles.dialogText}>Are you sure you want to remove the</span>
                <span style={styles.dialogText}>
                    Button Group: <span style={styles.boldText}>{`${group?.group_key}`},</span><br/>
                    for Article: <span style={styles.boldText}>{`${article?.title}`}</span>?<br/>
                    The article will remain, but all buttons in this groups will be destroyed.
                </span>
            </div>
            <ButtonGroup style={styles.formButtons}>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button 
                    onClick={handleDeleteButtonGroup} 
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                    style={buttonStyle}
                >
                    {isSaving ? <CircularProgress size={24} style={styles.buttonProgress} /> : 'BOOM!'}
                </Button>
            </ButtonGroup>
        </div>
    );

    return showErrorPage ? <OnlyOnGroupError/> : <DeleteGroupForm/>;
};

export default DeleteArticleButtonGroup;