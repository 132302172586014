import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import DeleteProgramPath from './DeleteProgramPath';
import DeleteDomain from './DeleteDomain';
import DeleteDirectDomain from './DeleteDirectDomain';
import DeleteProgramRecord from './DeleteProgramRecord';
import DeleteEndpoint from './DeleteEndpoint';
import DeleteEndpointGroup from './DeleteEndpointGroup';
import DeleteQuickLink from './DeleteQuickLink';
import DeleteArticle from './DeleteArticle';
import DeleteArticleButtonGroup from './DeleteArticleButtonGroup';
import DeleteArticleOffer from './DeleteArticleOffer';
import DeleteOfferBlock from './DeleteOfferBlock';
import styles from './DeleteDialogs.css';

const DeleteDialogs = () => {
    const { selectionState } = useContext(AppContext);
    const { delete_doc, show_delete } = selectionState;

    const renderActiveDialog = () => {
        switch(delete_doc.type) {
            case 'program_path':
                return <DeleteProgramPath />;
            case 'domain':
                return <DeleteDomain />;
            case 'direct_domain':
                return <DeleteDirectDomain />;
            case 'program_record':
                return <DeleteProgramRecord />;
            case 'endpoint':
                return <DeleteEndpoint />;
            case 'endpoint_group':
                return <DeleteEndpointGroup />;
            case 'quick_link':
                return <DeleteQuickLink />;
            case 'article':
                return <DeleteArticle />;
            case 'article_button-group':
                return <DeleteArticleButtonGroup />;
            case 'article_offer':
                return <DeleteArticleOffer />;
            case 'offer_block':
                return <DeleteOfferBlock />;

            default:
                return `Unrecognized doc type:${delete_doc.type}`;
        }
    };

    return (
        <div style={styles.formContainer}>
            <Slide direction='down' in={show_delete} mountOnEnter unmountOnExit>
                <Paper elevation={4} style={styles.paperContainer}>
                    <div style={styles.headerContainer}>Confirm Delete:</div>
                    {renderActiveDialog()}
                </Paper>
            </Slide>
        </div>
    );
}

export default DeleteDialogs;