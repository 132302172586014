import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 200;

const styles = {
    activeTab: {
        borderRight: '2px solid #FFF',
    },
    iconContainer: {
        minWidth: '60px',
        height: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    sidebarIcon: {
        fontSize: '1.75rem',
        color: 'rgba(255,255,255,1)',
    },
    sidebarTabTitle: {
        fontSize: '1rem',
        color: '#fff',
    },
    drawerContainer: {
        backgroundColor: 'rgba(16,64,107,0.92)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100vh',
        maxHeight: 'calc(100vh - 50px)',
    },
    materialStyle: makeStyles((theme) => ({
        root: {
            display: 'flex',
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap'
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: '60px'
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
    })),
    backButton: {
        backgroundColor: 'rgba(189,189,189,0.5)',
        borderRadius: '50%',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '35px',
        maxHeight: '35px',
        width: '35px',
        minWidth: 'unset',
        margin: '0 5px',
        padding: '0',
    },
    backIcon: {
        fontSize: '1.3rem',
        color: '#10406B',
    },
    backContainer: {
        backgroundColor: 'rgba(189,189,189,0.5)',
        maxHeight: '48px',
        minHeight: '48px'
    }
};

export default styles;