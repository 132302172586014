const styles = {
    button: {
        borderRadius: '50%',
        color: 'white',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '30px',
        maxHeight: '30px',
        width: '30px',
        minWidth: 'unset',
        margin: '0',
        padding: '0',
    },
    lightBlue: { backgroundColor: 'aliceblue' },
    ice: { backgroundColor: 'rgba(16,64,107,0.25)', cursor: 'pointer', transition: 'all 0.7s ease', },
    lightGrey: { backgroundColor: '#F5F5F5' },
    white: { backgroundColor: 'rgb(255,255,255)' },
    whiteSlim: { backgroundColor: 'rgb(255,255,255)', margin: '0' },
    hoverDomain: { backgroundColor: 'rgba(66,150,180,0.75)', margin: '0' },
    green: { backgroundColor: 'rgba(66,150,180,0.5)' },
    clear: { 
        backgroundColor: 'transparent',

    },
    grey: { 
        backgroundColor: 'rgb(202,202,202)', 
        margin: '0',
    },
    icon: {
        fontSize: '1.2rem',
        color: 'rgba(16,64,107,0.7)',
    },
};

export default styles;