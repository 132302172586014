import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Loading from '../../../Loading';
import styles from '../Reports.css';
import { StyledTableCell, StyledTableRow, capitalizeTerm } from './TableDetails';

const createData = (obj) => {
    return {
        segment: obj.segment,
        type: obj.type,
        paid_clicks: obj.paid_clicks,
        revenue_total: obj.rev_total,
        rpc: obj.rev_avg || 0
    };
};

const SearchReportTable = ({ data }) => {
    const [ rows, setRows ] = useState(null);

    useEffect(() => {
        setRows(null);
        if(!!data) {
            const rowArr = data.map((rawObj) => createData(rawObj));
            setRows(rowArr);
        };
        // eslint-disable-next-line
    },[data]);

    return(
        !!rows ? (
            <TableContainer component={Paper}>
                <Table size="small" sx={{minWidth: 700}} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Segment</StyledTableCell>
                            <StyledTableCell>Device Type</StyledTableCell>
                            <StyledTableCell align="center">Paid Clicks</StyledTableCell>
                            <StyledTableCell align="center">Total Revenue</StyledTableCell>
                            <StyledTableCell align="center">Revenue Per Click</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    {rows.map((row) => (
                        <TableBody key={`${row.segment}=${row.type}`}>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">{row.segment}</StyledTableCell>
                                <StyledTableCell component="th" scope="row">{capitalizeTerm(row.type)}</StyledTableCell>
                                <StyledTableCell align="center">{row.paid_clicks}</StyledTableCell>
                                <StyledTableCell align="center">{row.revenue_total}</StyledTableCell>
                                <StyledTableCell align="center">{row.rpc}</StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    ))}
                </Table>
            </TableContainer>
        ) : (
            <div style={styles.loaderContainer}>
                <Loading />
            </div>
        )
    );
};

export default SearchReportTable;