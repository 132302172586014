import React from 'react';
import  { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './TopAppBar.css.js';

const UserIcon = () => {
    const { user } = useAuth0();
    return (
        <div style={styles.userInfo}>
            {user.picture ?
                <img alt='user-avatar' src={user.picture} style={styles.picture} /> :
                <FontAwesomeIcon style={styles.barIcon} icon={['fal', 'user-circle']} size='lg' />
            }
            <div style={styles.userName}>
                {user.nickname}
            </div>
        </div>
    );
};

export default UserIcon;