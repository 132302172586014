import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import styles from './ArticleControl.css';
import OfferListItem from './OfferListItem';
import List from '@material-ui/core/List';
import ArticleOfferDialog from '../../Forms/ArticleOfferDialog';
import { useQuery } from '@apollo/client';
import { ARTICLE_OFFERS } from '../../../graphql/queries';
import ActionBar from '../../ActionBar';
import Loading from '../../Loading';
import EmptyList from '../../EmptyList';

const RecommendedList = () => {
    const { dispatchSelection, selectionState } = useContext(AppContext);

    const { loading, error, data } = useQuery(ARTICLE_OFFERS);

    if (error) {
        return <div>{`Error fetching Article Offer List: ${error}`}</div>;
    };

    if (loading) {
        return <div style={styles.loading}><Loading /></div>
    };

    const offers = data.fetchArticleOffers.body;

    const createNewArticleOffer = (e) => {
        e.stopPropagation();
        dispatchSelection({ 
            type: 'SET_ARTICLE_OFFER', 
            payload: null
        });
        dispatchSelection({ type: 'TOGGLE_ARTICLE_OFFER_DIALOG' });
    };

    const OfferList = () => (
        <div style={styles.articleListContainer}>
            {offers.length > 0 ? (
                <List component='nav' aria-labelledby='nested-list-programs'>
                    {offers.map((article_offer, idx) => (
                        <OfferListItem
                            key={`article-item__${idx}`}
                            index={idx}
                            item={article_offer}
                        />
                    ))}
                </List>
            ) : (<EmptyList />)}
        </div>
    );

    const toggleDialog = () => {
        dispatchSelection({ type: 'TOGGLE_ARTICLE_OFFER_DIALOG' });
    };

    return (
        <div style={styles.pageContainer}>
            <ActionBar text='Article' title='OFFERS' handleClick={createNewArticleOffer} size='Lg'/>
            {selectionState.showArticleOfferDialog ? <div style={{marginTop: '20px'}}><ArticleOfferDialog open={selectionState.showArticleOfferDialog} close={toggleDialog}/></div> : <OfferList />}
        </div>
    )
};

export default RecommendedList;