const styles = {
    fillPage: {
        minHeight: '55vh',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    pageContainer: {
        minWidth: '90vw',
        maxWidth: '1500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    content: {
        padding: '20px 0 0 0',
        width: '100%',
        maxWidth: '1500px',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    editIcon: {
        padding: '0',
        margin: '0 10px',
        fontSize: '1.4rem',
        color: 'rgba(16,64,107,.5)',
    },
    tabIcon: {
        fontSize: '1.2rem',
        marginRight: '5px',
        color: 'rgba(16,64,107,1)',
    },
    tabTitle: {
        fontSize: '1.1rem',
        whiteSpace: 'nowrap',
        textAlign: 'left',
        color: '#FFFFFF',
    },
    acceptableMedia: {
        borderLeft: 'solid 1px #10406B',
        fontSize: '0.9rem',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        color: 'rgba(0,0,0,0.4)',
        padding: '0 10px',
    },
    mediaType: { paddingLeft: '5px' },
    listContainer: {
        maxWidth: '1300px',
        minWidth: 'calc(100% - 60px)',
        padding: '10px 30px 20px 30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'flex-start',
        minHeight: 'fit-content',
    },
    tableTitle: {
        fontSize: '2rem',
        fontWeight: '200',
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: 'rgba(15, 64, 107, 0.5)',
        marginBottom: '10px'
    },
    verticalTab: {
        minHeight: '50px'
    },
    loadingItemContent: {
        minWidth: '100%',
        height: '100%',
        minHeight: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    tablePaper: {
        width: '100%',
        maxWidth: '1300px',
        display: 'flex',
        margin: '0',
        // backgroundColor: 'rgba(16,64,107,0.45)',
        // backgroundColor: 'rgba(141,165,188,0.5)',
        // backgroundColor: 'rgb(141,165,188)',
        backgroundColor: 'rgba(16,64,107,0.45)',
        borderRadius: '5px',
        padding: '0'
    },
};

export default styles;