import { makeStyles } from '@material-ui/core/styles';
// const drawerWidth = 200;

const styles = {
    materialStyle: makeStyles((theme) => ({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            backgroundColor: '#FFF',
            // backgroundColor: 'rgb(147,169,188)',
            maxHeight: '60px'
        },
        appBarShift: {
            marginLeft: '200px',
            width: 'calc(100% - 200px)',
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: { marginRight: 0, marginLeft: 10 },
        hide: { display: 'none' }
    })),
    barContent: {
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    logo: {
        maxHeight: '30px',
    },
    barIcon: {
        color: '#10406B',
        fontSize: '1.75rem'
    },
    userInfo: {
        display: 'inline-flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginLeft: '20px'
    },
    userName: {
        color: '#10406B',
        textTransform: 'capitalize',
        marginLeft: '5px',
        fontSize: '1.1rem'
    },
    picture: {
        maxWidth: '30px',
        borderRadius: '50%',
        marginLeft: '5vw'
    },
    topSideContent: {
        display: 'inline-flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    }
};

export default styles;