import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import TextField from '@material-ui/core/TextField';
import { useMutation } from '@apollo/client';
import { SAVE_QUICK_LINK } from '../../../graphql/mutations';
import { ALL_QUICK_LINKS } from '../../../graphql/queries';
import { flattenString } from '../../../utils/helpers';
import styles from './QuickLinkDialog.css.js';
import DialogBase from '../DialogBase/DialogBase';
import GroupSelect from './GroupSelect';
import OfferGroupSelect from './OfferGroupSelect';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const QuickLinkDialog = ({ open, close }) => {
    const { addToast } = useToasts();
    const { selectionState, dispatchSelection } = useContext(AppContext);
    const [ activeEndpointGroup, setActiveGroup ] = useState(selectionState.quick_link.offer_group || null);
    const [ showGroupSelect, setShowGroups ] = useState(!!selectionState.quick_link.offer_group && selectionState.quick_link.offer_group !== 'N/A');
    const [ isGoogle, setIsGoogle ] = useState(selectionState.quick_link.google || false);
    const { quick_link } = selectionState;
    const [ isSaving, setIsSaving ] = useState(false);
    const { register, errors, handleSubmit } = useForm({ mode: 'onChange', reValidateMode: 'onChange', });
    
    const setGroupName = () => {};

    const handleRedirect = (data) => {
        addToast(data.saveQuickLink.message, { appearance: 'success', autoDismiss: true });
        setIsSaving(false);
        close();
    };
    const handleError = (error) => {
        console.error(error);
        addToast(error?.message ?? 'Error!', { appearance: 'error', autoDismiss: true });
        setIsSaving(false);
        close();
    };

    const [saveQuickLink] = useMutation(
        SAVE_QUICK_LINK, {
            refetchQueries: [{ query: ALL_QUICK_LINKS }],
            onCompleted: handleRedirect,
            onError: handleError,
        }
    );

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        dispatchSelection({ 
            type: 'DELETE_SELECTION', 
            payload: { 
                type: 'quick_link', 
                item_id: quick_link.id,
                name: quick_link.name
            } 
        });
    };

    const handleSubmission = (formData) => {
        setIsSaving(true);
        const formNew = {
            cluster: flattenString(formData.cluster),
            group: 'N/A',
            name: formData.name,
            text: formData.text,
            offer_group: showGroupSelect ? activeEndpointGroup : 'N/A',
            url: formData.url || null,
            jump: formData.jump || null,
            google: isGoogle
        };
        const fullForm = Object.assign({}, 
            formNew,
            !!quick_link?.id && { id: quick_link.id },
            !!quick_link?.id && { qid: quick_link.qid || quick_link.id }
        );
        console.log('full updated QL:', fullForm);
        saveQuickLink({ variables: { quick_link: fullForm } });
    };

    return (
        <DialogBase
            open={open}
            close={close}
            title={!!quick_link?.id ? 'Edit Quick Link:' : 'Add New Quick Link:'}
            isSaving={isSaving}
            handleSubmission={handleSubmit(handleSubmission)}
            handleDelete={!!quick_link?.id ? handleDeleteClick : null}
        >
            <div style={styles.selectFields}>
                <GroupSelect 
                    name='cluster'
                    labelText='Choose a Cluster name'
                    defaultValue={quick_link?.cluster ?? 'N/A'}
                    ref={register({ required: true })}
                />
            </div>
            <div style={styles.textFields}>
                <TextField
                    name='name'
                    label='Link Name'
                    fullWidth
                    error={!!errors.name}
                    defaultValue={quick_link.name}
                    inputRef={ register({ required: 'a Quick Link Name is required!' }) }
                    style={styles.textInput}
                />
                <TextField
                    name='text'
                    label='Button Text'
                    fullWidth
                    error={!!errors.text}
                    defaultValue={quick_link.text}
                    inputRef={ register({ required: 'Button Text is required!' }) }
                    style={styles.textInput}
                />
                <FormControlLabel
                    style={styles.checkInput}
                    control={
                        <Checkbox
                            checked={showGroupSelect}
                            onChange={() => setShowGroups(!showGroupSelect)}
                            name='show-group'
                            color='primary'
                        />
                    }
                    label='Use Offer Group?'
                />
                {!showGroupSelect && <FormControlLabel
                    style={styles.checkInput}
                    control={
                        <Checkbox
                            checked={isGoogle}
                            onChange={() => setIsGoogle(!isGoogle)}
                            name='show-group'
                            color='primary'
                        />
                    }
                    label='Show as System1 Link?'
                />}
                {showGroupSelect ?
                    <OfferGroupSelect 
                        activeEndpointGroup={activeEndpointGroup}
                        setActiveGroup={setActiveGroup}
                        setGroupName={setGroupName}
                    />
                    :
                    <><TextField
                        name='url'
                        label='Link URL'
                        fullWidth
                        error={!!errors.url}
                        defaultValue={quick_link.url}
                        inputRef={ register({ required: 'an Offer Link URL is required!' }) }
                        style={styles.textInput}
                    />
                    <TextField
                        name='jump'
                        label='Jump Behind'
                        fullWidth
                        defaultValue={quick_link.jump}
                        inputRef={ register() }
                        style={styles.textInput}
                    /></>
                }
            </div>
        </DialogBase>
    );
}

export default QuickLinkDialog;