import React from 'react';
import styles from './CustomColumns.css.js';
import { capitalizeValue } from '../../../utils/helpers';

const OptInColumns = () => ([
	{ 
		title: 'Collection', 
        field: 'type',
        defaultGroupOrder: 0,
		defaultGroupSort: 'asc',
		cellStyle: styles.collectionCell,
		render: rowData => <span style={styles.clusterText}>{capitalizeValue(rowData)}</span>
	},
	{ 
		title: 'Group Name', 
        field: 'name',
        cellStyle: {...styles.titleCell, width: '40%'}, 
		render: rowData => capitalizeValue(rowData.name) 
	},
	{ 
		title: 'Group Offer Count', 
        field: 'group_items',
        cellStyle: {...styles.tableCell, width: '40%'}, 
		render: rowData => <span>{`Offer Count: (${rowData.group_items.length})`}</span>
	},
]);

export default OptInColumns;