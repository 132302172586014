import React, { useContext } from 'react';
import { AppContext } from '../../../../context';
import styles from './Endpoints.css.js';
import CategoryTabPanel from '../CategoryTabPanel';
import Offers from './Offers';
import OfferGroups from './OfferGroups';

const Endpoints = () => {
	const { appState } = useContext(AppContext);
	const tabValue = appState.subcategoryTabValue;

	return (
		<div style={styles.pageContainer}>
			<CategoryTabPanel value={tabValue} idx={0}>
				<Offers />
			</CategoryTabPanel>
			<CategoryTabPanel value={tabValue} idx={1}>
				<OfferGroups />
			</CategoryTabPanel>
		</div>
	);
}

export default Endpoints;