import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import styles from './ArticleControl.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListItem from '@material-ui/core/ListItem';
import AccordionItem from '../../AccordionItem';
import BlockExample from './BlockExample';

const OfferBlockItem = ({ item, index }) => {
    const { dispatchSelection } = useContext(AppContext);

    const handleEditClick = (e) => {
        e.stopPropagation();
        dispatchSelection({ 
            type: 'SET_OFFER_BLOCK', 
            payload: item 
        });
        dispatchSelection({ type: 'TOGGLE_OFFER_BLOCK_DIALOG' });
    };

    const showDelete = () => {
        dispatchSelection({ 
            type: 'DELETE_SELECTION', 
            payload: { 
                type: 'offer_block', 
                id: item.id,
                name: item.name
            }
        });
    };

    const OfferBlockSummary = () => (
        <ListItem dense style={styles.summaryItem}>                    
            <div style={styles.summaryItemTitle}>
                {item.name.toUpperCase()}
            </div>
            <FontAwesomeIcon 
                icon={['fal', 'pen']} 
                size='1x' 
                style={styles.editIcon}
                onClick={handleEditClick}
            />
            <FontAwesomeIcon 
                icon={['fal', 'trash-alt']} 
                size='1x' 
                style={{...styles.editIcon, color: 'red'}}
                onClick={showDelete}
            />
        </ListItem>
    );

    return (
        <div style={styles.fullListItem}>
            <AccordionItem 
				idx={index}
                rowName={`offer-block-item__${index}`}
				expandStyle='expanded' 
				Summary={(props) => <OfferBlockSummary {...props}/>}
				content={
                    <div style={styles.offerExample}>
                        <BlockExample item={item} />
                    </div>
                }
			/>
        </div>
    );
};

export default OfferBlockItem;