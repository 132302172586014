import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { useToasts } from 'react-toast-notifications';
import { useMutation } from '@apollo/client';
import { DELETE_PROGRAM_PATH } from '../../../graphql/mutations';
import { PROGRAM_TABLE } from '../../../graphql/queries';
import styles from './DeleteDialogs.css.js';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const DeleteProgramPath = () => {
    const { addToast } = useToasts();
    const { selectionState, dispatchSelection } = useContext(AppContext);
    const { delete_doc } = selectionState;
    const [ isSaving, setIsSaving ] = useState(false);
    const [ isHovering, setHovering ] = useState(false);

    const closeDialog = () => {
        dispatchSelection({ type: 'CLOSE_DELETE' });
    };

    const handleRedirect = (data) => {
        addToast(data.deleteProgramPath.message, { appearance: 'success', autoDismiss: true });
        setIsSaving(false);
        closeDialog();
    };
    const handleError = (error) => {
        console.error(error);
        addToast(error?.message ?? 'Error!', { appearance: 'error', autoDismiss: true });
        setIsSaving(false);
        closeDialog();
    };

    const [deleteProgramPath] = useMutation(
        DELETE_PROGRAM_PATH, {
            refetchQueries: [{ 
                query: PROGRAM_TABLE,
                variables: {
                    pid: delete_doc.domain.pid,
                    vertical: delete_doc.vertical
                }
            }],
            onCompleted: handleRedirect,
            onError: handleError,
        }
    );

    const handleConfirmClick = () => {
        setIsSaving(true);
        deleteProgramPath({ variables: { id: delete_doc.item_id } });
    };

    const buttonStyle = Object.assign({},
        styles.submitButton,
        isHovering && styles.deleteHover
    );

    return (
        <div style={styles.deleteDialogContent}>
            <div style={styles.textContainer}>
                <span style={styles.importantText}>This action is permanant!</span>
                <span style={styles.dialogText}>Are you sure you want to remove this</span>
                <span style={styles.dialogText}>
                    Program Path from the
                    <span style={styles.boldText}>{`${delete_doc?.domain?.title} `}</span>
                    domain?
                </span>
            </div>
            <ButtonGroup style={styles.formButtons}>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button 
                    onClick={handleConfirmClick} 
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                    style={buttonStyle}
                >
                    {isSaving ? <CircularProgress size={24} style={styles.buttonProgress} /> : 'BOOM!'}
                </Button>
            </ButtonGroup>
        </div>
    );
}

export default DeleteProgramPath;