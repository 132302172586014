import React, { useEffect, useState } from 'react';
import styles from './Reports.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import axios from 'axios';
import SubIdReportTable from './Tables/SubIdReportTable';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

const SubIdReport = () => {
    const [ reportData, setReportData ] = useState(null);
    const [ hovering, setHovering ] = useState(false);
    const [ date, setDate ] = useState(dayjs());

    const fetchReport = async(dateString) => {
        const fetchUrl = `https://f8fjn5bgw2.execute-api.us-east-1.amazonaws.com/prod/system1/subid/${dateString}`;
        // const fetchUrl = `http://localhost:4000/dev/system1/subid/${dateString}`;
        const response = await axios({
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            url: fetchUrl
        });
        setReportData(response.data);
    };

    const lengthOfNumber = (num) => {
        return num.toString().length;
    };

    const formatDateString = (dateObj) => {
        const year = dateObj.year();
        let month = dateObj.month() + 1;
        if(lengthOfNumber(month) === 1) { month = `0${month}`};
        let day = dateObj.date();
        if(lengthOfNumber(day) === 1) { day = `0${day}`};
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        if(!reportData) {
            const reportDate = dayjs(date);
            const formattedDate = formatDateString(reportDate);
            fetchReport(formattedDate);
        };
        // eslint-disable-next-line
    },[reportData]);

    const handleDateChange = (newValue) => {
        if(newValue !== date) setReportData(null);
        setDate(newValue);
        const formattedDate = formatDateString(newValue);
        fetchReport(formattedDate);
    };

    const handleDownloadClick = () => {
        const titleKeys = Object.keys(reportData[0]);
        const refinedData = [];
        refinedData.push(titleKeys);
        reportData.forEach(item => {
            refinedData.push(Object.values(item))  
        });
        let csvContent = '';
        refinedData.forEach(row => {
            csvContent += row.join(',') + '\n'
        });
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
        const objUrl = URL.createObjectURL(blob);
        const reportDate = dayjs(date);
        const formattedDate = formatDateString(reportDate);
        let linkElement = document.createElement('a');
        linkElement.setAttribute('href', objUrl);
        linkElement.setAttribute('download', `sub_id_report_${formattedDate}`);
        linkElement.click();
    };

    const downloadStyle = Object.assign({},
        styles.downloadButton,
        hovering && styles.hover
    );

    return(
        <div style={styles.pageContainer}>
            <div style={styles.datePickerContainer}>
                <div style={styles.datePicker}>
                    <DatePicker
                        label="Report Date"
                        value={date}
                        onChange={handleDateChange}
                    />  
                </div>
                <Tooltip 
                    title='Download CSV' 
                    placement='top-start' 
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                >
                    <Fab 
                        style={downloadStyle}
                        aria-label='download' 
                        onClick={handleDownloadClick}
                        variant='extended'
                        onMouseEnter={() => setHovering(true)}
                        onMouseLeave={() => setHovering(false)}
                        disabled={!reportData}
                    >
                        <FontAwesomeIcon style={styles.downloadIcon} icon={['fal', 'download']} />
                    </Fab>
                </Tooltip>
            </div>
            <div style={styles.dataTableContainer}>
                <SubIdReportTable data={reportData} />
            </div>
        </div>
    )
};

export default SubIdReport;