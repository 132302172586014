import React from 'react';
import Loading from '../../../Loading';
import styles from './PathItemOfferList.css.js';
import MaterialTable from 'material-table';
import { useQuery } from '@apollo/client';
import { GROUP_ENDPOINTS } from '../../../../graphql/queries';
import ActiveOffersColumns from '../../../Tables/TableColumns/ActiveOffersColumns';
import TableIcons from '../../../Tables/TableIcons';
import EmptyList from '../../../EmptyList';
import Paper from '@material-ui/core/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LinkListCountDisplay from './LinkListCountDisplay';

const PathItemOfferList = ({ program, range }) => {
    const { loading, error, data } = useQuery(
        GROUP_ENDPOINTS, { variables: { group_id: program.endpoint_group } }
    );
    
    if (loading) return <div style={styles.loadingContainer}><Loading /></div>;
    
    if (error) return <div>{`Error occured: ${error.message}`}</div>;
    
    const endpoints = data.fetchGroupEndpoints.body;

    const ExpandButton = ({ open }) => (
        <FontAwesomeIcon
            icon={['fal', `${open ? 'times' : 'chevron-right'}`]}
            style={styles.toggleIcon}
        />
    );

    const OfferDetailPanel = () => ([
        {
            icon: () => (<ExpandButton open={false} />),
            openIcon: () => (<ExpandButton open={true} />),
            tooltip: 'Show Offers',
            render: rowData => (
                <div style={styles.detailsContainer}>
                    {rowData.offer_page === 'selection' && 
                        <LinkListCountDisplay 
                            offerId={rowData.id} 
                            linkList={rowData.selection_links} 
                            program={program} 
                            rowData={rowData}
                            range={range}
                        />}
                </div>
            ),
        },
    ]);

    return (
        <Paper style={styles.tablePaper} elevation={10}>
            {endpoints.length > 0 ? (
                <MaterialTable
                    icons={TableIcons}
                    columns={ActiveOffersColumns(program, range)}
                    data={endpoints.map(offer => ({
                        id: offer?.id,
                        name: offer?.name,
                        url: offer?.url,
                        jump: offer?.jump,
                        offer_page: offer?.offer_page,
                        four_button: offer?.four_button,
                        restricted: offer?.restricted,
                        states: offer?.states,
                        offers: offer?.offers,
                        quick_link_group: offer?.quick_link_group,
                        selection_links: offer?.selection_links?.map((link) => ({
                            id: link?.id,
                            cluster: link?.cluster,
                            group: link?.group,
                            name: link?.name,
                            text: link?.text,
                            url: link?.url,
                            jump: link?.jump
                        })) ?? [],
                        usage: offer?.usage
                    }))}
                    options={{
                        search: false,
                        showTitle: false,
                        rowStyle: styles.rowStyle,
                        toolbar: false,
                        paging: false,
                        headerStyle: styles.offersHeader,
                    }}
                    style={styles.offersTable}
                    detailPanel={OfferDetailPanel()}
                    onRowClick={(_, __, togglePanel) => {togglePanel()}}
                />
            ) : (<EmptyList />)}
        </Paper>
    );
}


export default PathItemOfferList;