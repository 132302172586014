import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import OfferSelectionList from '../OfferGroupDialog/OfferSelectionList';
import TextField from '@mui/material/TextField';
import { useMutation } from '@apollo/client';
import { SAVE_PROGRAM_RECORD } from '../../../graphql/mutations';
import { RECORDS_BY_KEY } from '../../../graphql/queries';
import { flattenString, titleCase } from '../../../utils/helpers';
// import styles from './ProgramRecordDialog.css.js';
import DialogBase from '../DialogBase/DialogBase';
import styles from './ProgramRecordDialog.css.js';
// import InputLabel from '@mui/material/InputLabel';
// import MediaSwitches from './MediaSwitches';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import Paper from '@mui/material/Paper';

const ProgramRecordDialog = ({ open, close }) => {
    const { addToast } = useToasts();
    const { selectionState, dispatchSelection } = useContext(AppContext);
    const { program_record, program_domain } = selectionState;
    const [ isSaving, setIsSaving ] = useState(false);
    const [ offerSelection, setOfferSelection ] = useState(program_record?.endpoint_id ?? []);
    const { register, handleSubmit } = useForm({ mode: 'onChange', reValidateMode: 'onChange', });
    
    const handleRedirect = (data) => {
        addToast(data.saveProgramRecord.message, { appearance: 'success', autoDismiss: true });
        setIsSaving(false);
        close();
    };
    const handleError = (error) => {
        console.error(error);
        addToast(error?.message ?? 'Error!', { appearance: 'error', autoDismiss: true });
        setIsSaving(false);
        close();
    };

    const [saveProgramRecord] = useMutation(
        SAVE_PROGRAM_RECORD, {
            refetchQueries: [{ 
                query: RECORDS_BY_KEY,
                variables: { domain_key: program_domain.domain_key }
            }],
            onCompleted: handleRedirect,
            onError: handleError,
        }
    );

    const scrollToSave = () => {
        const saveButton = document.getElementById('submit_button');
        if(!!saveButton) {
            saveButton.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
        }
    };

    const handleSelectionClick = (e, rowData) => {
        e.stopPropagation();
        setOfferSelection([rowData.id]);
        scrollToSave();
    };

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        dispatchSelection({ 
            type: 'DELETE_SELECTION', 
            payload: { 
                type: 'program_record', 
                item_id: program_record.id,
                domain_key: program_domain.domain_key,
                title: program_record.record_name
            } 
        });
    };

    const handleSubmission = (formData) => {
        setIsSaving(true);
        const { record_name } = formData;
        let formNew = {
            record_name: flattenString(record_name),
            domain_key: program_domain.domain_key,
            pid: program_domain.pid,
            endpoint_id: offerSelection[0]
        };
        const fullForm = Object.assign({}, 
            formNew,
            !!program_record?.id && { id: program_record.id }
        );
        saveProgramRecord({ variables: { record: fullForm } });
    };

    return (
        <DialogBase
            open={open}
            close={close}
            title={!!program_record?.id ? 'Edit Program Record:' : 'Add New Program Record:'}
            isSaving={isSaving}
            handleSubmission={handleSubmit(handleSubmission)}
            handleDelete={!!program_record?.id ? handleDeleteClick : null}
        >
             <div style={styles.textFields}>
                <TextField
                    name='record_name'
                    label='Record Name(must be unique)'
                    fullWidth
                    autoFocus
                    defaultValue={titleCase(program_record.record_name, true)}
                    inputRef={ register({ required: 'a Record Name is required!' }) }
                    style={styles.textInput}
                />
            </div>
            <div style={styles.offerList}>
                <OfferSelectionList 
                    handleSelection={handleSelectionClick} 
                    activeIds={offerSelection}
                />
            </div>
        </DialogBase>
    );
}

export default ProgramRecordDialog;