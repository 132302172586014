import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GROUP_COUNT } from '../../graphql/queries';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './GroupCount.css.js';

const GroupCount = ({ id, endpoint_group, range }) => {

    const { loading, error, data, refetch } = useQuery(
        GROUP_COUNT,
        {
            variables: {
                service: {
                    program_id: id,
                    group_id: endpoint_group,
                    range: range
                }
            },
            skip: !id || !endpoint_group || !range,
            // pollInterval: 25000
        }
    );

    useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, [range]);

    if (error) {
        return (
            <div style={styles.error}>
                {`Error occurred fetching program group count...${error.message}`}
            </div>
        )
    };

    if (loading) return <CircularProgress size={30} thickness={4.5}/>;

    return (
        <div style={styles.count}>
            <div style={styles.countText}>{data.fetchGroupCount.body}</div>
        </div>
    )
}

export default GroupCount;