import React from 'react';
import styles from './ActionBar.css.js';
import ActionButton from '../ActionButton';

const ActionBar = ({ text, title, handleClick, size, position='right', isEdit=false }) => {
    const barStyle = Object.assign({},
        styles[`actionBarContent${size}`],
        styles[`${position}Title`],
        !!handleClick && styles.withButton
    );
    return (
        <div style={styles[`actionBar${size}`]}>
            <div style={barStyle}>
                <div style={styles.titleText}>
                    {`${text} `} <span style={styles.boldText}>{title}</span>
                </div>
            </div>
            {!!handleClick && (
                <ActionButton 
                    icon={isEdit ? 'pen' : 'plus'}
                    bg='lightGrey'
                    bgHover='green'
                    handleClick={handleClick}
                />
            )}
        </div>
    );
}

export default ActionBar;