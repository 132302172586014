import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import styles from './ArticleControl.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListItem from '@material-ui/core/ListItem';
import AccordionItem from '../../AccordionItem';
import ArticleDetails from './ArticleDetails';
import Chip from '@mui/material/Chip';

const ArticleListItem = ({ item, index }) => {
    const { dispatchSelection } = useContext(AppContext);

    const handleEditClick = (e) => {
        e.stopPropagation();
        dispatchSelection({ 
            type: 'SET_ACTIVE_DATA', 
            payload: {
                group: null,
                article: item
            } 
        });
        dispatchSelection({ type: 'TOGGLE_ARTICLE_DIALOG' });
    };

    const showDelete = () => {
        dispatchSelection({ 
            type: 'DELETE_SELECTION', 
            payload: { 
                type: 'article', 
                id: item.id,
                title: item.title
            } 
        });
    };

    const InfoChip = ({label, color, handleClick=null}) => (
        <div style={styles.chip}>
            <Chip
                label={label}
                variant='outlined'
                color={color}
                size='small'
                clickable={!!handleClick}
                onClick={handleClick}
            />
        </div>
    );

    const handleLinkClick = (e) => {
        e.stopPropagation();
        window.open(item.cci_link, '_blank', 'noreferrer');
    };

    const ArticleItemSummary = () => (
        <ListItem dense style={styles.summaryItem}>                    
            <div style={styles.summaryItemTitle}>
                {item.title.toUpperCase()}
            </div>
            <div style={styles.articleKey}>
                {`?article=${item.key}`}
            </div>
            <div style={styles.linkDiv}>
                {!!item.rsoc_active && !item.rsoc_desktop && (
                    <InfoChip 
                        label='RSOC'
                        color='info'
                    />
                )}
                {!!item.rsoc_desktop && (
                    <InfoChip 
                        label='RSOC-Desktop'
                        color='info'
                    />
                )}
                {!!item?.cci_link && (
                    <InfoChip 
                        label='CCI Link'
                        color='warning'
                        handleClick={handleLinkClick}
                    />
                )}
                {!!item?.offer_block && (
                    <InfoChip 
                        label='Offer Block'
                        color='secondary'
                    />
                )}
            </div>
            
            <FontAwesomeIcon 
                icon={['fal', 'pen']} 
                size='1x' 
                style={styles.editIcon}
                onClick={handleEditClick}
            />
            <FontAwesomeIcon 
                icon={['fal', 'trash-alt']} 
                size='1x' 
                style={{...styles.editIcon, color: 'red'}}
                onClick={showDelete}
            />
        </ListItem>
    );

    return (
        <div style={styles.fullListItem}>
            <AccordionItem 
				idx={index}
                rowName={`article-item__${index}`}
				expandStyle='expanded' 
				Summary={(props) => <ArticleItemSummary {...props}/>}
				content={<ArticleDetails item={item}/>}
			/>
        </div>
    );
};

export default ArticleListItem;