import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { useForm } from "react-hook-form";
import { useToasts } from 'react-toast-notifications';
import TextField from '@material-ui/core/TextField';
import { useMutation } from '@apollo/client';
import { SAVE_OFFER_BLOCK } from '../../../graphql/mutations';
import { OFFER_BLOCKS } from '../../../graphql/queries.js';
import styles from './OfferBlockDialog.css.js';
import DialogBase from '../DialogBase';
import OfferSelection from './OfferSelection';

const OfferBlockDialog = ({ open, close }) => {
    const { addToast } = useToasts();
    const { selectionState, dispatchSelection } = useContext(AppContext);
    const { offer_block } = selectionState;
    // this one is for offer options
    const [ offerList, setOfferSelection ] = useState(null);
    // this one is for offer selection
    const [ blockState, setBlockState ] = useState({ offers: [] });
    const [ isSaving, setIsSaving ] = useState(false);
    const { register, handleSubmit } = useForm({ mode: 'onChange', reValidateMode: 'onChange', });
    
    const handleRedirect = (data) => {
        addToast(data.saveOfferBlock.message, { appearance: 'success', autoDismiss: true });
        setIsSaving(false);
        close();
    };
    const handleError = (error) => {
        console.error(error);
        addToast(`SAVE Offer Block: FAIL. ${error.message}`, { appearance: 'error', autoDismiss: true });
        setIsSaving(false);
        close();
    };

    const [saveOfferBlock] = useMutation(
        SAVE_OFFER_BLOCK, {
        refetchQueries: [{ query: OFFER_BLOCKS }],
        onCompleted: handleRedirect,
        onError: handleError
    });

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        dispatchSelection({ 
            type: 'DELETE_SELECTION', 
            payload: { 
                type: 'offer_block', 
                id: offer_block.id,
                name: offer_block.name
            }
        });
    };

    const handleSubmission = (formData) => {
        setIsSaving(true);
        const activeOfferIds = blockState.offers.map((offer) => (offer.id));
        let formNew = {
            name: formData.name,
            offer_ids: activeOfferIds
        };
        const fullForm = Object.assign({}, 
            formNew,
            !!offer_block?.id && { id: offer_block.id }
        );
        saveOfferBlock({ variables: { offerBlock: fullForm } });
    };

    return (
        <DialogBase
            open={open}
            close={close}
            title={!!offer_block?.id ? 'Edit Offer Block:' : 'Add New Offer Block:'}
            isSaving={isSaving}
            handleSubmission={handleSubmit(handleSubmission)}
            handleDelete={!!offer_block?.id ? handleDeleteClick : null}
            style={{margin: '10px 0 10px 0'}}
            bg='white'
        >
            <form style={styles.mainForm}>
                <TextField
                    name='name'
                    label='Friendly Name'
                    fullWidth
                    autoFocus
                    defaultValue={offer_block?.name ?? ''}
                    inputRef={register()}
                    style={styles.textInput}
                />
                <OfferSelection 
                    offer_block={offer_block}
                    offerList={offerList}
                    setOfferSelection={setOfferSelection}
                    blockState={blockState}
                    setBlockState={setBlockState}
                />
            </form>
        </DialogBase>
    );
}

export default OfferBlockDialog;