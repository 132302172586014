import React, { useState } from 'react';
import styles from './DomainItem.css.js';
import Box from '@material-ui/core/Box';
import DomainVertical from '../DomainVertical';
import VerticalsTabs from '../VerticalsTabs';

const TabPanel = ({ domain, value, index, vertical,...other }) => (
	<div
		role='tabpanel'
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}
	>
		{value === index && (
			<Box p={3} style={styles.verticalContent}>
				<DomainVertical domain={domain} vertical={vertical} />
			</Box>
		)}
	</div>
);

const DomainItemContent = ({ programDomain }) => {
	const [ tabValue, setValue ] = useState(0);

	const tabVerticals = [ 'auto_loans', 'credit_cards', 'home_loans', 'personal_loans', 'direct' ];

	const handleChange = (event, newValue) => {
		event.preventDefault();
		setValue(newValue);
	};

	return (
		<div style={styles.programContent}>
            <VerticalsTabs value={tabValue} handleSelection={handleChange}/>
            {tabVerticals.map((value, idx) => (
				<TabPanel
					key={`domain-vertical-tab__${idx}`} 
                    value={tabValue} 
                    index={idx} 
                    domain={programDomain} 
                    vertical={value}
                />
            ))}
		</div>
	);
}

export default DomainItemContent;