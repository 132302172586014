import React from 'react';
import EmptyList from '../EmptyList';
import Loading from '../Loading';
import { useQuery } from '@apollo/client';
import { GROUP_OPTINS, GROUP_ENDPOINTS } from '../../graphql/queries';
import GroupColumns from '../Tables/TableColumns/GroupColumns';
import MaterialTable from 'material-table';
import TableIcons from '../Tables/TableIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Tables.css.js';
import Paper from '@material-ui/core/Paper';

const GroupDetailsTable = ({ group, type }) => {
    const { loading, error, data } = useQuery(type === 'optin' ? GROUP_OPTINS : GROUP_ENDPOINTS, 
            {variables: type === 'optin' ? 
                { group_items: group.group_items } :
                { group_id: group.id }
            }
        );

    if (loading) return <div style={styles.loadingTable}><Loading /></div>;

    if (error) return <div>{`Error occured: ${error.message}`}</div>;

    const groupArray = data[`${type === 'optin' ? 'fetchGroupOptins' : 'fetchGroupEndpoints'}`].body;

    const rows = groupArray.map((optinGroup, idx) => {
        let rowObject = {
            ...optinGroup,
            usage: group.usage[idx]
        };
        return rowObject;
    });

    return (
        <Paper style={styles.detailPaper} elevation={5}>
            {rows.length > 0 ?
                <MaterialTable
                    icons={TableIcons}
                    columns={GroupColumns()}
                    data={type === 'optin' ? mapOptins(rows) : mapEndpoints(rows)}
                    options={{
                        search: false,
                        showTitle: false,
                        rowStyle: styles.rowStyle,
                        toolbar: false,
                        paging: false,
                        headerStyle: styles.header,
                        sorting: false,
                    }}
                    style={styles.table}
                /> :
                <div style={styles.loadingTable}><EmptyList /></div>
            }
        </Paper>
    );
};

const ExpandButton = ({ open }) => (
    <div style={styles.expandCell}>
        <FontAwesomeIcon
            icon={['fal', `${open ? 'times' : 'chevron-right'}`]}
            style={styles.toggleIcon}
        />
    </div>
);

const GroupDetails = (type) => ([
    {
        icon: () => (<ExpandButton open={false} />),
        openIcon: () => (<ExpandButton open={true} />),
        tooltip: 'Show Group Offers',
        render: rowData => (<GroupDetailsTable group={rowData} type={type}/>),
    },
]);

export default GroupDetails;

const mapOptins = (rows) => (rows.map(optin => ({
    id: optin.id,
    type: optin.type,
    name: optin.title,
    description: optin.description,
    link: optin.link,
    jump: optin.jump,
    offer_page: optin.offer_page,
    four_button: optin.four_button,
    offers: optin.offers,
    restricted: optin.restricted,
    states: optin.states,
    usage: optin.usage
})
)
);
const mapEndpoints = (rows) => (rows.map(endpoint => ({
    id: endpoint.id,
    name: endpoint.name,
    offer_page: endpoint.offer_page,
    link: endpoint.url,
    jump: endpoint.jump,
    four_button: endpoint.four_button,
    offers: endpoint.offers,
    optin: endpoint.optin,
    restricted: endpoint.restricted,
    states: endpoint.states,
    selection_links: endpoint.selection_links && endpoint.selection_links.map((link) => ({
        id: link.id,
        name: link.name,
        text: link.text,
        url: link.url,
        jump: link.jump
    })),
    quick_link_group: endpoint.quick_link_group,
    usage: endpoint.usage
})
)
);