import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import TextField from '@mui/material/TextField';
import { useMutation } from '@apollo/client';
import { SAVE_DIRECT_DOMAIN } from '../../../graphql/mutations';
import { DIRECT_DOMAINS } from '../../../graphql/queries';
import { buildAcceptedTypes } from '../../../utils/helpers';
import styles from './DirectDomainDialog.css.js';
import DialogBase from '../DialogBase/DialogBase';
import InputLabel from '@mui/material/InputLabel';
import MediaSwitches from './MediaSwitches';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Paper from '@mui/material/Paper';

const LittleIcon = ({icon, color, handleClick}) => {
    const [ isHovering, setHovering ] = useState(false);
    
    const iconStyle = Object.assign({},
        styles.litteIcon,
        {color: color},
        isHovering && styles.iconHover
    );

    return(
        <FontAwesomeIcon
            icon={['fal', icon]}
            size='1x'
            style={iconStyle}
            onClick={handleClick}
            onMouseEnter={() => setHovering(true)}
			onMouseLeave={() => setHovering(false)}
        />
    );
};

const DirectDomainDialog = ({ open, close }) => {
    const { addToast } = useToasts();
    const { selectionState, dispatchSelection } = useContext(AppContext);
    const { program_domain } = selectionState;
    const [ isSaving, setIsSaving ] = useState(false);
    const blankPid = { pid: '' };
    const [ pidState, setPidState ] = useState(program_domain?.pid?.length > 0 ? program_domain.pid.map(val => ({ pid: val })) : [{ ...blankPid }]);
    const activeSwitches = program_domain?.accepts?.map(item => (item.value)) ?? [];
    const [ mediaState, setMediaState ] = useState({
        bing: activeSwitches.includes('bing'),
        google: activeSwitches.includes('google'),
        social: activeSwitches.includes('social'),
        native: activeSwitches.includes('native'),
        email: activeSwitches.includes('email'),
        sms: activeSwitches.includes('sms'),
        push: activeSwitches.includes('push'),
        known: activeSwitches.includes('known'),
        unknown: activeSwitches.includes('unknown')
    });
    const { register, errors, handleSubmit } = useForm({ mode: 'onChange', reValidateMode: 'onChange', });
    
    const handleRedirect = (data) => {
        addToast(data.saveDirectDomain.message, { appearance: 'success', autoDismiss: true });
        setIsSaving(false);
        close();
    };
    const handleError = (error) => {
        console.error(error);
        addToast(error?.message ?? 'Error!', { appearance: 'error', autoDismiss: true });
        setIsSaving(false);
        close();
    };

    const [saveDirectDomain] = useMutation(
        SAVE_DIRECT_DOMAIN, {
            refetchQueries: [{ 
                query: DIRECT_DOMAINS
            }],
            onCompleted: handleRedirect,
            onError: handleError,
        }
    );

    const handleAddInputClick = () => {
        setPidState([...pidState, { ...blankPid }])
    };

    const handleRemoveInputClick = (idx) => {
        let newState = pidState.filter((el, i) => i !== idx);
        setPidState(newState);
    };

    const handlePidChange = (e) => {
        const updatedPids = [...pidState];
        updatedPids[Number(e.target.id)]['pid'] = e.target.value;
        setPidState(updatedPids);
    };

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        dispatchSelection({ 
            type: 'DELETE_SELECTION', 
            payload: { 
                type: 'direct_domain', 
                item_id: program_domain.id,
                domain: program_domain.domain_key,
                title: program_domain.title
            } 
        });
    };

    const handleSubmission = (formData) => {
        setIsSaving(true);
        const { title, domain_key, description, avatar } = formData;
        const pidValuesRaw = pidState.map(input => ( Number(input.pid)));
        const pidValues = pidValuesRaw.filter((item) => (item !== 0));
        let formNew = {
            title,
            pid: pidValues,
            domain_key: domain_key.toLowerCase(),
            description: description,
            avatar: avatar || 'N/A',
            accepts: buildAcceptedTypes(mediaState)
        };
        const fullForm = Object.assign({}, 
            formNew,
            !!program_domain?.id && { id: program_domain.id }
        );
        saveDirectDomain({ variables: { domain: fullForm } });
    };

    return (
        <DialogBase
            open={open}
            close={close}
            title={!!program_domain?.id ? 'Edit Direct Domain:' : 'Add New Direct Domain:'}
            isSaving={isSaving}
            handleSubmission={handleSubmit(handleSubmission)}
            handleDelete={!!program_domain?.id ? handleDeleteClick : null}
        >
            <div style={styles.mainRow}>
                <div style={styles.textFields}>
                    <MediaSwitches mediaState={mediaState} setMediaState={setMediaState} />
                    <TextField
                        name='title'
                        label='Program Title'
                        fullWidth
                        autoFocus
                        error={errors.name}
                        defaultValue={program_domain.title}
                        inputRef={ register({ required: 'a Program Title is required!' }) }
                        style={styles.textInput}
                    />
                    <TextField
                        name='domain_key'
                        label='Domain Key(must be unique)'
                        fullWidth
                        error={errors.domain}
                        defaultValue={program_domain.domain_key}
                        inputRef={register({ required: 'a Domain Key is required! ' })}
                        style={styles.textInput}
                    />
                    <TextField
                        name='description'
                        label='Program Description'
                        fullWidth
                        error={errors.description}
                        defaultValue={program_domain.description}
                        inputRef={register({ required: 'a short Description of the Domain is required!' })}
                        style={styles.textInput}
                    />
                    <TextField
                        name='avatar'
                        label='Program Icon SRC'
                        fullWidth
                        defaultValue={program_domain.avatar}
                        inputRef={register()}
                        style={styles.textInput}
                    />
                </div>
                <Paper style={styles.pidPaper} elevation={10}>
                    <div style={styles.pidAssignment}>
                        <div style={styles.pidAssignmentTop}>
                            <InputLabel style={styles.inputLabel}>PID values</InputLabel>
                            <LittleIcon 
                                icon='plus-circle' 
                                color='rgba(15,64,107,0.92)' 
                                handleClick={handleAddInputClick}
                            />
                        </div>
                        {pidState.map((val, idx) => (
                            <div key={`pid-input__${idx}`} style={styles.pidRow}>
                                <TextField
                                    id={`${idx}`}
                                    name={`pid-${idx}`}
                                    className='pid'
                                    fullWidth        
                                    required={true}
                                    value={pidState[idx].pid}
                                    onChange={handlePidChange}
                                    style={styles.pidInput}
                                    size='small'
                                />
                                {idx !== 0 && 
                                    <LittleIcon 
                                        icon='minus-circle' 
                                        color='red' 
                                        handleClick={() => handleRemoveInputClick(idx)}
                                    />
                                }
                            </div>
                        ))}
                    </div>
                </Paper>
            </div>
        </DialogBase>
    );
}

export default DirectDomainDialog;