import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import styles from './EndpointDialog.css.js';

const SelectPageType = ({ page, setPage }) => {
    const handlePageChange = (e) => {
        e.preventDefault();
        setPage(e.target.value);
    };

    return (
        <div style={styles.selectionRow}>
            <FormControl style={styles.selection}>
                <InputLabel id='select_page-label' >Page Type</InputLabel>
                <Select
                    labelId='select_page-label'
                    id='offer_page-input'
                    name='offer_type'
                    value={page}
                    onChange={handlePageChange}
                >
                    {offerPageTypeOptions.map((page, idx) => (
                        <MenuItem key={`page-type__menu-item-${idx}`} value={page.value}>{page.text}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    )
};

export default SelectPageType;

const offerPageTypeOptions = [
    {
        key: '1',
        text: 'mNet',
        value: 'mNet'
    },
    {
        key: '2',
        text: 'One Button',
        value: 'one_button'
    },
    {
        key: '3',
        text: 'Four Button',
        value: 'four_button'
    },
    {
        key: '4',
        text: 'Direct Link',
        value: 'direct_link'
    },
    {
        key: '5',
        text: 'User Selection',
        value: 'selection'
    }
];