import React from 'react';
import RenderPids from './RenderPids';
import ControlButtons from './ControlButtons';
import styles from './Steps.css.js';
import { capitalizeValue } from '../../../../../utils/helpers';

const VerifyDomain = ({selection, close, moveToNext}) => (
	<div style={styles.stepContentContainer}>
		<div style={styles.stepContentTitle}>Verify Program Domain:</div>
		<table style={styles.stepContentTable}>
			<tbody>
				<tr style={styles.stepContentTableRow}>
					<td style={styles.stepContentTableKey}>Domain: </td>
					<th style={styles.stepContentTableValue}>{selection?.program_domain?.domain ?? 'N/A'}</th>
				</tr>
				<tr style={styles.stepContentTableRow}>
					<td style={styles.stepContentTableKey}>PID Values: </td>
					<th style={styles.stepContentTableValue}><RenderPids pids={selection?.program_domain?.pid ?? []}/></th>
				</tr>
				<tr style={styles.stepContentTableRow}>
					<td style={styles.stepContentTableKey}>Vertical: </td>
					<th style={styles.stepContentTableValue}>{capitalizeValue(selection?.vars?.vertical ?? 'N/A')}</th>
				</tr>
			</tbody>
		</table>
		<ControlButtons close={close} handleNextClick={moveToNext} />
	</div>
);

export default VerifyDomain;