const styles = {
    detailsContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'aliceblue',
    },
    rowStyle: {
        fontSize: '1.1rem',
    },
    tooltipContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    buttonText: {
        margin: '10px',
        lineHeight: '1.25'
    },
    indexText: {
        fontSize: '1.5rem',
        fontWeight: '300'
    },
    valueText: {
        fontSize: '1.5rem',
        textDecoration: 'underline'
    },
    eyeContainer: {
        // paddingLeft: '10px'
    },
    cellIcon: {
        fontSize: '1.3rem',
        color: 'rgba(0,0,0,0.75)'
    },
    emptyCellIcon: {
        color: 'rgba(0, 0, 0, 0.15)',
        fontSize: '1.5rem',
        // marginLeft: '15px',
    },
    expandIcon: {
        fontSize: '1.5rem',
        textAlign: 'center',
    },
    expanded: {
        transform: 'rotate(90deg)',
        transition: 'all 1s linear'
    },
    pathTable: { 
        borderRadius: '5px',
        width: '100%',
    },
    header: {
        backgroundColor: 'rgba(16,64,107,0.45)',
        color: 'rgba(16,64,107,1)',
        padding: '2px 10px',
        fontSize: '1rem',
        fontWeight: '300',
        whiteSpace: 'nowrap',
    },
    toggleIcon: {
        fontSize: '1.4rem',
        color: 'rgba(16,64,107,0.7)',
    },
    toggleDeleteIcon: {
        fontSize: '1.4rem',
        color: 'red',
        opacity: '0.5'
    },
    loadingPathOffers: {
        minWidth: '100%',
        height: '100%',
        minHeight: '300px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    actionContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 'unset',
    },
    actionIcon: {
        color: 'rgba(16,64,107,0.75)',
    },
    tablePaper: {
        overflowX: 'hidden',
        overflowY: 'hidden',
        width: '100%',
        borderRadius: '5px',
    },
};

export default styles;