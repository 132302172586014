import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import TextField from '@material-ui/core/TextField';
import { useMutation } from '@apollo/client';
import { SAVE_DOMAIN } from '../../../graphql/mutations';
import { DOMAINS } from '../../../graphql/queries';
import { buildAcceptedTypes } from '../../../utils/helpers';
import styles from './DomainDialog.css.js';
import DialogBase from '../DialogBase/DialogBase';
import InputLabel from '@material-ui/core/InputLabel';
import MediaSwitches from './MediaSwitches';
import ActionButton from '../../ActionButton';
import Paper from '@material-ui/core/Paper';

const DomainDialog = ({ open, close }) => {
    const { addToast } = useToasts();
    const { selectionState, dispatchSelection } = useContext(AppContext);
    const { program_domain } = selectionState;
    const [ isSaving, setIsSaving ] = useState(false);
    const blankPid = { pid: '' };
    const [ pidState, setPidState ] = useState(program_domain?.pid?.length > 0 ? program_domain.pid.map(val => ({ pid: val })) : [{ ...blankPid }]);
    const activeSwitches = program_domain?.accepts?.map(item => (item.value)) ?? [];
    const [ mediaState, setMediaState ] = useState({
        bing: activeSwitches.includes('bing'),
        google: activeSwitches.includes('google'),
        social: activeSwitches.includes('social'),
        native: activeSwitches.includes('native'),
        email: activeSwitches.includes('email'),
        sms: activeSwitches.includes('sms'),
        push: activeSwitches.includes('push'),
        known: activeSwitches.includes('known'),
        unknown: activeSwitches.includes('unknown')
    });
    const { register, errors, handleSubmit } = useForm({ mode: 'onChange', reValidateMode: 'onChange', });
    
    const handleRedirect = (data) => {
        addToast(data.saveDomain.message, { appearance: 'success', autoDismiss: true });
        setIsSaving(false);
        close();
    };
    const handleError = (error) => {
        console.error(error);
        addToast(error?.message ?? 'Error!', { appearance: 'error', autoDismiss: true });
        setIsSaving(false);
        close();
    };

    const [saveProgramDomain] = useMutation(
        SAVE_DOMAIN, {
            refetchQueries: [{ 
                query: DOMAINS
            }],
            onCompleted: handleRedirect,
            onError: handleError,
        }
    );

    const handleAddInputClick = () => {
        setPidState([...pidState, { ...blankPid }])
    };

    const handleRemoveInputClick = (idx) => {
        let newState = pidState.filter((el, i) => i !== idx);
        setPidState(newState);
    };

    const handlePidChange = (e) => {
        const updatedPids = [...pidState];
        updatedPids[Number(e.target.id)]['pid'] = e.target.value;
        setPidState(updatedPids);
    };

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        dispatchSelection({ 
            type: 'DELETE_SELECTION', 
            payload: { 
                type: 'domain', 
                item_id: program_domain.id,
                domain: program_domain.domain,
                title: program_domain.title
            } 
        });
    };

    const handleSubmission = (formData) => {
        setIsSaving(true);
        const { title, domain, description, avatar } = formData;
        const pidValues = pidState.map((input) => (
            Number(input.pid)
        ));
        let formNew = {
            title,
            pid: pidValues,
            domain: domain,
            description: description,
            avatar: avatar || 'N/A',
            accepts: buildAcceptedTypes(mediaState)
        };
        const fullForm = Object.assign({}, 
            formNew,
            !!program_domain?.id && { id: program_domain.id }
        );
        saveProgramDomain({ variables: { domain: fullForm } });
    };

    return (
        <DialogBase
            open={open}
            close={close}
            title={!!program_domain?.id ? 'Edit Program Domain:' : 'Add New Program Domain:'}
            isSaving={isSaving}
            handleSubmission={handleSubmit(handleSubmission)}
            handleDelete={!!program_domain?.id ? handleDeleteClick : null}
        >
            <MediaSwitches mediaState={mediaState} setMediaState={setMediaState} />
            <div style={styles.mainRow}>
                <div style={styles.textFields}>
                    <TextField
                        name='title'
                        label='Program Title'
                        fullWidth
                        autoFocus
                        error={errors.name}
                        defaultValue={program_domain.title}
                        inputRef={ register({ required: 'a Program Title is required!' }) }
                        style={styles.textInput}
                    />
                    <TextField
                        name='domain'
                        label='Domain'
                        fullWidth
                        error={errors.domain}
                        defaultValue={program_domain.domain}
                        inputRef={register({ required: 'a Domain Name is required! ' })}
                        style={styles.textInput}
                    />
                    <TextField
                        name='description'
                        label='Program Description'
                        fullWidth
                        error={errors.description}
                        defaultValue={program_domain.description}
                        inputRef={register({ required: 'a short Description of the Domain is required!' })}
                        style={styles.textInput}
                    />
                    <TextField
                        name='avatar'
                        label='Program Icon SRC'
                        fullWidth
                        defaultValue={program_domain.avatar}
                        inputRef={register()}
                        style={styles.textInput}
                    />
                </div>
                <Paper style={styles.pidPaper} elevation={10}>
                    <div style={styles.pidAssignment}>
                        <div style={styles.pidAssignmentTop}>
                            <InputLabel style={styles.inputLabel}>PID values</InputLabel>
                            <ActionButton 
                                icon='plus'
                                bg='white'
                                bgHover='lightBlue'
                                handleClick={handleAddInputClick}
                            />
                        </div>
                        {pidState.map((val, idx) => (
                            <div key={`pid-input__${idx}`} style={styles.pidRow}>
                                <TextField
                                    id={`${idx}`}
                                    name={`pid-${idx}`}
                                    className='pid'
                                    fullWidth        
                                    required={true}
                                    value={pidState[idx].pid}
                                    onChange={handlePidChange}
                                    style={styles.pidInput}
                                />
                                {idx !== 0 && 
                                    <ActionButton 
                                        icon='minus'
                                        bg='white'
                                        bgHover='lightBlue'
                                        handleClick={() => handleRemoveInputClick(idx)}
                                    />
                                }
                            </div>
                        ))}
                    </div>
                </Paper>
            </div>
        </DialogBase>
    );
}

export default DomainDialog;