export const capitalizeValue = (value) => {
    if(!value || value === 'N/A' || value === '') return value;
    if(value === 'n/a') return 'N/A';
    let text = value;
    text = text.toLowerCase()
        .split('_')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    return text;
};

export const titleCase = (str, setEmpty=false) => {
    if(setEmpty && str === '') return '';
    if(!str || str === 'n/a' || str === 'N/A' || str === '') return 'N/A';
    let splitStr = str.toLowerCase().replaceAll('_', ' ').split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    };
    return splitStr.join(' '); 
};

export const flattenString = (string) => {
    // Removes leading and trailing wite spaces...
    let stringCleaned = string.replace(/^\s+|\s+$/g, '');
    // Replaces spaces between words wit an underscore...
    stringCleaned = stringCleaned.replace(/\s/g, '_');
    // Returns te result in lowercase.
    return stringCleaned.toLowerCase();
};

export const buildAcceptedTypes = (toggleStates) => {
    const toggleStateArray = [{
            media_type: 'bing',
            title: 'Bing Ads',
            avatar: ['fal', 'search-dollar'],
            selected: toggleStates.bing,
        },
        {
            media_type: 'google',
            title: 'Google Adwords',
            avatar: ['fab', 'google'],
            selected: toggleStates.google,
        },
        {
            media_type: 'social',
            title: 'Social (Facebook/Instagram)',
            avatar: ['fal', 'users'],
            selected: toggleStates.social,
        },
        {
            media_type: 'native',
            title: 'Native (Taboola/Outbrain)',
            avatar: ['fal', 'mobile'],
            selected: toggleStates.native,
        },
        {
            media_type: 'email',
            title: 'Email',
            avatar: ['fal', 'envelope'],
            selected: toggleStates.email,
        },
        {
            media_type: 'sms',
            title: 'SMS',
            avatar: ['fal', 'sms'],
            selected: toggleStates.sms,
        },
        {
            media_type: 'push',
            title: 'Push Notifications',
            avatar: ['fal', 'bells'],
            selected: toggleStates.push,
        },
        {
            media_type: 'known',
            title: 'Site Placement (Known)',
            avatar: ['fal', 'ad'],
            selected: toggleStates.known,
        },
        {
            media_type: 'unknown',
            title: 'Affiliate (Unknown)',
            avatar: ['fal', 'question'],
            selected: toggleStates.unknown,
        }
    ];

    let acceptedTypes = [];
    toggleStateArray.map((item) => {
        if (item.selected) {
            let accepted = {
                title: item.title,
                avatar: item.avatar,
                value: item.media_type
            };
            acceptedTypes.push(accepted);
        };
        return null;
    })
    return acceptedTypes;
};

export const amountToText = (str) => {
    switch (str) {
        case '<15000':
            return 'Less than $15,000'
        case '15000-30000':
            return '$15,000 - $30,000'
        case '30000-50000':
            return '$30,000 - $50,000'
        case '>50000':
            return 'More than $50,000'
        default:
            return str
    }
};

export const makePagePretty = (page) => {
	switch(page) {
		case 'direct_link':
			return 'Direct Offer';
		case 'four_button':
			return '4 Button';
		case 'offer_wall':
			return 'Marketplace';
		case 'one_button':
			return 'Single Offer';
		case 'mNet':
			return 'Media.Net';
		case 'optin':
			return 'Opt-In Offer';
		case 'selection':
			return 'User Selection';
		case 'lincx':
			return 'Lincx Page';
		default:
			return 'Unknown page type';
	}
};

export const groupBy = (list, keyGetter) => {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

export const prepSelectionLink = (links) => {
    return links.map((link) => ({
        id: link.id,
        qid: link.qid || link.id,
        cluster: link.cluster,
        name: link.name,
        text: link.text,
        offer_group: link.offer_group,
        url: link.url,
        jump: link.jump,
        google: link.google
    }))
}