import React, { forwardRef, useState, useEffect } from 'react';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useQuery } from '@apollo/client';
import { ALL_QUICK_LINKS } from '../../../graphql/queries';
import Loading from '../../Loading';
import styles from './QuickLinkDialog.css.js';
import { groupBy, titleCase } from '../../../utils/helpers';

const GroupSelect = forwardRef(({ name, labelText, ...props }, ref) => {
    const [ optionData, setOptionData ] = useState(['N/A']);
    const { loading, error, data } = useQuery(ALL_QUICK_LINKS);

    useEffect(() => {
        if (data) {
            const quick_links = data.fetchAllQuickLinks.body;
            if (quick_links.length > 0) {
                // Save cluster keys...
                let newGroupKeys = [];
                let groups = groupBy(quick_links, link => link[`${name}`]);
                for (let key of groups.keys()) { newGroupKeys.push(titleCase(key)); };
                setOptionData(newGroupKeys);
            };
        };
        // eslint-disable-next-line
    }, [data]);

    if (loading) return <div style={styles.loadingTable}><Loading /></div>;

    if (error) return <div>{`Error occured fetching quick link offers: ${error.message}`}</div>;

    return (
    <Autocomplete
        freeSolo
        fullWidth
        options={optionData}
        getOptionLabel={option => !!option ? option : 'N/A'}
        renderOption={option => (
            <span>
                {option}
            </span>
        )}
        renderInput={params => (
            <TextField
                {...params}
                label={labelText}
                variant='outlined'
                inputRef={ref}
                name={name}
            />
        )}
        {...props}
    />
    )
});

export default GroupSelect;