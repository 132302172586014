const styles = {
    loadingTable: {
        minWidth: '100%',
        height: '100%',
        minHeight: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    offersHeader: {
        backgroundColor: 'rgb(35,79,119)',
        color: '#f5f5f5',
        padding: '2px 10px',
        fontSize: '1rem',
        fontWeight: '300'
    },
    rowStyle: {},
    groupTable: {
        borderRadius: '5px',
        backgroundColor: 'aliceblue'
    },
    itemLabel: {
        paddingLeft: '10px',
        fontSize: '1rem',
    },
    tablePaper: {
        width: '100%',
        maxWidth: '1300px',
        margin: '0',
        borderRadius: '5px',
    },
    actionContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 'unset',
    },
    groupRow: {
        backgroundColor: '#FFF',
        color: 'rgba(16,64,107,1)',
    },
    toggleIcon: {
        fontSize: '1.4rem',
        color: 'rgba(16,64,107,0.7)',
    },
    editIcon: {
        fontSize: '1.4rem',
        color: 'rgba(16,64,107,0.7)',
    }
};

export default styles;