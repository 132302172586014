const initialAppState = {
    uri: null,
    authGroup: 'bk',
    homeTabActive: true,
    domainsTabActive: false,
    programsTabActive: false,
    offeringsTabActive: false,
    pushTabActive: false,
    reportsTabActive: false,
    articlesTabActive: false,
    marketTabValue: 0,
    offeringsTabValue: 0,
    subcategoryTabValue: 0,
    reportsTabValue: 0,
    articlesTabValue: 0,
    pageSizeTabValue: 0,
};

const appStateReducer = (state, action) => {
    switch (action.type) {
        case 'SET_URI':
            return {
                ...state,
                uri: action.payload
            }

        case 'SET_AUTH_GROUP':
            return {
                ...state,
                authGroup: action.payload
            }

        case 'HOME_TAB_ACTIVE':
            return {
                ...state,
                homeTabActive: true,
                domainsTabActive: false,
                programsTabActive: false,
                offeringsTabActive: false,
                pushTabActive: false,
                reportsTabActive: false,
                articlesTabActive: false,
            }

        case 'DOMAINS_TAB_ACTIVE':
            return {
                ...state,
                homeTabActive: false,
                domainsTabActive: true,
                programsTabActive: false,
                offeringsTabActive: false,
                pushTabActive: false,
                reportsTabActive: false,
                articlesTabActive: false,
            }

        case 'PROGRAMS_TAB_ACTIVE':
            return {
                ...state,
                homeTabActive: false,
                domainsTabActive: false,
                programsTabActive: true,
                offeringsTabActive: false,
                pushTabActive: false,
                reportsTabActive: false,
                articlesTabActive: false,
            }

        case 'OFFERINGS_TAB_ACTIVE':
            return {
                ...state,
                homeTabActive: false,
                domainsTabActive: false,
                programsTabActive: false,
                offeringsTabActive: true,
                pushTabActive: false,
                reportsTabActive: false,
                articlesTabActive: false,
            }

        case 'PUSH_TAB_ACTIVE':
            return {
                ...state,
                homeTabActive: false,
                domainsTabActive: false,
                programsTabActive: false,
                offeringsTabActive: false,
                pushTabActive: true,
                reportsTabActive: false,
                articlesTabActive: false,
            }
            
        case 'REPORTS_TAB_ACTIVE':
            return {
                ...state,
                homeTabActive: false,
                domainsTabActive: false,
                programsTabActive: false,
                offeringsTabActive: false,
                pushTabActive: false,
                reportsTabActive: true,
                articlesTabActive: false,
            }

        case 'ARTICLES_TAB_ACTIVE':
            return {
                ...state,
                homeTabActive: false,
                domainsTabActive: false,
                programsTabActive: false,
                offeringsTabActive: false,
                pushTabActive: false,
                reportsTabActive: false,
                articlesTabActive: true,
            }

        case 'OFFERINGS_TAB_SELECT':
            return {
                ...state,
                subcategoryTabValue: 0,
                offeringsTabValue: action.payload
            }

        case 'SUBCATEGORY_TAB_SELECT':
            return {
                ...state,
                subcategoryTabValue: action.payload
            }

        case 'REPORTS_TAB_SELECT':
            return {
                ...state,
                reportsTabValue: action.payload
            }

        case 'ARTICLES_TAB_SELECT':
            return {
                ...state,
                articlesTabValue: action.payload
            }

        case 'ARTICLE_SIZE_TAB_SELECT':
            return {
                ...state,
                pageSizeTabValue: action.payload
            }
            
        default:
            throw new Error(`Not supported action ${action.type}`);
    }
};

export {
    initialAppState,
    appStateReducer
};