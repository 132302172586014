import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { useForm } from "react-hook-form";
import { useToasts } from 'react-toast-notifications';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useMutation } from '@apollo/client';
import { SAVE_ENDPOINT } from '../../../graphql/mutations';
import { ALL_ENDPOINTS } from '../../../graphql/queries';
import { prepSelectionLink } from '../../../utils/helpers';
import styles from './EndpointDialog.css.js';
import DialogBase from '../DialogBase';
import SelectPageType from './SelectPageType';
import FourButtonInputs from './FourButtonInputs';
import LinkListBuilder from '../LinkListBuilder';
import GeoRestrictions from './GeoRestrictions';

const EndpointDialog = ({ open, close }) => {
    const { addToast } = useToasts();
    const { selectionState, dispatchSelection } = useContext(AppContext);
    const { endpoint } = selectionState;
    const [ isSaving, setIsSaving ] = useState(false);
    const [ showSearch, setShowSearch ] = useState(endpoint.show_search);
    const [ isGoogle, setIsGoogle ] = useState(endpoint.google || false);
    const [ offerPage, setOfferPage ] = useState(endpoint.offer_page);
    const [ quickLinks, setQuickLinks ] = useState(prepSelectionLink(endpoint.selection_links));
    const [ stateNames, setStateName ] = useState(endpoint.states);
    const { register, errors, handleSubmit } = useForm({ mode: 'onChange', reValidateMode: 'onChange', });
    
    const handleRedirect = (data) => {
        addToast(data.saveEndpoint.message, { appearance: 'success', autoDismiss: true });
        setIsSaving(false);
        close();
    };
    const handleError = (error) => {
        console.error(error);
        addToast(`SAVE Endpoint: FAIL. ${error.message}`, { appearance: 'error', autoDismiss: true });
        setIsSaving(false);
        close();
    };

    const [saveEndpoint] = useMutation(
        SAVE_ENDPOINT, {
        refetchQueries: [{ query: ALL_ENDPOINTS }],
        onCompleted: handleRedirect,
        onError: handleError
    });

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        dispatchSelection({ 
            type: 'DELETE_SELECTION', 
            payload: { 
                type: 'endpoint', 
                item_id: endpoint.id,
                name: endpoint.name
            } 
        });
    };

    const handleSubmission = (formData) => {
        setIsSaving(true);
        let formNew = {
            name: formData.name,
            url: formData?.url ?? 'N/A',
            jump: formData?.jump ?? 'N/A',
            offer_page: offerPage,
            four_button: offerPage === 'four_button' ? ([
                {
                    text: formData.buttonText1,
                    url: formData.buttonUrl1,
                    jump: formData.buttonJump1
                },
                {
                    text: formData.buttonText2,
                    url: formData.buttonUrl2,
                    jump: formData.buttonJump2
                },
                {
                    text: formData.buttonText3,
                    url: formData.buttonUrl3,
                    jump: formData.buttonJump3
                },
                {
                    text: formData.buttonText4,
                    url: formData.buttonUrl4,
                    jump: formData.buttonJump4
                },
            ]) : [],
            restricted: stateNames?.length > 0,
            states: stateNames,
            question_text: formData?.question_text ?? '',
            show_search: showSearch,
            selection_links: offerPage === 'selection' ? prepSelectionLink(quickLinks) : [],
            quick_link_group: 'N/A',
            google: isGoogle
        };
        const fullForm = Object.assign({}, 
            formNew,
            !!endpoint?.id && { id: endpoint.id }
        );
        console.log('full:', fullForm);
        saveEndpoint({ variables: { endpoint: fullForm } });
    };

    return (
        <DialogBase
            open={open}
            close={close}
            title={!!endpoint?.id ? 'Edit Endpoint Offer:' : 'Add New Endpoint Offer:'}
            isSaving={isSaving}
            handleSubmission={handleSubmit(handleSubmission)}
            handleDelete={!!endpoint?.id ? handleDeleteClick : null}
            style={{margin: '10px 0 10px 0'}}
            bg='white'
        >
            <form style={styles.mainForm}>
                <SelectPageType page={offerPage} setPage={setOfferPage}/>
                <FormControlLabel
                    style={styles.googleInput}
                    control={
                        <Checkbox
                            checked={isGoogle}
                            onChange={() => setIsGoogle(!isGoogle)}
                            name='show-group'
                            color='primary'
                        />
                    }
                    label='Show as System 1 Link?'
                />
                <TextField
                    name='name'
                    label='Offer Name'
                    fullWidth
                    autoFocus
                    error={errors.name}
                    defaultValue={endpoint.name}
                    inputRef={ register({ required: 'a Name value is required!' }) }
                    style={styles.textInput}
                />
                {showSearch && offerPage === 'selection' &&
                    <TextField
                        name='url'
                        label='Custom Search Linkout URL'
                        fullWidth
                        error={errors.url}
                        defaultValue={endpoint.url}
                        inputRef={ register({ required: 'the search box linkout url is required!' }) }                    
                        style={styles.textInput}
                    />
                }      
                {offerPage === 'selection' &&
                    <div style={styles.selectionPageRow}>
                        <FormControlLabel
                            style={styles.checkInput}
                            control={
                                <Checkbox
                                    checked={showSearch}
                                    onChange={() => setShowSearch(!showSearch)}
                                    name='show-search'
                                    color='primary'
                                />
                            }
                            label='Show Custom Search Input?'
                        />
                        <TextField
                            name='question_text'
                            label='Question Text'
                            fullWidth
                            error={errors.question_text}
                            defaultValue={endpoint?.question_text ?? 'What are you interested in?'}
                            inputRef={ register({ required: 'the offer\'s Question Text is required!' }) }                    
                            style={styles.textInput}
                        />
                    </div>
                }
                {!['lincx', 'selection', 'four_button'].includes(offerPage) &&
                    <TextField
                        name='url'
                        label={offerPage === 'mNet' ? 'Search Keyword' : 'Offer Linkout URL'}
                        fullWidth
                        error={errors.url}
                        defaultValue={endpoint.url}
                        inputRef={ register({ required: 'the offer\'s linkout url is required!' }) }                    
                        style={styles.textInput}
                    />
                }  
                {!['mNet', 'selection', 'lincx', 'four_button'].includes(offerPage) &&
                    <TextField
                        name='jump'
                        label='Jump Behind URL'
                        fullWidth
                        defaultValue={endpoint.jump}
                        inputRef={ register() }
                        style={styles.textInput}
                    />
                }
                {offerPage === 'four_button' && <FourButtonInputs register={register} />}
                {offerPage === 'selection' && 
                    <LinkListBuilder 
                    selection={quickLinks}
                    setSelection={setQuickLinks}
                    />
                }
                <GeoRestrictions states={stateNames} setStates={setStateName} />
            </form>
        </DialogBase>
    );
}

export default EndpointDialog;