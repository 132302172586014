const styles = {
    toggleContainer: {
        display: 'inline-flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    toggleText: {
        marginLeft: '20px',
        fontSize: '1.25rem'
    },
    buttonInputContainer: {
        marginTop: '20px',
        width: '100%',
        border: '1px solid rgba(0,0,0,0.25)',
        borderRadius: '25px',
        padding: '30px 50px 50px 50px'
    },
    check: {
        fontSize: '1.5rem',
    },
    buttonInputAddRow: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    buttonInputRow: {
        margin: '0 20px',
        width: '95%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    buttonInputRowNumber: {
        color: 'rgba(0,0,0,0.54)',
        paddingRight: '10px',
        paddingTop: '10px'
    },
    buttonInputLabel: {
        color: 'rgba(0,0,0,0.54)',
	    fontSize: '14px',
    },
    litteIcon: {
        fontSize: '1.25rem',
        marginRight: '15px',
    },
    iconHover: {
        cursor: 'pointer',
        color: 'rgb(36,78,118)',
        fontSize: '1.5rem',
        opacity: '0.75',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
    }
};
export default styles;