import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { useForm } from "react-hook-form";
import { useToasts } from 'react-toast-notifications';
import TextField from '@material-ui/core/TextField';
// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useMutation } from '@apollo/client';
import { SAVE_ARTICLE_OFFER } from '../../../graphql/mutations';
import { ARTICLE_OFFERS } from '../../../graphql/queries.js';
import styles from './ArticleOfferDialog.css.js';
import DialogBase from '../DialogBase';

const ArticleOfferDialog = ({ open, close }) => {
    const { addToast } = useToasts();
    const { selectionState, dispatchSelection } = useContext(AppContext);
    const { article_offer } = selectionState;
    const [ isSaving, setIsSaving ] = useState(false);
    const { register, errors, handleSubmit } = useForm({ mode: 'onChange', reValidateMode: 'onChange', });
    
    const handleRedirect = (data) => {
        addToast(data.saveArticleOffer.message, { appearance: 'success', autoDismiss: true });
        setIsSaving(false);
        close();
    };
    const handleError = (error) => {
        console.error(error);
        addToast(`SAVE Article Offer: FAIL. ${error.message}`, { appearance: 'error', autoDismiss: true });
        setIsSaving(false);
        close();
    };

    const [saveArticleOffer] = useMutation(
        SAVE_ARTICLE_OFFER, {
        refetchQueries: [{ query: ARTICLE_OFFERS }],
        onCompleted: handleRedirect,
        onError: handleError
    });

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        dispatchSelection({ 
            type: 'DELETE_SELECTION', 
            payload: { 
                type: 'article_offer', 
                id: article_offer.id,
                name: article_offer.name
            }
        });
    };

    const handleSubmission = (formData) => {
        setIsSaving(true);
        let formNew = {
            name: formData.name,
            display: formData.display,
            main_title: formData.main_title,
            sub_title: formData.sub_title,
            sub_text: formData.sub_text,
            cta: formData.cta,
            offer_url: formData.offer_url,
        };
        const fullForm = Object.assign({}, 
            formNew,
            !!article_offer?.id && { id: article_offer.id }
        );
        // console.log('full:', fullForm);
        saveArticleOffer({ variables: { articleOffer: fullForm } });
    };

    return (
        <DialogBase
            open={open}
            close={close}
            title={!!article_offer?.id ? 'Edit Article Offer:' : 'Add New Offer:'}
            isSaving={isSaving}
            handleSubmission={handleSubmit(handleSubmission)}
            handleDelete={!!article_offer?.id ? handleDeleteClick : null}
            style={{margin: '10px 0 10px 0'}}
            bg='white'
        >
            <form style={styles.mainForm}>
                <TextField
                    name='name'
                    label='Friendly Name'
                    fullWidth
                    autoFocus
                    error={errors.name}
                    defaultValue={article_offer?.name ?? ''}
                    inputRef={ register({ required: 'a Name value is required!' }) }
                    style={styles.textInput}
                />
                <TextField
                    name='display'
                    label='Offer Block Button Text'
                    fullWidth
                    error={errors.display}
                    defaultValue={article_offer?.display ?? ''}
                    inputRef={ register({ required: 'a Button Display value is required!' }) }
                    style={styles.textInput}
                />
                <TextField
                    name='main_title'
                    label='Main Header'
                    fullWidth
                    error={errors.main_title}
                    defaultValue={article_offer?.main_title ?? ''}
                    inputRef={ register({ required: 'the offer\'s header is required!' }) }                    
                    style={styles.textInput}
                />
                <TextField
                    name='sub_title'
                    label='Sub-text Title'
                    fullWidth
                    error={errors.sub_title}
                    defaultValue={article_offer?.sub_title ?? ''}
                    inputRef={ register({ required: 'the offer\'s subtext title is required!' }) }                    
                    style={styles.textInput}
                />
                <TextField
                    name='sub_text'
                    label='Sub-text Content'
                    fullWidth
                    error={errors.sub_text}
                    defaultValue={article_offer?.sub_text ?? ''}
                    multiline
                    inputRef={ register({ required: 'the offer\'s subtext content is required!' }) }                    
                    style={styles.textInput}
                />
                <TextField
                    name='cta'
                    label='Call to Action'
                    fullWidth
                    error={errors.cta}
                    defaultValue={article_offer?.cta ?? ''}
                    inputRef={ register({ required: 'the offer\'s call to action is required!' }) }                    
                    style={styles.textInput}
                />
                <TextField
                    name='offer_url'
                    label='Offer URL'
                    fullWidth
                    error={errors.offer_url}
                    defaultValue={article_offer?.offer_url ?? ''}
                    inputRef={ register({ required: 'the offer\'s URL is required!' }) }                    
                    style={styles.textInput}
                />
            </form>
        </DialogBase>
    );
}

export default ArticleOfferDialog;