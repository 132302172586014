import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import styles from './CustomCells.css.js';
import { capitalizeValue } from '../../../utils/helpers';

const RenderTooltip = ({ data, selector }) => (
    <div style={styles.tooltipContainer}>
        {data.map((link, idx) => (
            <div key={`link-${selector}-${idx}`} style={styles.buttonText}>
                <span style={styles.indexText}>{`${idx + 1}.) `}</span>                
                {typeof link === 'string' ? 
                    <a rel='noreferrer' target='_blank' style={styles.valueText} href={link}>{link}</a> :
                    selector === 'text' ? 
                        <div style={styles.valueText}>{capitalizeValue(link[selector])}</div> : 
                        <a rel='noreferrer' target='_blank' style={styles.valueText} href={link[selector]}>{link[selector]}</a>
                }
            </div>
        ))}
    </div>
);

const TooltipChild = forwardRef((props, ref) => (
    <div style={styles.eyeContainer} ref={ref} {...props}>
        <FontAwesomeIcon style={styles.cellIcon} icon={['fal', 'eye']} />
    </div>
));

const TooltipCell = ({ linkArray, selector }) => (
    linkArray && linkArray[0] !== 'N/A' ? (
        <Tooltip
            title={
                <RenderTooltip 
                    data={linkArray}
                    selector={selector}
                />
            }
            arrow={true}
            placement='top'
            // style={styles.toolTip}
            interactive
            TransitionComponent={Fade} 
            TransitionProps={{ timeout: 600 }}
        >
            <TooltipChild />
        </Tooltip>
    ) : <FontAwesomeIcon style={styles.emptyColumn} icon={['fal', 'empty-set']} />
);

export default TooltipCell;