import React, { useContext, useEffect } from 'react';
import { AppContext } from '../context';
// import { useAuth0 } from '../auth/react-auth0-spa';
import RouteComponent from '../RouteComponent';
import Sidebars from './Sidebars';
// import Loading from './Loading';
import styles from './Global/GlobalStyle.css.js';
import DeleteDialogs from './Forms/DeleteDialogs';

const App = ({ uri }) => {
    // const { loading } = useAuth0();
    const { selectionState, appState, dispatchApp } = useContext(AppContext);

    useEffect(() => {
        if(!appState.uri){
            dispatchApp({ type: 'SET_URI', payload: uri});
        };
    },[]);
	// if (loading) return <div style={styles.initLoading}><Loading /></div>;

	return (
		<div style={styles.app}>
            {selectionState.show_delete ?
                <DeleteDialogs /> :
                <Sidebars>
                    <RouteComponent />
                </Sidebars> 
            }
        </div>
	);
};

export default App;