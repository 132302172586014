import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './DialogBase.css.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DialogBase = (props) => {
    const { 
        open, close, 
        title, isSaving, 
        children, showSubmit=true,
        handleSubmission,
        bg='white', handleDelete } = props;

    const [ isHovering, setHovering ] = useState(false);
    const [ isHoveringDelete, setHoveringDelete ] = useState(false);

    const themedStyle = Object.assign({},
        styles.submitButton,
        styles.submitColor,
        isHovering && styles.hover,
        isSaving && styles.progress
    );
    const paperStyle = Object.assign({},
        styles.paperContainer,
        styles[`${bg}`]
    );

    const deleteButtonStyle = Object.assign({},
        styles.deleteButton,
        isHoveringDelete && styles.deleteHover
    );

    return (
        <div style={styles.formContainer}>
            <Slide direction='down' in={open} mountOnEnter unmountOnExit>
                <Paper elevation={10} style={paperStyle}>
                    <div style={styles.headerContainer}>{title}</div>
                        <div style={styles.modalForm}>
                            {children}
                            {showSubmit && 
                                <div style={styles.formButtonRow}>
                                    <div>
                                        {!!handleDelete && 
                                            <Button
                                                onClick={handleDelete}
                                                onFocus={(event) => event.stopPropagation()}
                                                onMouseEnter={() => setHoveringDelete(true)}
                                                onMouseLeave={() => setHoveringDelete(false)}
                                                style={deleteButtonStyle}
                                            >
                                                <FontAwesomeIcon 
                                                    style={styles.deleteIcon} 
                                                    icon={['fal', 'trash-alt']} 
                                                />
                                            </Button>
                                        }
                                    </div>
                                    <ButtonGroup style={styles.formButtons}>
                                        <Button 
                                            onClick={close}>
                                            Cancel
                                        </Button>
                                        <Button
                                            id='submit_button'
                                            type='submit'
                                            onClick={handleSubmission} 
                                            onFocus={(event) => event.stopPropagation()}
                                            onMouseEnter={() => setHovering(true)}
                                            onMouseLeave={() => setHovering(false)}
                                            style={themedStyle}
                                        >
                                            {isSaving ? <CircularProgress size={24} style={styles.buttonProgress} /> : 'Save'}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            }
                        </div>
                </Paper>
            </Slide>
        </div>
    );
}

export default DialogBase;