import React, { useState } from 'react';
import styles from './Reports.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import axios from 'axios';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import Loading from '../../Loading';

const SubIdHourlyReport = () => {
    const [ hovering, setHovering ] = useState(false);
    const [ date, setDate ] = useState(dayjs());
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(null);

    const lengthOfNumber = (num) => {
        return num.toString().length;
    };

    const formatDateString = (dateObj) => {
        const year = dateObj.year();
        let month = dateObj.month() + 1;
        if(lengthOfNumber(month) === 1) { month = `0${month}`};
        let day = dateObj.date();
        if(lengthOfNumber(day) === 1) { day = `0${day}`};
        return `${year}-${month}-${day}`;
    };

    const fetchReport = async() => {
        const formattedDate = formatDateString(date);
        const fetchUrl = `https://f8fjn5bgw2.execute-api.us-east-1.amazonaws.com/prod/system1/subid/hourly/${formattedDate}`;
        // const fetchUrl = `http://localhost:4000/dev/system1/subid/${dateString}`;
        try{
            return await axios({
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json'
                },
                timeout: 30000,
                url: fetchUrl
            });
        } catch (err) {
            setLoading(false);
            setError(err.message);
            console.log('Error:', err);
        }
    };

    const handleDateChange = (newValue) => {
        setDate(newValue);
    };

    const handleDownloadClick = async() => {
        setLoading(true);
        setError(null);
        const reportData = await fetchReport(); 
        const blob = new Blob([reportData.data], { type: 'text/csv;charset=utf-8,' })
        const objUrl = URL.createObjectURL(blob);
        const reportDate = dayjs(date);
        const formattedDate = formatDateString(reportDate);
        let linkElement = document.createElement('a');
        linkElement.setAttribute('href', objUrl);
        linkElement.setAttribute('download', `sub_id_hourly_report_${formattedDate}`);
        linkElement.click();
        setLoading(false);
    };

    const downloadStyle = Object.assign({},
        styles.downloadButton,
        hovering && styles.hover
    );

    return(
        <div style={styles.pageContainer}>
            <div style={styles.datePickerContainer}>
                <div style={styles.datePicker}>
                    <DatePicker
                        label="Report Date"
                        value={date}
                        onChange={handleDateChange}
                    />  
                </div>
                <Tooltip 
                    title='Download CSV' 
                    placement='top-start' 
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                >
                    <Fab 
                        style={downloadStyle}
                        aria-label='download' 
                        onClick={handleDownloadClick}
                        variant='extended'
                        onMouseEnter={() => setHovering(true)}
                        onMouseLeave={() => setHovering(false)}
                    >
                        <FontAwesomeIcon style={styles.downloadIcon} icon={['fal', 'download']} />
                    </Fab>
                </Tooltip>
            </div>
            {loading && (
                <div style={styles.loaderContainer}>
                    <Loading />
                </div>
            )}
            {!!error && (
                <div style={styles.loaderContainer}>
                    {error}
                </div>
            )}
        </div>
    )
};

export default SubIdHourlyReport;