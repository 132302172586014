import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import TextField from '@material-ui/core/TextField';
import { useMutation } from '@apollo/client';
import { SAVE_ENDPOINT_GROUP } from '../../../graphql/mutations';
import { ENDPOINT_GROUPS } from '../../../graphql/queries';
import styles from './OfferGroupDialog.css.js';
import DialogBase from '../DialogBase/DialogBase';
import GroupItemInput from './GroupItemInput';
import OfferSelectionList from './OfferSelectionList';

const OfferGroupDialog = ({ open, close }) => {
    const { addToast } = useToasts();
    const { selectionState, dispatchSelection } = useContext(AppContext);
    const { offer_group } = selectionState;
    const [ offerSelection, setOfferSelection ] = useState(offer_group?.group_items.map((item, idx) => ({id: item, usage: offer_group.usage[idx]})) ?? []);
    const [ isSaving, setIsSaving ] = useState(false);

    const { register, errors, handleSubmit } = useForm({ mode: 'onChange', reValidateMode: 'onChange', });
    
    const handleRedirect = (data) => {
        addToast(data.saveEndpointGroup.message, { appearance: 'success', autoDismiss: true });
        setIsSaving(false);
        close();
    };
    const handleError = (error) => {
        console.error(error);
        addToast(error?.message ?? 'Error!', { appearance: 'error', autoDismiss: true });
        setIsSaving(false);
        close();
    };

    const [saveEndpointGroup] = useMutation(
        SAVE_ENDPOINT_GROUP, {
            refetchQueries: [{ query: ENDPOINT_GROUPS }],
            onCompleted: handleRedirect,
            onError: handleError,
        }
    );

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        dispatchSelection({ 
            type: 'DELETE_SELECTION', 
            payload: { 
                type: 'endpoint_group', 
                item_id: offer_group.id,
                name: offer_group.name
            } 
        });
    };

    const handleSelectionClick = (e, rowData) => {
        e.stopPropagation();
        const activeIds = offerSelection.map(item => item.id);
        if(activeIds.includes(rowData.id)) {
            const newSelection = offerSelection.filter(item => (item.id !== rowData.id));
            setOfferSelection(newSelection);
        } else {
            setOfferSelection([...offerSelection, { ...rowData, usage: 0 }]);
        };
    };

    const handleSubmission = (formData) => {
        setIsSaving(true);
        const formNew = {
            name: formData.name,
            group_items: offerSelection.map(offer => (offer.id)),
            usage: offerSelection.map((_, idx) => (Number(formData[`usage${idx}`])))
        };
        const fullForm = Object.assign({}, 
            formNew,
            !!offer_group?.id && { id: offer_group.id }
        );
        saveEndpointGroup({ variables: { group: fullForm } });
    };

    return (
        <DialogBase
            open={open}
            close={close}
            title={!!offer_group?.id ? 'Edit Offer Group:' : 'Add New Offer Group:'}
            isSaving={isSaving}
            handleSubmission={handleSubmit(handleSubmission)}
            handleDelete={!!offer_group?.id ? handleDeleteClick : null}
        >
            <div style={styles.textFields}>
                <TextField
                    name='name'
                    label='Offer Group Name'
                    fullWidth
                    autoFocus
                    error={!!errors.name}
                    defaultValue={offer_group?.name ?? ''}
                    inputRef={ register({ required: 'a Group Name is required!' }) }
                    style={styles.textInput}
                />
                {offerSelection?.map((item, idx) => (
                    <GroupItemInput
                        key={item.id}
                        name={`usage${idx}`}
                        item={{...item, idx}}
                        error={!!errors[`usage${idx}`]}
                        ref={register({ required: 'Offer Usage % is required!' })}
                    />
                ))}
            </div>
            <div style={styles.offerList}>
                <OfferSelectionList 
                    handleSelection={handleSelectionClick} 
                    activeIds={offerSelection.map(item => item.id)}
                />
            </div>
        </DialogBase>
    );
}

export default OfferGroupDialog;