import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faYahoo,
    faGoogle,
    faFacebook,
    faOptinMonster
} from '@fortawesome/free-brands-svg-icons';

import {
    faBars,
    faArrowAltRight,
    faCreditCard,
    faHandHoldingUsd,
    faHomeHeart,
    faCar,
    faPercent,
    faDollarSign,
    faMoneyBillWave,
    faGift,
    faPlaneDeparture,
    faChartLine,
    faBriefcase,
    faExchange,
    faTruckPickup,
    faTruckMonster,
    faShuttleVan,
    faUserGraduate,
    faBalanceScaleRight,
    faLightbulbDollar,
    faMoneyCheckEditAlt,
    faShoppingCart,
    faHandsUsd,
    faSearchDollar,
    faLandmarkAlt,
    faGopuram,
    faSortSizeDown,
    faFileInvoiceDollar,
    faSackDollar,
    faCars,
    faDonate,
    faHeartRate,
    faCoin,
    faCoins,
    faAngleDoubleRight,
    faChevronDoubleRight,
    faThumbsUp,
    faBlog,
    faTimes,
    faChevronUp,
    faSearch,
    faHome,
    faRoute,
    faChartNetwork,
    faSignOut,
    faChevronLeft,
    faChevronRight,
    faAd,
    faBells,
    faSms,
    faEnvelope,
    faMobile,
    faUsers,
    faPen,
    faQuestion,
    faPlus,
    faMinus,
    faEmptySet,
    faCarSide,
    faLocation,
    faHourglass,
    faCalendarDay,
    faCalendarWeek,
    faCalendarAlt,
    faInfinity,
    faLongArrowDown,
    faAngleRight,
    faEye,
    faTruck,
    faEyeSlash,
    faTrashAlt,
    faList,
    faObjectGroup,
    faFunnelDollar,
    faLayerGroup,
    faBullseyePointer,
    faChevronDown,
    faFastBackward,
    faFastForward,
    faAngleLeft,
    faCheckSquare,
    faBan,
    faGraduationCap,
    faMoneyBillAlt,
    faPlane,
    faPiggyBank,
    faLandmark,
    faCompressArrowsAlt,
    faFileChartColumn,
    faBook,
    faMinusCircle,
    faPlusCircle,
    faSitemap,
    faBullseyeArrow,
    faDiagramSubtask,
    faDownload,
    faClock,
    faTrophyStar,
    faListRadio,
    faInboxOut,
    faNewspaper,
    faLightbulb
} from '@fortawesome/pro-light-svg-icons';

export const initFontAwesome = () => {
    library.add(
        faBars,
        faArrowAltRight,
        faCreditCard,
        faHandHoldingUsd,
        faHomeHeart,
        faCar,
        faPercent,
        faDollarSign,
        faMoneyBillWave,
        faGift,
        faUserGraduate,
        faPlaneDeparture,
        faChartLine,
        faBriefcase,
        faExchange,
        faCars,
        faTruckPickup,
        faTruckMonster,
        faShuttleVan,
        faBalanceScaleRight,
        faLightbulbDollar,
        faMoneyCheckEditAlt,
        faShoppingCart,
        faHandsUsd,
        faSearchDollar,
        faLandmarkAlt,
        faGopuram,
        faDonate,
        faSortSizeDown,
        faHeartRate,
        faFileInvoiceDollar,
        faCoin,
        faCoins,
        faSackDollar,
        faAngleDoubleRight,
        faChevronDoubleRight,
        faThumbsUp,
        faBlog,
        faTimes,
        faChevronUp,
        faSearch,
        faHome,
        faRoute,
        faChartNetwork,
        faSignOut,
        faChevronLeft,
        faChevronRight,
        faAd,
        faBells,
        faSms,
        faEnvelope,
        faMobile,
        faUsers,
        faPen,
        faQuestion,
        faYahoo,
        faGoogle,
        faFacebook,
        faOptinMonster,
        faPlus,
        faMinus,
        faEmptySet,
        faCarSide,
        faLocation,
        faHourglass,
        faCalendarDay,
        faCalendarWeek,
        faCalendarAlt,
        faInfinity,
        faLongArrowDown,
        faAngleRight,
        faEye,
        faTruck,
        faEyeSlash,
        faTrashAlt,
        faList,
        faObjectGroup,
        faFunnelDollar,
        faLayerGroup,
        faBullseyePointer,
        faChevronDown,
        faFastBackward,
        faFastForward,
        faAngleLeft,
        faCheckSquare,
        faBan,
        faGraduationCap,
        faPlane,
        faMoneyBillAlt,
        faPiggyBank,
        faLandmark,
        faCompressArrowsAlt,
        faFileChartColumn,
        faBook,
        faMinusCircle,
        faPlusCircle,
        faSitemap,
        faBullseyeArrow,
        faDiagramSubtask,
        faDownload,
        faClock,
        faTrophyStar,
        faListRadio,
        faInboxOut,
        faNewspaper,
        faLightbulb
    );
};