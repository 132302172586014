const styles = {
    mainForm: {
        width: '100%',
        minWidth: '1100px'
    },
    textInput: {
        marginBottom: '40px'
    },
    offerSelectionContainer: {
        width: '100%',
        display: 'inline-flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    header: { 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    headLegend: {
        width: '100%',
        margin: '0 0 20px 0',
        color: '#0F406B',
        opacity: '0.75',
        fontSize: '1.1rem',
    },
    leftContainer: {
        width: '40%',
    },
    rightContainer: {
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    emptyContainer: {
        width: '40%',
        minHeight: '200px',
        paddingTop: '20%',
        display: 'flex',
        flexDirection: 'column',
        justifyConent: 'center',
        alignItems: 'center'
    },
    emptyContainerText: {
        fontSize:'1.4rem',
        fontWeight: 'bold',
        color: '#0F406B',
    },
    emptyIcon: {
        marginTop: '20px',
        fontSize: '1.5rem'
    },
    exampleContainer: {
        maxWidth: '500px'
    },
    blockContainer: {
        width: '100%',
        maxWidth: '600px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '30px',
        border: '1px solid rgba(15, 64, 107, 0.5)',
        borderRadius: '10px',
        backgroundColor: 'rgb(239,248,255)'
    },
    blockTitle: {
        width: '100%',
        textAlign: 'center',
        fontSize: '1.4rem',
        fontWeight: 'bold',
        marginBottom: '20px'
    },
    blockOffersListContainer: {
        width: '100%',
        maxWidth: '800px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    blockOfferContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    blockOfferButton: {
        width: '350px',
        height: '40px',
        backgroundColor: 'rgb(9,44,126)',
        color: 'rgb(255,255,255)',
        fontSize: '1.1rem',
        marginBottom: '10px',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};
export default styles;