import React, { useState } from 'react';
import styles from './ArticleControl.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'react-responsive';

const RecBox = ({item}) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [ isHovering, setHovering ] = useState(false);
    const handleOfferClick = () => {
        window.open(item.offer_url, "_blank");
    };

    const iconContainerStyle = Object.assign(
        styles.recTitleContainer,
        isMobile && {alignItems: 'center'}
    );
    const iconStyle = Object.assign(
        styles.recIcon,
        isMobile && {fontSize: '2rem'}
    );

    const buttStyle = Object.assign({}, 
        styles['offerButton'],
        isHovering && styles['offerHover'],
    );

    const ContentText = ({title, text}) => (
        <div style={styles.contentText}>
            {!!title && <b style={styles.boldText}>{`${title} - `}</b>}{text}
        </div>
    );

    return(
        <div style={styles.recOfferContainer}>
            <div style={iconContainerStyle}>
                <FontAwesomeIcon
                    icon={['fal', 'trophy-star']}
                    style={iconStyle}
                />
                <div style={{...styles.title, padding: '0'}}>
                    {item.main_title}
                </div>
            </div>
            <ContentText title={item.sub_title} text={item.sub_text} />
            <div style={styles.offerButtonContainer}>
                <button 
                    onClick={handleOfferClick} 
                    style={buttStyle}
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                >
                    {item.cta}
                </button>
            </div>
        </div>
    )
};

export default RecBox;