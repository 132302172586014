import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import styles from './Steps.css.js';

const ControlButtons = ({close, handleNextClick, handleBack}) => {
    const [ isHovering, setHovering ] = useState(false);

    const buttonStyle = Object.assign({},
        styles.nextButton,
        isHovering && styles.hoverButton
    );

	return (
        <ButtonGroup style={styles.buttonGroup}>
            {!!handleBack && <Button onClick={handleBack}>Back</Button>}
            {!!close && <Button onClick={close}>Cancel</Button>}
            {!!handleNextClick && <Button
                onClick={handleNextClick}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
                style={buttonStyle}
            >
                Next
            </Button>}
        </ButtonGroup>
	);
}

export default ControlButtons;