const styles = {
    inputPaper: {
        minHeight: '300px',
        display: 'flex',
        margin: '20px 0 0 0',
        backgroundColor: 'rgba(16,64,107,0.15)',
        borderRadius: '10px',
        padding: '10px'
    },
    linkBuilder: {
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
        margin: '20px 0'
    },
    selectedListContainer: {
        width: '30%',
        margin: '0 20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    customListContainer: {
        width: '70%',
        margin: '0 20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    customListTitle: {
        borderBottom: '1px solid rgba(16,64,107,1)',
        color: 'rgba(16,64,107,1)',
        minWidth: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '15px',
        fontSize: '1.4rem'
    },
    customLoading: {
        minHeight: '200px',
        width: '100%',
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    selectedListTitle: {
        borderBottom: '1px solid rgba(16,64,107,1)',
        color: 'rgba(16,64,107,1)',
        minWidth: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        fontSize: '1.4rem'
    },
    linkRow: {
        maxWidth: '600px',
        overflowX: 'hidden',
        backgroundColor: '#FFF'
    },
    toggleIcon: {
        fontSize: '1.4rem',
        color: 'rgba(16,64,107,0.7)',
    },
    addContainer: {
        borderRadius: '50%',
        // color: 'white',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '30px',
        maxHeight: '30px',
        width: '30px',
        minWidth: 'unset',
        margin: '0',
        padding: '0',
    },
    addHover: {
        backgroundColor: 'rgba(66,150,180,0.75)',
        color: 'white',
        transition: 'all 0.5'
    },
    addIcon: {
        fontSize: '1.2rem',
        color: 'rgba(16,64,107,1)',
        fontWeight: '300'
    },
    customLinksPaper: {
        width: '100%',
    },
    customlinksTable: {
        borderRadius: '5px',
        width: '100%',
        overflowX: 'hidden',
    },
    actionContainer: {
        position: 'relative',
        left: '-175px',
    },
    getItemStyle: (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        padding: '8px 16px',
        margin: '0',
        border: '1px solid #10406B',
        borderRadius: '25px',
        fontSize: '1.25rem',
        width: 250,
        // change background colour if dragging
        background: isDragging ? 'lightgreen' : 'aliceblue',
    
        // styles we need to apply on draggables
        ...draggableStyle
    }),
    getListStyle: isDraggingOver => ({
        background: isDraggingOver ? 'rgba(16,64,107,0.1)' : 'rgba(16,64,107,0)',
        borderRadius: 10,
        padding: 16,
        width: 368,
        marginTop: 10
    }),
    selectedRow: {
        display: 'inline-flex',
        width: '100%',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginBottom: '10px'
    },
    removeIcon: {
        color: 'red',
        fontSize: '1.4rem'
    }
};

export default styles;