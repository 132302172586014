import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GROUP_ITEM_COUNT } from '../../graphql/queries';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './GroupItemCount.css.js';

const GroupItemCount = ({ program_id, group_id, offer_id, range }) => {

    const { loading, error, data, refetch } = useQuery(
        GROUP_ITEM_COUNT,
        {
            variables: {
                service: {
                    program_id,
                    group_id,
                    offer_id,
                    range
                }
            },
            skip: !program_id || !group_id || !offer_id || !range,
            // pollInterval: 25000
        }
    );

    useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, [range]);

    if (error) {
        return (
            <div style={styles.error}>
                {`Error occurred fetching program item group count...${error.message}`}
            </div>
        )
    };

    if (loading) return <CircularProgress size={30} thickness={4.5}/>;

    return (
        <div style={styles.count}>
            <div style={styles.countText}>{data.fetchGroupItemCount.body}</div>
        </div>
    )
}

export default GroupItemCount;