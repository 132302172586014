const styles = {
    pageContainer: {
        minWidth: '90vw',
        maxWidth: '1500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    datePickerContainer: {
        width: '100%',
        maxWidth: '1500px',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
        marginTop: '20px',
        borderBottom: 'solid 1px #10406B',
        paddingBottom: '20px'
    },
    datePicker: {
        width: '300px'
    },
    dataTableContainer: {
        width: '100%',
        maxWidth: '1500px',
    },
    loaderContainer: {
        width: '100%',
        maxWidth: '1500px',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10vh'
    },
    downloadButton: {
        backgroundColor: 'rgba(189,189,189,0.5)',
        borderRadius: '50%',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '35px',
        maxHeight: '35px',
        width: '35px',
        minWidth: 'unset',
        margin: '0 5px',
        padding: '0',
    },
    downloadIcon: {
        fontSize: '1.3rem',
        color: '#10406B',
    },
    hover: {
        cursor: 'pointer',
        backgroundColor: 'rgba(66,150,180,0.5)',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
    },
    inputGroup: {
        display: 'inline-flex',
        justifyContent: 'flex-start'
    },
    dayPicker: {
        marginLeft: '20px',
        width: '150px'
    },
    typePicker: {
        marginLeft: '20px'
    },
    buttonProgress: {
        color: 'rgb(162, 201, 252) !important',
        margin: '0 10px',
    },
    progress: {
        backgroundColor: 'aliceblue'
    },
    submit: {
        backgroundColor: '#10406B',
        color: 'white',
    },
    unexpected: {
        fontSize: '1.5rem',
        width: '100%',
        alignItems: 'center'
    }
};
export default styles;