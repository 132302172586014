import React, { useEffect, useState } from 'react';
import styles from './Reports.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import axios from 'axios';
import SearchReportTable from './Tables/SearchReportTable';

const SearchPerformanceReport = () => {
    const [ reportData, setReportData ] = useState(null);
    const [ date, setDate ] = useState(dayjs());

    const fetchReport = async(dateString) => {
        const fetchUrl = `https://f8fjn5bgw2.execute-api.us-east-1.amazonaws.com/prod/system1/search/${dateString}`;
        // const fetchUrl = `http://localhost:4000/dev/system1/search/${dateString}`;
        const response = await axios({
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            url: fetchUrl
        });
        setReportData(response.data);
    };

    const lengthOfNumber = (num) => {
        return num.toString().length;
    };

    const formatDateString = (dateObj) => {
        const year = dateObj.year();
        let month = dateObj.month() + 1;
        if(lengthOfNumber(month) === 1) { month = `0${month}`};
        let day = dateObj.date();
        if(lengthOfNumber(day) === 1) { day = `0${day}`};
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        if(!reportData) {
            const reportDate = dayjs(date);
            const formattedDate = formatDateString(reportDate);
            fetchReport(formattedDate);
        };
        // eslint-disable-next-line
    },[reportData]);

    const handleDateChange = (newValue) => {
        if(newValue !== date) setReportData(null);
        setDate(newValue);
        const formattedDate = formatDateString(newValue);
        fetchReport(formattedDate);
    };

    return(
        <div style={styles.pageContainer}>
            <div style={styles.datePickerContainer}>
                <div style={styles.datePicker}>
                    <DatePicker
                        label="Report Date"
                        value={date}
                        onChange={handleDateChange}
                    />  
                </div>
            </div>
            <div style={styles.dataTableContainer}>
                <SearchReportTable data={reportData} />
            </div>
        </div>
    )
};

export default SearchPerformanceReport;