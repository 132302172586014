import React from 'react';
import { useQuery } from '@apollo/client';
import { ALL_ENDPOINTS } from '../../graphql/queries';
import Paper from '@material-ui/core/Paper';
import EmptyList from '../EmptyList';
import Loading from '../Loading';
import styles from './EndpointsList.css.js';
import EndpointOffersColumns from '../Tables/TableColumns/EndpointOffersColumns';
import TableIcons from '../Tables/TableIcons';
import MaterialTable from 'material-table';
import ActionButton from '../ActionButton';

const EndpointsList = ({ handleEdit }) => {
    const { loading, error, data } = useQuery(ALL_ENDPOINTS);

    if (loading) return <div style={styles.loadingTable}><Loading /></div>;

    if (error) return <div>{`Error occured fetching endpoint groups: ${error.message}`}</div>;

    const endpointsArray = data.fetchAllEndpoints.body;

    const dataArray = endpointsArray.map(endpoint => ({
        id: endpoint.id,
        name: endpoint.name,
        offer_page: endpoint.offer_page,
        url: endpoint.url,
        jump: endpoint.jump,
        four_button: endpoint.four_button,
        offers: endpoint.offers,
        restricted: endpoint.restricted,
        states: endpoint.states,
        question_text: endpoint.question_text,
        show_search: endpoint.show_search,
        selection_links: endpoint.selection_links && endpoint.selection_links.map((link) => ({
            id: link.id,
            qid: link.qid || link.id,
            cluster: link.cluster,
            group: link.group,
            name: link.name,
            text: link.text,
            offer_group: link.offer_group,
            url: link.url,
            jump: link.jump
        })),
        quick_link_group: endpoint.quick_link_group,
        google: endpoint.google
    }));

    return (
        <Paper style={styles.tablePaper} elevation={10}>
            {endpointsArray?.length > 0 ? 
                <MaterialTable
                    icons={TableIcons}
                    columns={EndpointOffersColumns()}
                    data={dataArray}
                    options={{
                        search: true,
                        toolbar: true,
                        sorting: true,
                        showTitle: false,
                        paging: false,
                        headerStyle: styles.offersHeader,
                        rowStyle: styles.offerRow,
                    }}
                    style={styles.endpointsTable}
                    actions={[
                        rowData => ({
                            icon: 'Edit', 
                            tooltip: 'Edit Endpoint', 
                            onClick: handleEdit,
                            disabled: !rowData.id
                        })
                    ]}
                    components={{
                        Action: props => (
                            <ActionButton 
                                icon='pen' 
                                bg='clear'
                                bgHover='ice' 
                                handleClick={(e) => handleEdit(e, props.data)}
                            />
                        ),
                    }}
                /> : <EmptyList />
            }
        </Paper>
    )
};

export default EndpointsList;