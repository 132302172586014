import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Loading from '../../../Loading';
import styles from '../Reports.css';
import { StyledTableCell, StyledTableRow, capitalizeTerm } from './TableDetails';

const createData = (obj) => {
    return {
        sub_id: obj.sub_id,
        type: obj.type,
        sessions: obj.total_sessions,
        page_views: obj.total_page_views,
        impressions: obj.total_impressions,
        paid_clicks: obj.total_paid_clicks,
        revenue: obj.total_revenue
    };
};

const SubIdReportTable = ({ data }) => {
    const [ rows, setRows ] = useState(null);

    useEffect(() => {
        setRows(null);
        if(!!data) {
            const rowArr = data.map((rawObj) => createData(rawObj));
            setRows(rowArr);
        };
        // eslint-disable-next-line
    },[data]);

    return(
        !!rows ? (
            <TableContainer component={Paper}>
                <Table size="small" sx={{minWidth: 700}} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>SubID</StyledTableCell>
                            <StyledTableCell>Device Type</StyledTableCell>
                            <StyledTableCell align="center">Total Sessions</StyledTableCell>
                            <StyledTableCell align="center">Total Page Views</StyledTableCell>
                            <StyledTableCell align="center">Total Impressions</StyledTableCell>
                            <StyledTableCell align="center">Total Paid Clicks</StyledTableCell>
                            <StyledTableCell align="center">Total Revenue</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    {rows.map((row) => (
                        <TableBody key={`${row.sub_id}=${row.type}`}>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">{row.sub_id}</StyledTableCell>
                                <StyledTableCell component="th" scope="row">{capitalizeTerm(row.type)}</StyledTableCell>
                                <StyledTableCell align="center">{row.sessions}</StyledTableCell>
                                <StyledTableCell align="center">{row.page_views}</StyledTableCell>
                                <StyledTableCell align="center">{row.impressions}</StyledTableCell>
                                <StyledTableCell align="center">{row.paid_clicks}</StyledTableCell>
                                <StyledTableCell align="center">{row.revenue}</StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    ))}
                </Table>
            </TableContainer>
        ) : (
            <div style={styles.loaderContainer}>
                <Loading />
            </div>
        )
    );
};

export default SubIdReportTable;