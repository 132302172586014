import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import styles from './ArticleButtonGroupDialog.css';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogBase from '../DialogBase';
import { useMutation } from '@apollo/client';
import { SAVE_ARTICLE } from '../../../graphql/mutations';
import { ARTICLES } from '../../../graphql/queries';
import InputLabel from '@material-ui/core/InputLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LittleIcon = ({icon, color, handleClick}) => {
    const [ isHovering, setHovering ] = useState(false);
    
    const iconStyle = Object.assign({},
        styles.litteIcon,
        {color: color},
        isHovering && styles.iconHover
    );

    return(
        <FontAwesomeIcon
            icon={['fal', icon]}
            size='1x'
            style={iconStyle}
            onClick={handleClick}
            onMouseEnter={() => setHovering(true)}
			onMouseLeave={() => setHovering(false)}
        />
    );
};

const ArticleButtonGroupDialog = () => {
    const { addToast } = useToasts();
    const { dispatchSelection, selectionState } = useContext(AppContext);
    const { article, button_group } = selectionState;
    const blankButton = { button: '' };
    const [ buttonState, setButtonState ] = useState(!!button_group ? button_group.buttons.map(val => ({ button: val })) : [{button: ''}, {button: ''}, {button: ''}]);
    const [ isSaving, setSaving ] = useState(false);
    const [ selectedActive, setSelectedActive ] = useState(!!button_group ? button_group.group_key === article.active_button_group_key : false);
    const { handleSubmit } = useForm({ mode: 'onChange', reValidateMode: 'onChange', });

    const close = () => {
        dispatchSelection({ type: 'CLEAR_SELECTION' });
        dispatchSelection({ type: 'CLOSE_BUTTON_GROUP_DIALOG' });
    };

    const handleRedirect = (data) => {
        if (data.saveArticle.success) {
            addToast('Successfully SAVED Article!', { appearance: 'success', autoDismiss: true });
            setSaving(false);
        } else {
            console.error(data.message);
            addToast('Failed!', { appearance: 'error', autoDismiss: true });
            setSaving(false);
        };
        close();
    };

    const [saveArticle] = useMutation(
        SAVE_ARTICLE, {
        refetchQueries: [{ query: ARTICLES }],
        onCompleted: handleRedirect
    });

    const handleAddInputClick = () => {
        if(buttonState.length > 6) return;
        setButtonState([...buttonState, { ...blankButton }]);
    };

    const handleRemoveInputClick = (idx) => {
        if(buttonState.length < 4) return;
        let newState = buttonState.filter((el, i) => i !== idx);
        setButtonState(newState);
    };

    const handleButtonChange = (e) => {
        const updatedButtons = [...buttonState];
        updatedButtons[Number(e.target.id)]['button'] = e.target.value;
        setButtonState(updatedButtons);
    };

    const handleCheckboxClick = () => {
        setSelectedActive(!selectedActive);
    };

    const showDelete = (e) => {
        e.stopPropagation();
        dispatchSelection({ 
            type: 'DELETE_SELECTION', 
            payload: { 
                type: 'article_button-group', 
                article: article,
                group: button_group
            } 
        });
    };

    const handleSubmission = () => {
        const { key, title, header, sub_text, button_title } = article;
        const buttonValues = buttonState.map((input) => (
            input.button
        ));
        console.log('start:', article)
        // If it already has a key, use it.
        // If not, make one with timestamp
        const buttonGroupKey = !!button_group?.group_key ? button_group.group_key : `${article.key.toLowerCase()}-button_group-${new Date().getTime()}`;
        let buttonGroupArr = article.button_groups.map((groupObj) => ({
            group_key: groupObj.group_key,
            buttons: groupObj.buttons
        }));
        // const setButtonGroup = () => {
            if(!!button_group?.group_key) {
                // find and replace group with new one
                const index = buttonGroupArr.findIndex((obj) => {
                    return obj.group_key === button_group.group_key
                });
                buttonGroupArr[index] = {
                    group_key: buttonGroupArr[index].group_key,
                    buttons: buttonValues
                };
            } else {
                // Push new group into array
                const newGroup = {
                    group_key: buttonGroupKey ,
                    buttons: buttonValues
                };
                buttonGroupArr.push(newGroup);
            };
        // };

        const sendData = {
            key,
            title,
            header,
            sub_text,
            button_title,
            active_button_group_key: selectedActive ? buttonGroupKey : article.active_button_group_key,
	        button_groups: buttonGroupArr
        };
        saveArticle({ variables: { article: sendData } });
    };

    const label = { inputProps: { 'aria-label': 'Checkbox for active group' } };
    return (
        <DialogBase
            open={selectionState.showButtonGroupDialog}
            close={close}
            title={!!button_group ? 'Edit Button Group:' : 'Add New Button Group:'}
            isSaving={isSaving}
            handleSubmission={handleSubmit(handleSubmission)}
            handleDelete={!!button_group?.group_key ? showDelete : null}
        >
            <div style={styles.toggleContainer}>
                <FormControlLabel
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()} 
                    control={
                        <Checkbox
                            {...label}
                            checked={selectedActive}
                            onChange={handleCheckboxClick}
                            sx={{
                                '& .MuiSvgIcon-root': { fontSize: 28 },
                                color: '#10406B',
                                '&.Mui-checked': {
                                    color: '#10406B',
                                },
                            }}
                        />
                    } 
                    label='Set as Active Button Group?' 
                />
            </div>
            <div style={styles.buttonInputContainer}>
                <div style={styles.buttonInputAddRow}>
                    <InputLabel id='pid-input-label' style={styles.buttonInputLabel}>Buttons</InputLabel>
                    <LittleIcon 
                        icon='plus-circle' 
                        color='rgba(67, 150, 180, 0.75)' 
                        handleClick={handleAddInputClick}
                    />
                </div>
                { buttonState.map((val, idx) => {
                    const inputId = `button-${idx}`;
                    return (
                        <div key={`button-input__${idx}`} style={styles.buttonInputRow}>
                            <div style={styles.buttonInputRowNumber}>{`${idx + 1}.)`}</div>
                            <TextField
                                id={`${idx}`}
                                name={inputId}
                                fullWidth
                                margin='normal'
                                value={buttonState[idx].button}
                                onChange={handleButtonChange}
                            />
                            {idx !== 0 && (
                                <LittleIcon 
                                    icon='minus-circle' 
                                    color='red' 
                                    handleClick={() => handleRemoveInputClick(idx)}
                                />
                            )}
                        </div>
                    );
                })
                }
            </div>
        </DialogBase>
    );
};

export default ArticleButtonGroupDialog;