import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import TextField from '@material-ui/core/TextField';
import { capitalizeValue } from '../../../utils/helpers';
import styles from './EndpointDialog.css.js';

const ButtonInput = ({ idx, offerData, register }) => (
    <div style={styles.buttonInputsContainer}>
        <TextField
            name={`buttonText${idx+1}`}
            label={`Button Text ${idx+1}`}
            fullWidth
            autoFocus
            defaultValue={capitalizeValue(offerData?.[idx]?.text ?? '')}
            inputRef={ register({ required: 'a value is required!' }) }
            style={styles.textInput}
        />
        <TextField
            name={`buttonUrl${idx+1}`}
            label={`Button URL ${idx+1}`}
            fullWidth
            autoFocus
            defaultValue={capitalizeValue(offerData?.[idx]?.url ?? '')}
            inputRef={ register({ required: 'a value is required!' }) }
            style={styles.textInput}
        />
        <TextField
            name={`buttonJump${idx+1}`}
            label={`Button Jump ${idx+1}`}
            fullWidth
            autoFocus
            defaultValue={capitalizeValue(offerData?.[idx]?.jump ?? '')}
            inputRef={ register({ required: 'a value is required!' }) }
            style={styles.textInput}
        />
    </div>
);

const FourButtonInputs = ({ register }) => {
    const { selectionState } = useContext(AppContext);
    const { endpoint } = selectionState;

    return (
        <div style={styles.fourButtonFields}>
            <ButtonInput idx={0} offerData={endpoint.four_button} register={register}/>
            <ButtonInput idx={1} offerData={endpoint.four_button} register={register}/>
            <ButtonInput idx={2} offerData={endpoint.four_button} register={register}/>
            <ButtonInput idx={3} offerData={endpoint.four_button} register={register}/>
        </div>
    )
};

export default FourButtonInputs;