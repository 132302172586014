import React, { useContext } from 'react';
import { AppContext } from '../../context';
import  { useAuth0 } from '@auth0/auth0-react';
import clsx from 'clsx';
import BK_logo from '../../assets/bk_letters.png';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import OfferingsTabs from './TabGroups/OfferingsTabs';
import MarketPlaceTabs from './TabGroups/MarketPlaceTabs';
import SubCategoryTabs from './TabGroups/SubCategoryTabs';
import ReportsTabs from './TabGroups/ReportsTabs';
import ArticlesTabs from './TabGroups/ArticlesTabs';
import UserIcon from './UserIcon';
import styles from './TopAppBar.css.js';

const TopAppBar = ({ open, setOpen }) => {
    const { user } = useAuth0();
    const classes = styles.materialStyle();
    const { appState } = useContext(AppContext);
    const { offeringsTabActive, offeringsTabValue, reportsTabActive, articlesTabActive } = appState;

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    return (        
        <AppBar 
            position='fixed' 
            className={clsx(classes.appBar, { [classes.appBarShift]: open })}
            elevation={3}
        >
            <Toolbar>
                <IconButton
                    color='inherit'
                    aria-label='open drawer'
                    onClick={handleDrawerOpen}
                    edge='start'
                    className={clsx(classes.menuButton, { [classes.hide]: open })}
                    >
                    <img src={BK_logo} alt='home_banner_img' style={styles.logo} />
                </IconButton>
                <div style={styles.barContent}>
                    <div style={{...styles.topSideContent, marginLeft: '100px'}}>
                        {offeringsTabActive && (<OfferingsTabs />)}
                        {reportsTabActive && (<ReportsTabs />)}
                        {articlesTabActive && (<ArticlesTabs />)}
                    </div>
                    <div style={styles.topSideContent}>
                        {offeringsTabActive && offeringsTabValue < 1 && <SubCategoryTabs />}
                        {offeringsTabActive && offeringsTabValue === 3 && (<MarketPlaceTabs />)}
                        {user && (<UserIcon />)}
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default TopAppBar;