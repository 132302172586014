import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'rgba(16, 64, 107, 0.45)',
      color: 'rgb(16, 64, 107)',
      padding: '10px'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16
    }
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'rgb(240,248,255)',
        borderBottom: 'solid 0.5px #10406B'
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const capitalizeTerm = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export {
    StyledTableCell,
    StyledTableRow,
    capitalizeTerm
}