import gql from 'graphql-tag';

const SAVE_DOMAIN = gql`
    mutation saveDomain($domain: NewProgramDomain) {
        saveDomain(domain: $domain) {
            success
            status
            message
        }
    }
`;

const SAVE_DIRECT_DOMAIN = gql`
    mutation saveDirectDomain($domain: NewDirectDomain) {
        saveDirectDomain(domain: $domain) {
            success
            status
            message
        }
    }
`;

const SAVE_PROGRAM_RECORD = gql`
    mutation saveProgramRecord($record: NewRecord) {
        saveProgramRecord(record: $record) {
            success
            status
            message
        }
    }
`;

const DELETE_PROGRAM_RECORD = gql`
    mutation deleteProgramRecord($id: ID) {
        deleteProgramRecord(id: $id) {
            success
            status
            message
        }
    }
`;

const DELETE_DOMAIN = gql`
    mutation deleteDomain($id: ID) {
        deleteDomain(id: $id) {
            success
            status
            message
        }
    }
`;

const DELETE_DIRECT_DOMAIN = gql`
    mutation deleteDirectDomain($id: ID) {
        deleteDirectDomain(id: $id) {
            success
            status
            message
        }
    }
`;

const SAVE_PROGRAM_PATH = gql`
	mutation SaveProgramPath($program: NewProgramPath) {
		saveProgramPath(program: $program) {
			success
			status
			message
		}
	}
`;

const DELETE_PROGRAM_PATH = gql`
	mutation DeleteProgramPath($id: ID) {
		deleteProgramPath(id: $id) {
			success
			status
			message
		}
	}
`;

const SAVE_ENDPOINT = gql`
	mutation SaveEndpoint($endpoint: NewEndpoint) {
		saveEndpoint(endpoint: $endpoint) {
			success
			status
			message
		}
	}
`;

const DELETE_ENDPOINT = gql`
	mutation DeleteEndpoint($id: ID) {
		deleteEndpoint(id: $id) {
			success
			status
			message
		}
	}
`;

const SAVE_ENDPOINT_GROUP = gql`
	mutation SaveEndpointGroup($group: NewGroup) {
		saveEndpointGroup(group: $group) {
			success
			status
			message
		}
	}
`;

const DELETE_ENDPOINT_GROUP = gql`
	mutation DeleteEndpointGroup($id: ID) {
		deleteEndpointGroup(id: $id) {
			success
			status
			message
		}
	}
`;

const SAVE_QUICK_LINK = gql`
	mutation SaveQuickLink($quick_link: NewQuickLink) {
		saveQuickLink(quick_link: $quick_link) {
			success
			status
			message
		}
	}
`;

const DELETE_QUICK_LINK = gql`
	mutation DeleteQuickLink($id: ID) {
		deleteQuickLink(id: $id) {
			success
			status
			message
		}
	}
`;

const SAVE_PUSH_PROVIDERS = gql`
	mutation SetPushProviders($providerList: NewPushList) {
		setPushProviders(providerList: $providerList) {
			success
			status
			message
		}
	}
`;

const SAVE_ARTICLE = gql `
	mutation SaveArticle($article: NewArticle) {
		saveArticle(article: $article) {
			success
			status
			message
		}
	}
`;

const DELETE_ARTICLE = gql `
    mutation DeleteArticle($id: ID) {
        deleteArticle(id: $id) {
            success
            status
            message
        }
    }
`;

const SAVE_ARTICLE_OFFER = gql `
	mutation SaveArticleOffer($articleOffer: NewArticleOffer) {
		saveArticleOffer(articleOffer: $articleOffer) {
			success
			status
			message
		}
	}
`;

const DELETE_ARTICLE_OFFER = gql `
    mutation DeleteArticleOffer($id: ID) {
        deleteArticleOffer(id: $id) {
            success
            status
            message
        }
    }
`;

const SAVE_OFFER_BLOCK = gql `
	mutation saveOfferBlock($offerBlock: NewOfferBlock) {
		saveOfferBlock(offerBlock: $offerBlock) {
			success
			status
			message
		}
	}
`;

const DELETE_OFFER_BLOCK = gql `
    mutation DeleteOfferBlock($id: ID) {
        deleteOfferBlock(id: $id) {
            success
            status
            message
        }
    }
`;

const SAVE_DIRECT_OFFER_BLOCK = gql `
    mutation SaveDirectOfferBlock($offer_block_id: ID) {
        saveDirectOfferBlock(offer_block_id: $offer_block_id) {
            success
            status
            message
        }
    }
`;

export {
    SAVE_DOMAIN,
    DELETE_DOMAIN,
	SAVE_DIRECT_DOMAIN,
	DELETE_DIRECT_DOMAIN,
	SAVE_PROGRAM_RECORD,
	DELETE_PROGRAM_RECORD,
    SAVE_PROGRAM_PATH,
    DELETE_PROGRAM_PATH,
    SAVE_ENDPOINT,
	DELETE_ENDPOINT,
	SAVE_ENDPOINT_GROUP,
	DELETE_ENDPOINT_GROUP,
	SAVE_QUICK_LINK,
	DELETE_QUICK_LINK,
	SAVE_PUSH_PROVIDERS,
	SAVE_ARTICLE,
    DELETE_ARTICLE,
	SAVE_ARTICLE_OFFER,
	DELETE_ARTICLE_OFFER,
	SAVE_OFFER_BLOCK,
	DELETE_OFFER_BLOCK,
	SAVE_DIRECT_OFFER_BLOCK
}