import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../context';
import AccordionItem from '../../../AccordionItem';
import styles from './DomainItem.css.js';
import DomainItemSummary from './DomainItemSummary';
import DomainItemContent from './DomainItemContent';
import DomainDialog from '../../../Forms/DomainDialog';

const DomainItem = ({ item }) => {
	const { dispatchSelection } = useContext(AppContext);
	const [ showDialog, setShowDialog ] = useState(false);

	const handleEditClick = (e) => {
        e.stopPropagation();
        dispatchSelection({ type: 'EDIT_DOMAIN', payload: item });
        setShowDialog(true);
	};

	const handleClose = () => {
        dispatchSelection({ type: 'CLEAR_SELECTION' });
        setShowDialog(false);
	};
	
	return (
		<div style={styles.fullListItem}>
			<AccordionItem 
				idx={item.idx}
				expandStyle='expanded' 
				Summary={(props) => 
					<DomainItemSummary
						programDomain={item}
						onEdit={handleEditClick}
						{...props}
					/>
				}
				content={showDialog ?
					<DomainDialog open={showDialog} close={handleClose} /> :
					<DomainItemContent programDomain={item} />
				}
			/>
		</div>
	);
}

export default DomainItem;