import React, { useRef, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Grow from '@material-ui/core/Grow';
import Tab from '@material-ui/core/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Domains.css.js';

const VerticalsTabs = ({ value, handleSelection }) => {
    const tabsActions = useRef();

    useEffect(() => {
        tabsActions.current.updateIndicator()
        // setTimeout(() => tabsActions.current.updateIndicator(), 200);
    }, []);

    const a11yProps = (index) => {
		return {
			id: `vertical-tab-${index}`,
			className: 'vertical-tab',
			'aria-controls': `simple-tabpanel-${index}`,
		};
	};
    const VerticalTab = (props) => (
        <Tab 
            style={styles.verticalTab}
            label={<div style={styles.tabTitle}>{props.label}</div>}
            {...props}
        />
    );

    return (
        <Grow in={true}>
            <Tabs 
                value={value} 
                onChange={handleSelection} 
                aria-label='verticals tabs' 
                centered={true}
                action={tabsActions}
            >
                <VerticalTab label='Auto Loans' icon={<FontAwesomeIcon icon={['fal', 'car-side']} style={styles.tabIcon} />} {...a11yProps(0)}/>
                <VerticalTab label='Credit Cards' icon={<FontAwesomeIcon icon={['fal', 'credit-card']} style={styles.tabIcon} />} {...a11yProps(1)}/>
                <VerticalTab label='Home Loans' icon={<FontAwesomeIcon icon={['fal', 'home']} style={styles.tabIcon} />} {...a11yProps(2)}/>
                <VerticalTab label='Personal Loans' icon={<FontAwesomeIcon icon={['fal', 'hand-holding-usd']} style={styles.tabIcon} />} {...a11yProps(3)}/>
                <VerticalTab label='Direct Offers' icon={<FontAwesomeIcon icon={['fal', 'location']} style={styles.tabIcon} />} {...a11yProps(4)}/>
            </Tabs>
        </Grow>
    )
};

export default VerticalsTabs;