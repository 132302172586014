import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { useToasts } from 'react-toast-notifications';
import { useMutation } from '@apollo/client';
import { DELETE_DIRECT_DOMAIN } from '../../../graphql/mutations';
import { DIRECT_DOMAINS } from '../../../graphql/queries';
import styles from './DeleteDialogs.css.js';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const DeleteDirectDomain = () => {
    const { addToast } = useToasts();
    const { selectionState, dispatchSelection } = useContext(AppContext);
    const { delete_doc } = selectionState;
    const [ isSaving, setIsSaving ] = useState(false);
    const [ isHovering, setHovering ] = useState(false);

    const closeDialog = () => {
        dispatchSelection({ type: 'CLOSE_DIRECT_DOMAIN_DIALOG' });
        dispatchSelection({ type: 'CLOSE_DELETE' });
    };

    const handleRedirect = (data) => {
        addToast(data.deleteDirectDomain.message, { appearance: 'success', autoDismiss: true });
        setIsSaving(false);
        closeDialog();
    };
    const handleError = (error) => {
        console.error(error);
        addToast(error?.message ?? 'Error!', { appearance: 'error', autoDismiss: true });
        setIsSaving(false);
        closeDialog();
    };

    const [deleteDirectDomain] = useMutation(
        DELETE_DIRECT_DOMAIN, {
            refetchQueries: [{ 
                query: DIRECT_DOMAINS
            }],
            onCompleted: handleRedirect,
            onError: handleError,
        }
    );

    const handleConfirmClick = () => {
        setIsSaving(true);
        deleteDirectDomain({ variables: { id: delete_doc.item_id } });
    };

    const buttonStyle = Object.assign({},
        styles.submitButton,
        isHovering && styles.deleteHover
    );

    return (
        <div style={styles.deleteDialogContent}>
            <div style={styles.textContainer}>
                <span style={styles.importantText}>Think really hard for a second! <br/>This action is permanant!</span>
                <span style={styles.dialogText}>Are you sure you want to remove the</span>
                <span style={styles.dialogText}>
                    entire <span style={styles.boldText}>{`${delete_doc?.title} `}</span> Domain,
                    and all of the associated program records?!!!
                </span>
            </div>
            <ButtonGroup style={styles.formButtons}>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button 
                    onClick={handleConfirmClick} 
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                    style={buttonStyle}
                >
                    {isSaving ? <CircularProgress size={24} style={styles.buttonProgress} /> : 'BOOM!'}
                </Button>
            </ButtonGroup>
        </div>
    );
}

export default DeleteDirectDomain;