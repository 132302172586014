import React from 'react';
import styles from '../Offerings.css.js';
import ActionBar from '../../../ActionBar';
import OptInGroupList from '../../../OptinGroupList';

const OptInGroups = () => {

    const handleAddClick = () => {
        console.log('clicked ADD!');
    };

    const nullFunction = () => null;

    return (
        <>
            <ActionBar text='Opt-In Offer' title='GROUPS' handleClick={handleAddClick} size='Lg'/>
            <div style={{...styles.content, marginTop: '20px'}}>
                <OptInGroupList
                    type='debt'
                    isSelection={false}
                    activeGroup='N/A' 
                    setGroup={nullFunction}
                    setName={nullFunction}
                />
                <OptInGroupList
                    type='checking'
                    isSelection={false}
                    activeGroup='N/A' 
                    setGroup={nullFunction}
                    setName={nullFunction}
                />
            </div>
        </>
    )
};

export default OptInGroups;