import React, { useContext, useState } from 'react';
import { AppContext } from '../../../context';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccordionItem from '../../AccordionItem';
import styles from './Programs.css.js';
import ProgramItemContent from './ProgramItemContent';

const ItemSummary = props => {
	const { programDomain, isExpanded } = props;
	const [ isHovering, setHovering ] = useState(false);
    const { dispatchSelection } = useContext(AppContext);

	const handleEditClick = (e) => {
        e.stopPropagation();
        dispatchSelection({ type: 'EDIT_DIRECT_DOMAIN_DIALOG', payload: programDomain });
	};
	
	const editButtonStyle = Object.assign({},
        styles.editButton,
        isHovering && styles.hoverRow
	);

	return (
		<ListItem dense style={styles.summaryItem} >
			<ListItemAvatar>
			{isExpanded ? 
				<div
					onMouseEnter={() => setHovering(true)}
					onMouseLeave={() => setHovering(false)}
					style={editButtonStyle}
					onClick={handleEditClick}
				>
					<FontAwesomeIcon style={styles.editIcon} icon={['fal', 'pen']} />
				</div> :
				<Avatar
					alt={`Avatar n°${programDomain.title}`}
					src={`${programDomain.avatar === 'N/A' ? 'https://bluekeel-hosted-assets.s3.amazonaws.com/bk_logo.png' : programDomain.avatar}`}
					variant='rounded'
				/>
				}
			</ListItemAvatar>
			<Tooltip 
				title={programDomain?.pid?.length > 1 ? 
					programDomain.pid.map((pid, idx) => (
						<span key={`pid-tag-${idx}`} style={styles.tooltip}>{pid}</span>
					)) :
					<span style={styles.tooltip}>{programDomain.pid}</span>
				}
				arrow={true}
				placement='top-start'
			>
				<div style={styles.tooltipText}>PIDs</div>
			</Tooltip>
			<div style={styles.titleContainer}>
				<div style={styles.titlePrime}>
					{programDomain.title}
				</div>
				<div style={styles.titleSecond}>
					{programDomain.description}
				</div>
			</div>
            {programDomain.accepts.length !== 0 && (programDomain.accepts.map((accepting, i) => (
                    <div style={styles.acceptableMedia} key={i}>
                        <FontAwesomeIcon icon={accepting.avatar} size='1x' />
                        <span style={styles.mediaType}>
                            {accepting.value}
                        </span>
                    </div>
                ))
            )}
		</ListItem>
	);
};

const ProgramItem = ({ item }) => (
    <div style={styles.fullListItem}>
        <AccordionItem 
            idx={item.idx}
            expandStyle='expanded' 
            Summary={(props) => <ItemSummary programDomain={item} {...props} />}
            content={<ProgramItemContent programDomain={item} />}
        />
    </div>
);

export default ProgramItem;