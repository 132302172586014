import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useMutation } from '@apollo/client';
import { SAVE_PUSH_PROVIDERS } from '../../../graphql/mutations';
import { PUSH_PROVIDER_LIST } from '../../../graphql/queries';
import styles from './PushProviderDialog.css.js';
import DialogBase from '../DialogBase/DialogBase';
import PushProsLogo from '../../../assets/push-pros-logo.png';
import PushnamiLogo from '../../../assets/pushnami_logo.png';

const PushProviderDialog = ({ open, close }) => {
    const { addToast } = useToasts();
    const { selectionState } = useContext(AppContext);
    const { push_providers } = selectionState;
    const [ isSaving, setIsSaving ] = useState(false);
    const [ isError, setIsError ] = useState(false);
    const { register, errors, handleSubmit } = useForm({ mode: 'onBlur', reValidateMode: 'onChange', });
    
    const handleRedirect = (data) => {
        addToast(data.setPushProviders.message, { appearance: 'success', autoDismiss: true });
        setIsSaving(false);
        close();
    };
    const handleError = (error) => {
        console.error(error);
        addToast(error?.message ?? 'Error!', { appearance: 'error', autoDismiss: true });
        setIsSaving(false);
        close();
    };

    const [setPushProviders] = useMutation(
        SAVE_PUSH_PROVIDERS, {
            refetchQueries: [ { query: PUSH_PROVIDER_LIST } ],
            onCompleted: handleRedirect,
            onError: handleError,
        }
    );

    const handleSubmission = (formData) => {
        const pushProsValue = Number(formData.PushPros);
        const pushnamiValue = Number(formData.Pushnami);
        if(pushProsValue + pushnamiValue !== 100){
            setIsError(true);
            return;
        };
        setIsError(false);
        setIsSaving(true);
        setPushProviders({ variables: { 
            providerList: {
                push_pros: pushProsValue,
                pushnami: pushnamiValue
            }
        } });
    };

    const handleUnfocus = () => {
        setIsError(false);
    };

    return (
        <div style={styles.dialogParent}>
            <DialogBase
                open={open}
                close={close}
                title='Set Push Provider Distribution:'
                isSaving={isSaving}
                handleSubmission={handleSubmit(handleSubmission)}
                handleDelete={null}
            >
                <div style={styles.mainRow}>
                    <div style={styles.textFields}>
                        <div style={styles.imageInputContainer}>
                            <Paper style={{...styles.imagePaper, ...styles.imageBgPushPros}} elevation={10}>
                                <img src={PushProsLogo} alt='provider_img' style={styles.providerImage} />
                            </Paper>
                            <TextField
                                name='PushPros'
                                label='%'
                                fullWidth
                                autoFocus
                                error={!!errors['PushPros'] || isError}
                                defaultValue={push_providers.push_pros}
                                inputRef={ register({ required: 'a "%" value is required!' }) }
                                style={styles.textInput}
                                onBlur={handleUnfocus}
                            />
                        </div>
                        <div style={styles.imageInputContainer}>
                            <Paper style={{...styles.imagePaper, ...styles.imageBgPushnami}} elevation={10}>
                                <img src={PushnamiLogo} alt='provider_img' style={styles.providerImage} />
                            </Paper>
                            <TextField
                                name='Pushnami'
                                label='%'
                                fullWidth
                                autoFocus
                                error={!!errors['Pushnami'] || isError}
                                defaultValue={push_providers.pushnami}
                                inputRef={ register({ required: 'a "%" value is required!' }) }
                                style={styles.textInput}
                                onBlur={handleUnfocus}
                            />
                        </div>
                        {isError && <FormHelperText error>The sum of all values must equal 100%</FormHelperText>}
                    </div>
                </div>
            </DialogBase>
        </div>
    );
}

export default PushProviderDialog;