export const stateList = [
    {
        value: 'Alabama',
        text: 'Alabama',
        key: '1'
    },
	{
        value: 'Alaska',
        text: 'Alaska',
        key: '2'
    },
	{
        value: 'Arizona',
        text: 'Arizona',
        key: '3'
    },
	{
        value: 'Arkansas',
        text: 'Arkansas',
        key: '4'
    },
	{
        value: 'California',
        text: 'California',
        key: '5'
    },
	{
        value: 'Colorado',
        text: 'Colorado',
        key: '6'
    },
	{
        value: 'Connecticut',
        text: 'Connecticut',
        key: '7'
    },
	{
        value: 'Delaware',
        text: 'Delaware',
        key: '8'
    },
	{
        value: 'District Of Columbia',
        text: 'District',
        key: '9'
    },
	{
        value: 'Florida',
        text: 'Florida',
        key: '10'
    },
	{
        value: 'Georgia',
        text: 'Georgia',
        key: '11'
    },
	{
        value: 'Hawaii',
        text: 'Hawaii',
        key: '12'
    },
	{
        value: 'Idaho',
        text: 'Idaho',
        key: '13'
    },
	{
        value: 'Illinois',
        text: 'Illinois',
        key: '14'
    },
	{
        value: 'Indiana',
        text: 'Indiana',
        key: '15'
    },
	{
        value: 'Iowa',
        text: 'Iowa',
        key: '16'
    },
	{
        value: 'Kansas',
        text: 'Kansas',
        key: '17'
    },
	{
        value: 'Kentucky',
        text: 'Kentucky',
        key: '18'
    },
	{
        value: 'Louisiana',
        text: 'Louisiana',
        key: '19'
    },
	{
        value: 'Maine',
        text: 'Maine',
        key: '20'
    },
	{
        value: 'Maryland',
        text: 'Maryland',
        key: '21'
    },
	{
        value: 'Massachusetts',
        text: 'Massachusetts',
        key: '22'
    },
	{
        value: 'Michigan',
        text: 'Michigan',
        key: '23'
    },
	{
        value: 'Minnesota',
        text: 'Minnesota',
        key: '24'
    },
	{
        value: 'Mississippi',
        text: 'Mississippi',
        key: '25'
    },
	{
        value: 'Missouri',
        text: 'Missouri',
        key: '26'
    },
	{
        value: 'Montana',
        text: 'Montana',
        key: '27'
    },
	{
        value: 'Nebraska',
        text: 'Nebraska',
        key: '28'
    },
	{
        value: 'Nevada',
        text: 'Nevada',
        key: '29'
    },
	{
        value: 'New Hampshire',
        text: 'New Hampshire',
        key: '30'
    },
	{
        value: 'New Jersey',
        text: 'New Jersey',
        key: '31'
    },
	{
        value: 'New Mexico',
        text: 'New Mexico',
        key: '32'
    },
	{
        value: 'New York',
        text: 'New York',
        key: '33'
    },
	{
        value: 'North Carolina',
        text: 'North Carolina',
        key: '34'
    },
	{
        value: 'North Dakota',
        text: 'North Dakota',
        key: '35'
    },
	{
        value: 'Ohio',
        text: 'Ohio',
        key: '36'
    },
	{
        value: 'Oklahoma',
        text: 'Oklahoma',
        key: '37'
    },
	{
        value: 'Oregon',
        text: 'Oregon',
        key: '38'
    },
	{
        value: 'Pennsylvania',
        text: 'Pennsylvania',
        key: '39'
    },
	{
        value: 'Rhode Island',
        text: 'Rhode Island',
        key: '40'
    },
	{
        value: 'South Carolina',
        text: 'South Carolina',
        key: '41'
    },
	{
        value: 'South Dakota',
        text: 'South Dakota',
        key: '42'
    },
	{
        value: 'Tennessee',
        text: 'Tennessee',
        key: '43'
    },
	{
        value: 'Texas',
        text: 'Texas',
        key: '44'
    },
	{
        value: 'Utah',
        text: 'Utah',
        key: '45'
    },
	{
        value: 'Vermont',
        text: 'Vermont',
        key: '46'
    },
	{
        value: 'Virginia',
        text: 'Virginia',
        key: '47'
    },
	{
        value: 'Washington',
        text: 'Washington',
        key: '48'
    },
	{
        value: 'West Virginia',
        text: 'West Virginia',
        key: '49'
    },
	{
        value: 'Wisconsin',
        text: 'Wisconsin',
        key: '50'
    },
	{
        value: 'Wyoming',
        text: 'Wyoming',
        key: '51'
    },
]