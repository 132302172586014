import React from 'react';
import styles from './CustomColumns.css.js';
import { makePagePretty, titleCase } from '../../../utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipCell from '../CustomCells/TooltipCell';

const GroupColumns = () => ([
	{ 
		title: 'Name', 
        field: 'name',
        cellStyle: {...styles.titleCell, width: '30%'}, 
	},
	{ 
		title: 'Page Type', 
        field: 'offer_page',
        align: 'left',
        cellStyle: {...styles.tableCell}, 
		render: rowData => {
            const linkButtons = rowData?.selection_links?.map((link) => (link.text)) ?? [];
            return (
                rowData.offer_page === 'selection' ? 
                <span style={styles.inlineSpan}>QuickLinks: <TooltipCell linkArray={linkButtons} selector='text'/></span> :
                makePagePretty(rowData.offer_page) 
            )
        }
	},
	{ 
		title: 'Link', 
        field: 'link',
        align: 'left',
        cellStyle: styles.tableCell,
		render: rowData => (
            rowData.offer_page === 'mNet' ?
            <span style={styles.inlineSpan}>{titleCase(rowData.link)}</span> :
            <TooltipCell linkArray={[rowData.link]} selector='url'/>
        )
	},
	{ 
		title: 'Jump', 
        field: 'jump',
        align: 'left',
        cellStyle: styles.tableCell, 
		render: rowData => <TooltipCell linkArray={[rowData.jump]} selector='jump'/>
	},
	{ 
		title: 'Fenced', 
        field: 'jump',
        align: 'left',
        cellStyle: styles.tableCell, 
		render: rowData => (
            rowData.restricted ?
            <TooltipCell linkArray={rowData.states} selector='states'/> :
            <FontAwesomeIcon style={styles.emptyColumn} icon={['fal', 'empty-set']} />
        )
	},
	{ 
		title: 'Usage %', 
        field: 'usage',
        align: 'left',
        cellStyle: styles.tableCell, 
		render: rowData => (
            (rowData.usage && rowData.usage !== 0) ? 
			rowData.usage : 
			<FontAwesomeIcon style={styles.emptyColumn} icon={['fal', 'empty-set']} />
        )
	},
]);

export default GroupColumns;