import React from 'react';
import styles from './PathItemOfferList.css.js';
import  { titleCase } from '../../../../utils/helpers';
import { useQuery } from '@apollo/client';
import { SELECTION_LOG_COUNT } from '../../../../graphql/queries';
import Loading from '../../../Loading';

const LinkItem = ({ linkData, program, range }) => {
    const { loading, error, data } = useQuery(SELECTION_LOG_COUNT, { 
        variables: { query: {
            program_id: program.id,
            link_id: linkData.id,
            keyword: linkData.text,
            range: range
        } }
    });

    if (loading) return <Loading />;

    if (error) return <div>{`Error occured fetching query count: ${error.message}`}</div>;

    return (
        <div style={styles.linkItem}>
            <div style={styles.linkButton}>{`${titleCase(linkData.text)}`}</div>
            <div style={styles.linkClicksText}>{`${data.fetchSelectionCount.body} clicks`}</div>
        </div>
    )

};

const LinkListCountDisplay = ({ linkList, offerId, program, rowData, range }) => {
    return (
        <div style={styles.linksMain}>
            {linkList.map((link, idx) => (
                <LinkItem 
                    key={`link_${idx}`} 
                    linkData={{...link, idx, offerId}} 
                    program={program} 
                    rowData={rowData}
                    range={range}
                />
            ))}
        </div>
    )
};

export default LinkListCountDisplay;