import axios from 'axios';

export const getReportUrl = async(dateString, days, type, uri) => {
    try {
        const genReportIdUrl = `${uri}/system1/report/id/${dateString}/${days}/${type} `;
        console.log('url:', genReportIdUrl);
        // Generate Report
        const generateReportId = await axios({
            method: 'GET',
            url: genReportIdUrl
        });
        const reportId = generateReportId?.data;
        console.log('Report_id:', reportId);
        const response = await new Promise((resolve) => {
            let reportStatus = '';
            // Loop and check status every 10 seconds
            let looping = setInterval(async function() {
                const statusRes = await checkReportStatus(reportId, uri);
                reportStatus = statusRes.report_status;
                if(reportStatus === 'FAILED') {
                    clearInterval(looping);
                    resolve({ status: 'FAILED', message: statusRes?.message ?? 'There was an error downloading report - check console.' });
                    return;
                };
                if(reportStatus === 'SUCCESS'){
                    clearInterval(looping);
                    resolve({ status: 'SUCCESS', message: statusRes.content_url });
                    return;
                };
                if(reportStatus === 'NO CONTENT'){
                    clearInterval(looping);
                    resolve({ status: 'NO CONTENT', message: 'This report has no content. Pick a different date range.' });
                    return;
                };
            }, 10000);
        });
        return response;
    } catch(err) {
        console.log('Error getting System1 report url:', err);
        return { status: 'ERROR', message: err.message };
    };
};

const checkReportStatus = async(report_id, uri) => {
    try{
        const checkStatusUrl = `${uri}/system1/report/status/${report_id} `;
        const reportStatus = await axios({
            method: 'GET',
            url: checkStatusUrl
        });
        return reportStatus.data;
    } catch(err) {
        console.log('Error checking Status:', err);
        return { report_status: 'FAILED', message: err.message};
    }
};