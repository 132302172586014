import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './AccodionItem.css.js';

const AccordionItem = ({ idx, expandStyle, Summary, content }) => {
    const [ expanded, setExpanded ] = useState(false);
    const [ isHovering, setHovering ] = useState(false);

    const handlePanelChange = (e) => {
        e.stopPropagation();
        setExpanded(!expanded);
    };

    const summaryStyle = Object.assign({},
        styles.accordion,
        expanded && styles[`${expandStyle}`],
        isHovering && styles['hover']
    );

    const summary = Summary({
        isExpanded: expanded,
        onFocus: (event) => event.stopPropagation(),
        onClick: (event) => event.stopPropagation()
    });
    
    return (
        <Accordion
            TransitionProps={{ mountOnEnter: true, unmountOnExit: false }}
            expanded={expanded}
            onChange={handlePanelChange}
            style={styles.accordionItem}
        >
            <AccordionSummary
                expandIcon={<FontAwesomeIcon style={styles.toggleIcon} icon={['fal', 'chevron-down']} />}
                aria-label="Expand"
                aria-controls={`panel-content__${idx}`}
                id={`panel-header__${idx}`}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
                style={summaryStyle}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
            >
                {summary}
            </AccordionSummary>
            <AccordionDetails style={styles.panelContent}>
                {content}
            </AccordionDetails>
        </Accordion>
    );
};

export default AccordionItem;