import React from 'react';
import styles from './CustomColumns.css.js';
import { makePagePretty, titleCase } from '../../../utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipCell from '../CustomCells/TooltipCell';
import LinkCell from '../CustomCells/LinkCell';

const EndpointOffersColumns = () => ([
	{ 
		title: 'Name', 
        field: 'name',
        cellStyle: {...styles.titleCell},
        align: 'left',
        defaultSort: 'asc',
		render: rowData => (rowData.name) 
	},
	{ 
		title: 'Page Type', 
        field: 'offer_page',
        align: 'center',
        cellStyle: {...styles.tableCell, width: '20%'}, 
		render: rowData => {
            const linkButtons = rowData?.selection_links?.map((link) => (link.url)) ?? [];
            return (
                rowData.offer_page === 'selection' ? 
                <span style={styles.inlineSpan}>QuickLinks: <TooltipCell linkArray={linkButtons} selector='text'/></span> :
                makePagePretty(rowData.offer_page) 
            )
        }
	},
	{ 
		title: 'Link', 
        field: 'url',
        align: 'right',
        cellStyle: styles.tableCell,
        sorting: false,
		render: rowData => (
            rowData.offer_page === 'mNet' ?
                titleCase(rowData.url) :
                <LinkCell link={rowData.url} />
        )
	},
	{ 
		title: 'Jump', 
        field: 'jump',
        align: 'center',
        cellStyle: styles.tableCell,
        sorting: false, 
		render: rowData => <LinkCell link={rowData.jump}/>
	},
	{ 
		title: 'Fenced', 
        field: 'jump',
        align: 'left',
        cellStyle: styles.tableCell,
        sorting: false, 
		render: rowData => (
            rowData.restricted ?
                <TooltipCell linkArray={rowData.states} selector='states'/> :
                <FontAwesomeIcon style={styles.emptyColumn} icon={['fal', 'empty-set']} />
        )
	}
]);

export default EndpointOffersColumns;