import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../../context';
import ControlButtons from './ControlButtons';
import styles from './Steps.css.js';
import EndpointGroupList from '../../../../EndpointGroupList';
import Paper from '@material-ui/core/Paper';

const SelectEndpointGroup = ({ close, moveBack, moveToNext }) => {
	const { dispatchSelection, selectionState } = useContext(AppContext);
    const [ activeEndpointGroup, setActiveGroup ] = useState(selectionState.endpoint_group);
    const [ activeGroupName, setGroupName ] = useState(null);
    
	const handleSelection = () => {
        dispatchSelection({ 
            type: 'SELECT_ENDPOINT_GROUP', 
            payload: {id:activeEndpointGroup, name: activeGroupName}
        });
		moveToNext();
    };

	return (
		<div style={styles.stepContentContainer}>
			<div style={styles.stepContentQuestion}>
				<div style={styles.stepContentTitle}>
					Select an <span style={styles.stepContentTableValue}>Endpoint Group</span>:
				</div>
                <Paper style={styles.selections} elevation={10}>
					<EndpointGroupList
						isSelection={true}
						activeGroup={activeEndpointGroup} 
                        setGroup={setActiveGroup}
                        setName={setGroupName}
					/>
				</Paper>
                <ControlButtons 
                    close={close}
                    handleNextClick={handleSelection}
                    handleBack={moveBack}
                />
			</div>
		</div>
	);
}

export default SelectEndpointGroup;