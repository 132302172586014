import React from 'react';
import { useQuery } from '@apollo/client';
import { ENDPOINT_BY_ID } from '../../graphql/queries';
import CircularProgress from '@material-ui/core/CircularProgress';

const EndpointName = ({ id }) => {
    const { loading, error, data } = useQuery(ENDPOINT_BY_ID, { variables: { id } });

    if (loading) return <CircularProgress size={30} thickness={4.5}/>;

    if (error) return <div>{`Error occured: ${error.message}`}</div>;

    const offerData = data.fetchEndpointById.body;

    return <>{offerData.name}</>;
}

export default EndpointName;