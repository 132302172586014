import React from 'react';
import { useQuery } from '@apollo/client';
import { GROUP_BY_ID } from '../../graphql/queries';
import CircularProgress from '@material-ui/core/CircularProgress';

const GroupName = ({ type, id }) => {
    const { loading, error, data } = useQuery(GROUP_BY_ID, { variables: { group_type: type, group_id: id } });

    if (loading) return <CircularProgress size={30} thickness={4.5}/>;

    if (error) return <div>{`Error occured: ${error.message}`}</div>;

    const groupData = data.fetchGroupById.body;

    return <div>{groupData.name}</div>;
}

export default GroupName;