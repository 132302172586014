const styles = {
    acceptedContainer: {
        padding: '20px 0 0 0',
        width: '100%',
    },
    acceptedRow: {
        padding: '0 0 20px 0',
        width: '100%',
    },
    pidPaper: {
        padding: '10px',
        minHeight: '100%',
        margin: '30px 0 0 50px',
        width: '60%',
        borderRadius: '10px'
    },
    pidAssignment: {
        padding: '10px 25px'
    },
    pidAssignmentTop: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        marginBottom: '10px'
    },
    inputLabel: {
        color: 'rgba(15,64,107,0.92)',
        fontSize: '1.2rem',
    },
    pidRow: {
        margin: '0 20px',
        width: '95%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    textInput: {
        marginBottom: '20px'
    },
    pidInput: {
        margin: '0 20px 10px 0',
    },
    mainRow: {
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    textFields: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    switchIcon: {
        color: 'rgba(15,64,107,0.92)',
        fontSize: '1.75rem',
    },
    switchHelper: {
        color: 'rgba(0,0,0,0.54)',
        fontSize: '0.7rem',
    },
    switchContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    litteIcon: {
        fontSize: '1.25rem',
        marginRight: '15px',
        opacity: '0.75',
    },
    iconHover: {
        cursor: 'pointer',
        color: 'rgb(36,78,118)',
        opacity: '1',
        WebkitBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        MozBoxShadow: '0px 5px 40px -10px rgba(0,0,0,0.57)',
        boxShadow: '5px 40px -10px rgba(0,0,0,0.57)',
        transition: 'all 0.7s ease',
    }
};

export default styles;