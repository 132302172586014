import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../../../../context';
import ControlButtons from './ControlButtons';
import styles from './Steps.css.js';
import GroupName from '../../../../GroupName';
import RenderPids from './RenderPids';
import { capitalizeValue } from '../../../../../utils/helpers';

const ConfirmSelection = ({ close, moveBack, setShowSubmit }) => {
	const { selectionState } = useContext(AppContext);

	useEffect(() => {
        setShowSubmit(true);        
        return () => setShowSubmit(false);
// eslint-disable-next-line
	},[]);

    const TableVariableRow = ({ row }) => (
        <tr style={styles.confirmTableRow}>
            <td style={styles.confirmTableKey}>{row.keyName}</td>
            <th style={styles.confirmTableValue}>{row.childValue}</th>
        </tr>
    );

	return (
		<div style={styles.stepContentContainer}>
			<div style={styles.stepContentQuestion}>
				<div style={styles.stepContentTitle}>
					<span style={styles.stepContentTableValue}>Confirm </span> Program Selections:
				</div>
                <table style={styles.confirmTable}>
                    <tbody>
                        {makeTableData(selectionState).map((row, idx) => (
                            <TableVariableRow row={row} key={`selection-row-${idx}`}/>
                        ))}
                    </tbody>
                </table>
                <ControlButtons handleBack={moveBack}/>
			</div>
		</div>
	);
}

export default ConfirmSelection;

const makeTableData = (selection) => {
    let returnArray = [
        { keyName: 'Domain: ', childValue: selection?.program_domain?.domain ?? 'Unknown'},
        { keyName: 'PID Values: ', childValue: <RenderPids pids={selection?.program_domain?.pid ?? []} /> },
        { keyName: 'Vertical: ', childValue: capitalizeValue(selection?.vars?.vertical ?? 'N/A' )},
    ];
    if(selection?.vars?.vertical === 'direct') {
        returnArray.push({ keyName: 'Direct Type Key: ', childValue: capitalizeValue(selection?.vars?.loan_type ?? 'N/A') });
        returnArray.push({ keyName: 'Offer Group: ', childValue: <GroupName type='endpoint' id={selection.endpoint_group} /> });
    } else {
        returnArray.push({ keyName: 'Loan Type: ', childValue: capitalizeValue(selection?.vars?.loan_type ?? 'N/A') });
        returnArray.push({ keyName: 'Debt Type: ', childValue: capitalizeValue(selection?.vars?.debt_type ?? 'N/A') });
        returnArray.push({ keyName: 'Debt Amount: ', childValue: capitalizeValue(selection?.vars?.debt_amount ?? 'N/A') });
        returnArray.push({ 
            keyName: 'Active Debt Opt-In Group: ', 
            childValue: selection?.debt_group !== 'N/A' ? 
                <GroupName type='optin' id={selection.debt_group} /> : 'N/A'
        });
        returnArray.push({ 
            keyName: 'Active Checking Opt-In Group: ', 
            childValue: selection?.checking_group !== 'N/A' ? 
                <GroupName type='optin' id={selection.checking_group} /> : 'N/A'
        });
        returnArray.push({ keyName: 'Offer Group: ', childValue: capitalizeValue(selection.endpointGroupName) });
    };
    return returnArray;
};