import React from 'react';
import styles from './LinkListBuilder.css.js';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SelectionItem = (props) => {
    const { link, handleRemove, snapshot } = props;
    return (
        <div style={styles.selectedRow}>
            <div style={styles.getItemStyle(snapshot.isDragging)}>
                {link.text}
            </div>
            <FontAwesomeIcon 
                style={styles.removeIcon} 
                icon={['fal', 'ban']}
                onClick={(e) => handleRemove(e, link)}
            />
        </div>
    )
}

const SelectionList = ({ selection, setSelection, handleRemove }) => {

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    
    const handleDragEnd = (result) => {
        if (!result.destination) return;    
        if (result.destination.index === result.source.index) return;
    
        const selectionList = reorder(
            selection,
            result.source.index,
            result.destination.index
        );
        setSelection(selectionList);
    };
    
    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable key='selection-drop' droppableId='links'>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={styles.getListStyle(snapshot.isDraggingOver)}
                    > 
                        {selection.map((link, idx) => (
                            <Draggable key={`link-${idx}`} draggableId={`link-${idx}`} index={idx}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        
                                    >
                                        <SelectionItem
                                            link={{...link, idx}}
                                            handleRemove={handleRemove}
                                            snapshot={snapshot}
                                            provided={provided}
                                        />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div> 
                )}
            </Droppable>
        </DragDropContext>
        
    )
};

export default SelectionList;