import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './Loading.css.js';

const Loading = ({ size = 40, styleVar = 'fullLoading' }) => (
    <div style={styles[`${styleVar}`]}>
        <CircularProgress size={size} thickness={4.5}/>
    </div>
);

export default Loading;