export const autoVariableList =[
    {
        text: 'Car',
        value: 'car',
        avatar: ['fal', 'car']
    },
    {
        text: 'Truck',
        value: 'truck',
        avatar: ['fal', 'truck']
    },
    {
        text: 'SUV',
        value: 'suv',
        avatar: ['fal', 'truck-monster']
    },
    {
        text: 'Van/Minivan',
        value: 'van',
        avatar: ['fal', 'shuttle-van']
    },
    {
        text: 'Not Sure',
        value: 'not_sure',
        avatar: ['fal', 'question']
    }
] 

export const homeVariableList = [
    {
        text: 'HELOC', 
        value: 'heloc',
        avatar: ['fal', 'gopuram']
    },
    {
        text: 'Lease to Own', 
        value: 'lease_to_own',
        avatar: ['fal', 'donate']
    },
    {
        text: 'Purchase', 
        value: 'purchase',
        avatar: ['fal', 'shopping-cart']
    },
    {
        text: 'Refinance', 
        value: 'refinance',
        avatar: ['fal', 'sort-size-down'],
    },
]

export const ccVariableList = [
    {
        text: 'Low Interest',
        value: 'low_interest',
        avatar: ['fal', 'percent']
    },
    {
        text: 'Cash Back',
        value: 'cash_back',
        avatar: ['fal', 'money-bill-alt']
    },
    {
        text: 'Rewards',
        value: 'rewards',
        avatar: ['fal', 'gift']
    },
    {
        text: 'Student',
        value: 'student',
        avatar: ['fal', 'graduation-cap']
    },
    {
        text: 'Travel',
        value: 'travel',
        avatar: ['fal', 'plane']
    },
    {
        text: 'Credit Building',
        value: 'credit_building',
        avatar: ['fal', 'chart-line']
    },
    {
        text: 'Business',
        value: 'business',
        avatar: ['fal', 'briefcase']
    },
    {
        text: 'Balance Transfer',
        value: 'balance_transfer',
        avatar: ['fal', 'exchange']
    },
    {
        text: 'Prepaid',
        value: 'prepaid',
        avatar: ['fal', 'dollar-sign']
    },
]
    
export const personalVariableList = [
    {
        text: 'Debt Consolidation',
        value: 'debt_consolidation',
        avatar: ['fal', 'compress-arrows-alt']
    },
    {
        text: 'Pay Taxes',
        value: 'pay_taxes',
        avatar: ['fal', 'landmark']
    },
    {
        text: 'Make a Purchase',
        value: 'make_purchase',
        avatar: ['fal', 'donate']
    },
    {
        text: 'Emergency Cash',
        value: 'emergency_cash',
        avatar: ['fal', 'hands-usd']
    },
    {
        text: 'Pay Bills',
        value: 'pay_bills',
        avatar: ['fal', 'money-check-edit-alt']
    },
    {
        text: 'Other Purpose',
        value: 'other_purpose',
        avatar: ['fal', 'piggy-bank']
    }
]

export const debtTypeOptions = [
    {
        key: 'om',
        text: 'Omit',
        value: 'N/A',
        avatar: ['fal', 'eye-slash']
    },
    {
        key: 'c',
        text: 'Credit Card',
        value: 'credit_card',
        avatar: ['fal', 'credit-card']
    },
    {
        key: 'p',
        text: 'Personal Loan',
        value: 'personal_loan',
        avatar: ['fal', 'hand-holding-usd']
    },
    {
        key: 'm',
        text: 'Medical',
        value: 'medical',
        avatar: ['fal', 'heart-rate']
    },
    {
        key: 's',
        text: 'Student Loan',
        value: 'student_loan',
        avatar: ['fal', 'user-graduate']
    },
    {
        key: 'a',
        text: 'Auto Loan',
        value: 'auto_loan',
        avatar: ['fal', 'car']
    },
    {
        key: 'o',
        text: 'Other',
        value: 'other',
        avatar: ['fal', 'file-invoice-dollar']
    }
]

export const debtAmountOptions = [
    {
        key: 'om',
        text: 'Omit',
        value: 'N/A',
        avatar: ['fal', 'eye-slash']
    },
    {
        key: '1',
        text: 'Less than $15,000',
        value: '<15000',
        avatar: ['fal', 'coin']
    },
    {
        key: '2',
        text: 'Between $15,000 - $30,000',
        value: '15000-30000',
        avatar: ['fal', 'coins']
    },
    {
        key: '3',
        text: 'Between $30,000 - $50,000',
        value: '30000-50000',
        avatar: ['fal', 'money-bill-wave']
    },
    {
        key: '4',
        text: 'More than $50,000',
        value: '>50000',
        avatar: ['fal', 'sack-dollar']
    }
]