import { capitalizeValue } from '../../../../utils/helpers';

const checkForOptinGroup = (data) => {
	let debtActive = !!data.debt_group && data.debt_group !== 'N/A';
	let checkingActive = !!data.checking_group && data.checking_group !== 'N/A';

	if(debtActive && checkingActive) {
		return 'Debt & Checking Opt-Ins Active';
	};
	if(debtActive) {
		return 'Debt Opt-Ins Active'
	};
	if(checkingActive) {
		return 'Checking Opt-Ins Active'
	};
	return 'No Opt-Ins Active';
};

export const getStepTitles = (step, data) => {
	const typeText = data.vars.vertical === 'direct' ? 'URL Identity' : 'Select Loan Type';
	const debtText = data.vars.vertical === 'direct' ? '->' : 'Select Debt Type';
	const amountText = data.vars.vertical === 'direct' ? '->' : 'Select Amount of Debt';
	const optinText = data.vars.vertical === 'direct' ? '->' : 'Select Opt-In Options';
	switch (step) {
		case 0:
			return ([
				'Confirm Domain',
				typeText,
				debtText,
				amountText,
				optinText,
				'Select Endpoint Group',
				'Review'
			]);
		case 1:
			return ([
				`${capitalizeValue(data.vars.vertical)}`,
				typeText,
				debtText,
				amountText,
				optinText,
				'Select Endpoint Group',
				'Review'
			]);
		case 2:
			return ([
				`${capitalizeValue(data.vars.vertical)}`,
				`${capitalizeValue(data.vars.loan_type)}`,
				'',
				amountText,
				optinText,
				'Select Endpoint Group',
				'Review'
			]);
		case 3:
			return ([
				`${capitalizeValue(data.vars.vertical)}`,
				`${capitalizeValue(data.vars.loan_type)}`,
				`${capitalizeValue(data.vars.debt_type)}`,
				'',
				optinText,
				'Select Endpoint Group',
				'Review'
			]);
		case 4:
			return ([
				`${capitalizeValue(data.vars.vertical)}`,
				`${capitalizeValue(data.vars.loan_type)}`,
				`${capitalizeValue(data.vars.debt_type)}`,
				`${capitalizeValue(data.vars.debt_amount)}`,
				'',
				'Select Endpoint Group',
				'Review'
			]);
		case 5:
			return ([
				`${capitalizeValue(data.vars.vertical)}`,
				`${capitalizeValue(data.vars.loan_type)}`,
				`${capitalizeValue(data.vars.debt_type)}`,
				`${capitalizeValue(data.vars.debt_amount)}`,
				`${checkForOptinGroup(data)}`,
				'',
				'Review'
			]);
		case 6:
			return ([
				`${capitalizeValue(data.vars.vertical)}`,
				`${capitalizeValue(data.vars.loan_type)}`,
				`${capitalizeValue(data.vars.debt_type)}`,
				`${capitalizeValue(data.vars.debt_amount)}`,
				`${checkForOptinGroup(data)}`,
				`${data.endpointGroupName}`,
				''
			]);
		default:
			return 'Unknown step';
	}
};