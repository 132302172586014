import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Loading from '../../Loading';
import { useQuery } from '@apollo/client';
import { ARTICLE_OFFERS } from '../../../graphql/queries';
import styles from './ArticleDialog.css';

const OfferSelector = ({ id, label, value, setValue }) => {
    const { loading, error, data } = useQuery(ARTICLE_OFFERS);

    if (error) {
        return <div>{`Error fetching Article Offer List: ${error}`}</div>;
    };

    if (loading) {
        return <div style={styles.loading}><Loading /></div>
    };

    const offers = data.fetchArticleOffers.body;

    return(
        <div style={styles.selectorRow}>
            <div style={styles.selectorContainer}>
                <FormControl fullWidth>
                    <InputLabel id='select-label'>{label}</InputLabel>
                    <Select
                        labelId='select-label'
                        id={id}
                        value={value}
                        label={label}
                        onChange={setValue}
                    >
                        {offers.map((offer, idx) => (
                            <MenuItem key={`offer-option-${idx}`} value={offer.id}>{offer.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
};

export default OfferSelector;