import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import styles from './CustomCells.css.js';

const RenderTooltip = ({ data }) => (
    <div style={styles.tooltipContainer}>        
        <div style={styles.buttonText}>                           
            <a rel='noreferrer' target='_blank' style={styles.valueText} href={data}>{data}</a>                
        </div>
    </div>
);

const LinkCell = ({ link }) => (
    link !== 'N/A' ? (
        <Tooltip
            title={<RenderTooltip data={link} />}
            arrow={true}
            placement='top'
            style={styles.toolTip}
            interactive
            TransitionComponent={Fade} 
            TransitionProps={{ timeout: 600 }}
        >
            <div style={styles.eyeContainer}>
                <FontAwesomeIcon style={styles.cellIcon} icon={['fal', 'eye']} />
            </div>
        </Tooltip>
    ) : <FontAwesomeIcon style={styles.emptyColumn} icon={['fal', 'empty-set']} />
);

export default LinkCell;