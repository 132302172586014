import React, { useEffect } from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useQuery } from '@apollo/client';
import { ARTICLE_OFFERS } from '../../../graphql/queries.js';
import Loading from '../../Loading';
import styles from './OfferBlockDialog.css.js';
import OfferDnd from './OfferDnD.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OfferSelection = ({ offer_block, offerList, setOfferSelection, blockState, setBlockState }) => {

    useEffect(() => {
        let activeOffers = [];
        if(!offerList) return;
        const keyValues = Object.keys(offerList);
        keyValues.forEach((key) => {
            if(offerList[key].selected){
                activeOffers.push({
                    id: key,
                    name: offerList[key].name,
                    display: offerList[key].display,
                    offer_url: offerList[key].offer_url,
                })
            };
        });
        setBlockState({ offers: activeOffers});
// eslint-disable-next-line
    },[offerList]);

    const handleInboundData = (data) => {
        let offerState = {};
        const offerArray = data.fetchArticleOffers.body;
        const active_list = !!offer_block?.offers ? offer_block.offers.map((offer) => offer.id) : [];
        offerArray.forEach((offer) => {
            const details = {
				name: offer.name,
				display: offer.display || '',
				offer_url: offer.offer_url,
                selected: active_list.includes(offer.id)
            };
            offerState[offer.id] = details;
        });
        setOfferSelection(offerState);
    };

    const { loading, error, data } = useQuery(
        ARTICLE_OFFERS, { onCompleted: handleInboundData }
    );

    if (error) {
        return <div>{`Error fetching Offer List: ${error}`}</div>;
    };

    if (loading) {
        return <div style={styles.loading}><Loading /></div>
    };

    const offerArray = data.fetchArticleOffers.body;

    const handleChange = (event) => {
        const currentOffer = offerList[event.target.name];
        setOfferSelection({
            ...offerList,
            [event.target.name]: {
                name: currentOffer.name,
                display: currentOffer?.display || '',
                offer_url: currentOffer.offer_url,
                selected: !currentOffer.selected
            }
        });
    };

    const ListOption = ({ offer }) => (
        <FormControlLabel
            control={<Switch 
                checked={offerList[offer.id].selected} 
                onChange={handleChange} 
                name={offer.id}
            />}
            label={offer.name}
        />
    );

    const EmptyBlock = () => (
        <div style={styles.emptyContainer}>
            <span style={styles.emptyContainerText}>Select from the list of Available Offers to begin.</span>
            <FontAwesomeIcon style={styles.emptyIcon} icon={['fal', 'list-radio']} />
        </div>
    );

    return (
        <div style={styles.offerSelectionContainer}>
            <div style={styles.leftContainer}>
                <FormControl component='fieldset' variant='standard'>
                    <FormLabel style={styles.headLegend} component='legend'>Available Offers:</FormLabel>
                    <FormGroup>
                        {!!offerList && offerArray.map((offer, idx) => (
                            <ListOption key={`offer-list-selection-item-${idx}`} offer={offer} />
                        ))}
                    </FormGroup>
                </FormControl>
            </div>
            <div style={styles.rightContainer}>
                {blockState.offers.length === 0 ?
                    <EmptyBlock /> : 
                    <div style={styles.header}>
                        <FormLabel style={styles.headLegend} component='legend'>Example Offer Block:</FormLabel>
                        <div style={styles.exampleContainer}>
                            <OfferDnd state={blockState} setState={setBlockState} />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default OfferSelection;