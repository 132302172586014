import React from 'react';
import styles from './PathListStyle.css.js';
import PathItemOfferList from '../PathItemOfferList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ExpandButton = ({ open }) => (
    <FontAwesomeIcon
        icon={['fal', `${open ? 'times' : 'chevron-right'}`]}
        style={styles.toggleIcon}
    />
);

const ListDetailPanels = (range) => ([
    {
        icon: () => (<ExpandButton open={false} />),
        openIcon: () => (<ExpandButton open={true} />),
        tooltip: 'Show Offers',
        render: rowData => (<div style={styles.detailsContainer}><PathItemOfferList range={range} program={rowData}/></div>),
    },
]);

export default ListDetailPanels;