import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import styles from './ArticleControl.css';
import ArticleListItem from './ArticleListItem';
import List from '@material-ui/core/List';
import ArticleDialog from '../../Forms/ArticleDialog';
import { useQuery } from '@apollo/client';
import { ARTICLES } from '../../../graphql/queries';
import ActionBar from '../../ActionBar';
import Loading from '../../Loading';
import EmptyList from '../../EmptyList';
import ArticleButtonGroupDialog from '../../Forms/ArticleButtonGroupDialog.jsx';

const ArticleList = () => {
    const { dispatchSelection, selectionState } = useContext(AppContext);

    const { loading, error, data } = useQuery(ARTICLES);

    if (error) {
        return <div>{`Error fetching Article List: ${error}`}</div>;
    };

    if (loading) {
        return <div style={styles.loading}><Loading /></div>
    };

    const articles = data.fetchArticles.body;

    const createNewArticle = (e) => {
        e.stopPropagation();
        dispatchSelection({ 
            type: 'SET_ACTIVE_DATA', 
            payload: {
                group: null,
                article: null
            } 
        });
        dispatchSelection({ type: 'TOGGLE_ARTICLE_DIALOG' });
    };

    const ArticleList = () => (
        <div style={styles.articleListContainer}>
            {articles.length > 0 ? (
                <List component='nav' aria-labelledby='nested-list-programs'>
                    {articles.map((article, idx) => (
                        <ArticleListItem
                            key={`article-item__${idx}`}
                            index={idx}
                            item={article}
                        />
                    ))}
                </List>
            ) : (<EmptyList />)}
        </div>
    );

    return (
        <div style={styles.pageContainer}>
            <ActionBar text='' title='ARTICLES' handleClick={createNewArticle} size='Lg'/>
            {selectionState.showArticleDialog && <div style={styles.dialogContainer}><ArticleDialog /></div>}
            {selectionState.showButtonGroupDialog && <div style={styles.dialogContainer}><ArticleButtonGroupDialog /></div>}
            {!selectionState.showArticleDialog && !selectionState.showButtonGroupDialog && <ArticleList />}
        </div>
    )
};

export default ArticleList;