import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Domains.css.js';

const AcceptableMedia = ({ accepts }) => {
    if (accepts.length === 0) return null;

    return accepts.map((accepting, i) => {
        return (
            <div style={styles.acceptableMedia} key={i}>
                <FontAwesomeIcon icon={accepting.avatar} size='1x' />
                <span style={styles.mediaType}>
                    {accepting.value}
                </span>
            </div>
        )
    }) 
}

export default AcceptableMedia;