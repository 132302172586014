import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { ALL_QUICK_LINKS } from '../../../graphql/queries';
import styles from './LinkListBuilder.css.js';
import Paper from '@material-ui/core/Paper';
import Loading from '../../Loading';
import CustomLinksList from './CustomLinksList';
import SelectionList from './SelectionList';
import { flattenString, titleCase, groupBy } from '../../../utils/helpers';

const LinkListBuilder = ({ selection, setSelection }) => {
    const myTableRef = useRef(null);
    const [ linkData, setData ] = useState([]);
    const [ customLinks, setCustomLinks ] = useState([]);
    const { loading, error, data } = useQuery(ALL_QUICK_LINKS);

    useEffect(() => {
        if (data) {
            const selectedIds = selection?.map(link => (link.qid)) ?? [];
            const quick_links = data.fetchAllQuickLinks.body;
            const filteredLinks = quick_links.filter(link => !selectedIds.includes(link.qid));
            setData(filteredLinks);
        };
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (linkData.length > 0) {
            // Save cluster keys...
            let newClusterKeys = [];
            let clusters = groupBy(linkData, link => link.cluster);
            for (let key of clusters.keys()) { newClusterKeys.push(titleCase(key)); };
            const clusterArray = newClusterKeys.map(cluster => ({ name: cluster, cluster_name: cluster }));
            const linkArray = linkData.map(offer_link => ({
                id: offer_link.qid || offer_link.id,
                cluster: titleCase(offer_link.cluster),
                group: offer_link.group,
                qid: offer_link.qid || offer_link.id,
                name: offer_link.name,
                text: offer_link.text,
                offer_group: offer_link.offer_group,
                url: offer_link.url,
                jump: offer_link.jump
            }));
            const fullData = [...clusterArray, ...linkArray];
            setCustomLinks(fullData);
        };
        // eslint-disable-next-line
    }, [linkData]);

    const addCustomLink = (e, newLink) => {
        e.preventDefault();
        const newSelection = [...selection, {
            id: newLink.id,
            qid: newLink.id,
            cluster: flattenString(newLink.cluster),
            group: newLink.group,
            name: newLink.name,
            text: newLink.text,
            offer_group: newLink.offer_group,
            url: newLink.url,
            jump: newLink.jump 
        }];
        const newLinkList = customLinks.filter(link => link.id !== newLink.id);
        setSelection(newSelection);
        setCustomLinks(newLinkList);
    };

    const removeLink = (e, link) => {
        e.preventDefault()
        const result = Array.from(selection);
        result.splice(link.idx, 1);
        setCustomLinks([...customLinks, {
            id: link.id,
            qid: link.id,
            cluster: titleCase(link.cluster),
            group: link.group,
            name: link.name,
            text: link.text,
            offer_group: link.offer_group,
            url: link.url,
            jump: link.jump 
        }]);
        setSelection(result);
    };

    return (
        <Paper style={styles.inputPaper} elevation={10}>
            <div style={styles.linkBuilder}>
                <div style={styles.selectedListContainer}>
                    <span style={styles.selectedListTitle}>Selected List</span>
                    <SelectionList 
                        selection={selection} 
                        setSelection={setSelection}
                        handleRemove={removeLink}
                    />
                </div>
                <div style={styles.customListContainer}>
                    <span style={styles.customListTitle}>Custom Links</span>
                    {loading && <div style={styles.customLoading}><Loading /></div>}
                    {error && <div>{`Error occured: ${error.message}`}</div>}
                    {!loading && <CustomLinksList ref={myTableRef} links={customLinks} handleAdd={addCustomLink} />}
                </div>
            </div>
        </Paper>
    )
};

export default LinkListBuilder;