import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { useQuery } from '@apollo/client';
import { PUSH_PROVIDER_LIST } from '../../../graphql/queries';
import Loading from '../../Loading';
import PushProviderDialog from '../../Forms/PushProviderDialog';
import styles from './Push.css.js';
import ActionBar from '../../ActionBar';
import Paper from '@material-ui/core/Paper';
import PushProsLogo from '../../../assets/push-pros-logo.png';
import PushnamiLogo from '../../../assets/pushnami_logo.png';

const Push = () => {
    const { dispatchSelection } = useContext(AppContext);
    const [ showDialog, setShowDialog ] = useState(false);

    const { loading, error, data } = useQuery(PUSH_PROVIDER_LIST);

    if (error) return <div>Error fetching push provider list!</div>;

    if (loading) return <div style={styles.fillPage}><Loading /></div>;

    const providers = data.fetchPushProviders.body;

    const handleClose = () => {
        dispatchSelection({ type: 'CLEAR_SELECTION' });
        setShowDialog(false);
    };

    const handleEditClick = () => {
        dispatchSelection({ type: 'EDIT_PUSH_PROVIDERS', payload: providers });
        setShowDialog(true);
    };

    const PushProvider = ({ provider, logo, value }) => (
        <div style={styles.pushItem}>
            <Paper style={{...styles.imagePaper, ...styles[`imageBg${provider}`]}} elevation={10}>
                <img src={logo} alt='provider_img' style={styles.providerImage} />
            </Paper>
            <div style={styles.pushItemValue}>{value}%</div>
        </div>
    );

    return (
        <div style={styles.pageContainer}>
            <ActionBar 
                text='Push Provider' 
                title='DISTRIBUTION' 
                handleClick={handleEditClick} 
                size='Lg' 
                isEdit
            />
            {!showDialog ? 
                <div style={styles.content}>
                    <PushProvider provider='PushPros' logo={PushProsLogo} value={providers['push_pros']}/>
                    <PushProvider provider='Pushnami' logo={PushnamiLogo} value={providers['pushnami']}/>
                </div> :
                <PushProviderDialog open={showDialog} close={handleClose} />
            }
        </div> 
    );
}

export default Push;