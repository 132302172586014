import React, { useContext } from 'react';
import { AppContext } from '../../../context';
import styles from './ArticleControl.css';
import OfferBlockItem from './OfferBlockItem';
import List from '@material-ui/core/List';
import OfferBlockDialog from '../../Forms/OfferBlockDialog';
import { useQuery } from '@apollo/client';
import { OFFER_BLOCKS } from '../../../graphql/queries';
import ActionBar from '../../ActionBar';
import Loading from '../../Loading';
import EmptyList from '../../EmptyList';

const OfferBlockList = () => {
    const { dispatchSelection, selectionState } = useContext(AppContext);

    const { loading, error, data } = useQuery(OFFER_BLOCKS);

    if (error) {
        return <div>{`Error fetching Offer Blocks List: ${error}`}</div>;
    };

    if (loading) {
        return <div style={styles.loading}><Loading /></div>
    };

    const blocks = data.fetchOfferBlocks.body;

    const createNewOfferBlock = (e) => {
        e.stopPropagation();
        dispatchSelection({ 
            type: 'SET_OFFER_BLOCK', 
            payload: null
        });
        dispatchSelection({ type: 'TOGGLE_OFFER_BLOCK_DIALOG' });
    };

    const OfferBlockList = () => (
        <div style={styles.articleListContainer}>
            {blocks.length > 0 ? (
                <List component='nav' aria-labelledby='nested-list-programs'>
                    {blocks.map((offer_block, idx) => (
                        <OfferBlockItem
                            key={`offer-block__${idx}`}
                            index={idx}
                            item={offer_block}
                        />
                    ))}
                </List>
            ) : (<EmptyList />)}
        </div>
    );

    const toggleDialog = () => {
        dispatchSelection({ type: 'TOGGLE_OFFER_BLOCK_DIALOG' });
    };

    return (
        <div style={styles.pageContainer}>
            <ActionBar text='Offer' title='BLOCKS' handleClick={createNewOfferBlock} size='Lg'/>
            {selectionState.showOfferBlockDialog ? <div style={{marginTop: '20px'}}><OfferBlockDialog open={selectionState.showOfferBlockDialog} close={toggleDialog}/></div> : <OfferBlockList />}
        </div>
    )
};

export default OfferBlockList;