import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './EmptyList.css.js';

const EmptyList = () => (
    <div style={styles.main}>
        <FontAwesomeIcon icon={['fal', 'empty-set']} style={styles.nullIcon}/>
        <div style={styles.contentText}>Empty List</div>
    </div>
);

export default EmptyList;