import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './auth/ProtectedRoute';
// Container Components
import Home from './components/Pages/Home';
import Domains from './components/Pages/Domains';
import Programs from './components/Pages/Programs';
import Offerings from './components/Pages/Offerings';
import Push from './components/Pages/Push';
import Reports from './components/Pages/Reports';
import ArticleControl from './components/Pages/ArticleControl';
import CallbackPage from './auth/CallbackPage';

const RouteComponent = () => {
	return (
		<Routes>
			<Route
				path='/'
				element={<ProtectedRoute component={Home} />}
			/>
			<Route
				path='/domains'
				element={<ProtectedRoute component={Domains} />}
			/>
			<Route
				path='/programs'
				element={<ProtectedRoute component={Programs} />}
			/>
			<Route
				path='/offerings'
				element={<ProtectedRoute component={Offerings} />}
			/>
			<Route
				path='/push'
				element={<ProtectedRoute component={Push} />}
			/>
			<Route
				path='/reports'
				element={<ProtectedRoute component={Reports} />}
			/>
			<Route
				path='/articles'
				element={<ProtectedRoute component={ArticleControl} />}
			/>
			<Route path='/callback' element={<CallbackPage />} />
		</Routes>
	);
};

export default RouteComponent;