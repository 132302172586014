import React, { useState } from 'react';
import styles from './ArticleControl.css';

const BlockExample = ({ item }) => {

    const BlockOffer = ({ offerItem }) => {
        const [ isHovering, setHovering ] = useState(false);
        const handleOfferClick = () => {
            window.open(offerItem.offer_url, '_blank');
        };

        const buttStyle = Object.assign({}, 
            styles['blockOfferButton'],
            isHovering && styles['offerHover'],
        );

        return(
            <div style={styles.blockOfferContainer}>
                <button 
                    onClick={handleOfferClick} 
                    style={buttStyle}
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                >
                    {offerItem.display}
                </button>
            </div>
        )
    };

    return(
        <div style={styles.blockContainer}>
            <div style={styles.blockTitle}>Exclusive Offers!</div>
            <div style={styles.blockOffersListContainer}>
                {item.offers.map((offer, idx) => <BlockOffer key={`block-offer-item-button-${idx}`} offerItem={offer}/>)}
            </div>
        </div>
    )
};

export default BlockExample;