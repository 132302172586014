import React from 'react';
import styles from './CustomColumns.css.js';
import { capitalizeValue, amountToText } from '../../../utils/helpers';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GroupName from '../../GroupName';
import GroupCount from '../../GroupCount';

const VerticalPathsColumns = (range) => ([
	{ 
		title: 'Loan Type', 
		field: 'vars.loan_type',
		align: 'left',
        cellStyle: {...styles.titleCell}, 
		render: rowData => capitalizeValue(rowData.vars.loan_type) 
	},
	{ 
		title: 'Debt Type', 
		field: 'debt_type',
		align: 'center',
        cellStyle: styles.tableCell, 
		render: rowData => (
                rowData.vars.debt_type === 'N/A' ?
                        <FontAwesomeIcon style={styles.emptyCellIcon} icon={['fal', 'empty-set']} /> :
                        capitalizeValue(rowData.vars.debt_type)
                )
	},
	{ 
		title: 'Debt Amount', 
		field: 'debt_amount',
		align: 'center',
        cellStyle: styles.tableCell, 
		render: rowData => (
			rowData.vars.debt_amount === 'N/A' ?
                <FontAwesomeIcon style={styles.emptyCellIcon} icon={['fal', 'empty-set']} /> :
                amountToText(rowData.vars.debt_amount)
                
        )
	},
	{ 
        title: 'Checking Opt-In', 
		field: 'checking_group',
		align: 'center',
        cellStyle: styles.tableCell,
        render: rowData => (
            rowData.checking_group === 'N/A' ?
                <FontAwesomeIcon style={styles.emptyCellIcon} icon={['fal', 'empty-set']} /> :
                <GroupName type='optin' id={rowData.checking_group} />
        )
	},
	{ 
        title: 'Debt Opt-In', 
		field: 'debt_group',
		align: 'center',
        cellStyle: styles.tableCell,
        render: rowData => (
            rowData.debt_group === 'N/A' ?
                <FontAwesomeIcon style={styles.emptyCellIcon} icon={['fal', 'empty-set']} /> :
                <GroupName type='optin' id={rowData.debt_group} />
        )
	},
	{ 
        title: 'Group Name', 
		field: 'endpoint_group',
		align: 'center',
        cellStyle: styles.tableCell,
        render: rowData => <GroupName type='endpoint' id={rowData.endpoint_group} />
	},
	{ 
        title: 'Visitors', 
		field: 'endpoint_group',
		align: 'center',
        cellStyle: styles.tableCell,
        render: rowData => <GroupCount id={rowData.id} endpoint_group={rowData.endpoint_group} range={range}/>
	},
]);

export default VerticalPathsColumns;