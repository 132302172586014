import React, { useState, useContext } from 'react';
import { AppContext } from '../../../context';
import { useToasts } from 'react-toast-notifications';
import { useMutation } from '@apollo/client';
import { SAVE_PROGRAM_PATH } from '../../../graphql/mutations';
import { PROGRAM_TABLE } from '../../../graphql/queries';
import DialogBase from '../DialogBase/DialogBase';
import PathStepper from './PathStepper';

const ProgramDialog = ({ open, close }) => {
    const { selectionState } = useContext(AppContext);
    const { addToast } = useToasts();
    const [ isSaving, setIsSaving ] = useState(false);
    const [ showSubmitButton, setSubmitVisible ] = useState(false);

    const handleRedirect = (data) => {
        addToast(data.saveProgramPath.message, { appearance: 'success', autoDismiss: true });
        setIsSaving(false);
        close();
    };
    const handleError = (error) => {
        console.error(error);
        addToast(error?.message ?? 'Error!', { appearance: 'error', autoDismiss: true });
        setIsSaving(false);
        close();
    };

    const [saveProgramPath] = useMutation(
        SAVE_PROGRAM_PATH, {
            refetchQueries: [{ 
                query: PROGRAM_TABLE, 
                variables: { 
                    pid: selectionState.program_domain.pid,
                    vertical: selectionState.vars.vertical
                } 
            }],
            onCompleted: handleRedirect,
            onError: handleError,
        }
    );

    const handleSubmission = () => {
        setIsSaving(true);
        const { program_domain, vars } = selectionState;

        let formNew = {
            domain: program_domain.domain,
            pid: program_domain.pid,
            vars: {
                vertical: vars.vertical,
                loan_type: vars.loan_type,
                debt_type: vars.debt_type,
                debt_amount: vars.debt_amount
            },
            debt_group: selectionState.debt_group,
            checking_group: selectionState.checking_group,
            endpoint_group: selectionState.endpoint_group
        };
        const fullForm = Object.assign({}, 
            formNew,
            !!selectionState?.id && { id: selectionState.id }
        );
        saveProgramPath({ variables: { program: fullForm } });
    };

    return (
        <DialogBase
            open={open}
            close={close}
            title={!!selectionState?.id ? 'Edit Program Path:' : 'Build New Program Path:'}
            isSaving={isSaving}
            handleSubmission={handleSubmission}
            showSubmit={showSubmitButton}
            bg='white'
        >
            <PathStepper close={close} toggleSubmit={setSubmitVisible}/>
        </DialogBase>
    );
}

export default ProgramDialog;