const styles = {
    main: {
        display: 'inline-flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        borderBottom: '1px solid rgb(15,64,107)',
        backgroundColor: 'aliceblue'
    },
    icon: {
        fontSize: '1rem',
        marginBottom: '5px',
        color: '#0F406B'
    }
};

export default styles;