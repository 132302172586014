import React, { useState } from 'react';
import Fab from '@material-ui/core/Fab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './ActionButton.css.js';

const ActionButton = ({ icon, bg, bgHover, handleClick }) => {
    const [ isHovering, setHovering ] = useState(false);

    const buttonStyle = Object.assign({},
        styles.button,
        styles[`${bg}`],
        isHovering && !!bgHover && styles[`${bgHover}`]
    );

    return (
        bg === 'clear' ? 
        <div
            style={buttonStyle} 
            aria-label={`${icon}`} 
            onClick={!!handleClick ? handleClick : (e) => e.preventDefault()}
            onFocus={(event) => event.stopPropagation()}
            variant='extended'
            onMouseEnter={() => setHovering(true)}
			onMouseLeave={() => setHovering(false)}
        >
            <FontAwesomeIcon
                icon={['fal', `${icon}`]}
                style={styles.icon}
            />
        </div> :
        <Fab 
            style={buttonStyle} 
            aria-label={`${icon}`} 
            onClick={!!handleClick ? handleClick : (e) => e.preventDefault()}
            onFocus={(event) => event.stopPropagation()}
            variant='extended'
            onMouseEnter={() => setHovering(true)}
			onMouseLeave={() => setHovering(false)}
        >
            <FontAwesomeIcon
                icon={['fal', `${icon}`]}
                style={styles.icon}
            />
        </Fab>
    );
}

export default ActionButton;